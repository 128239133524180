<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import { OrcamentoOrdemServicos } from "../../resources/orcamentoOrdemServicosResource";
import { Servico } from "../../resources/servicoResource";
import { useField, useForm } from "vee-validate";
import { string, addMethod, object, number } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ServicoCreate from "../Servicos/ServicoCreate.vue";
import ServicoBusca from "../Servicos/ServicoBusca.vue";
import { useCasasDecimais } from "../../store/casasDecimais";
import Moeda from "../Utils/moeda.vue";
import Quantidade from "../Utils/quantidade.vue";
import { Converte } from "../../utils/converte";
import { useRoute } from "vue-router";

const dialogServico = ref(false);
const dialogBuscaServico = ref(false);
let showBtnGravar = ref(true);
const casasDecimaisStore = useCasasDecimais();
const route = useRoute();
const emit = defineEmits(['add-servico', 'close-dialog']);

const snackBarApp = inject("snackBarApp");
const permissoesStore = usePermissoes();

addMethod(string, "quantidade_maior_zero", function quantidadeMaiorZero(message) {
    return string().test(
        "quantidade-maior_zero", // Name
        message, // Msg
        function (quantidade) {
            if (Converte.stringToFloat(quantidade) > 0) {
                return true;
            }
            return false;
        }
    );
});


const validationSchema = object({
    servicoOrcamentoOrdemServicos: object({
        idOrcamentoOrdemServicos: undefined,
        idServico: undefined,
        codigoServico: number().required("O código do serviço é obrigatório").typeError("O código do serviço deve ser um número").label("O código do servico"),
        descricaoServico: undefined,
        unidadeServico: undefined,
        quantidade: string().quantidade_maior_zero("A quantidade deve ser maior que 0."),
        valorUnitario: string().required("O valor unitário é obrigatório").label("O valor unitário"),
        valorTotal: string().required("O valor total é obrigatório").label("O valor total"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const servicoOrcamentoOrdemServicos = ref({
    idOrcamentoOrdemServicos: useField("servicoOrcamentoOrdemServicos.idOrcamentoOrdemServicos").value,
    idServico: useField("servicoOrcamentoOrdemServicos.idServico").value,
    codigoServico: useField("servicoOrcamentoOrdemServicos.codigoServico").value,
    descricaoServico: useField("servicoOrcamentoOrdemServicos.descricaoServico").value,
    unidadeServico: useField("servicoOrcamentoOrdemServicos.unidadeServico").value,
    quantidade: useField("servicoOrcamentoOrdemServicos.quantidade").value,
    valorUnitario: useField("servicoOrcamentoOrdemServicos.valorUnitario").value,
    valorTotal: useField("servicoOrcamentoOrdemServicos.valorTotal").value,
});

const submit = handleSubmit((values, actions) => {
    addServico(values, actions);
});

onMounted(() => {

    cleanFormServico();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});


function cleanFormServico() {
    servicoOrcamentoOrdemServicos.value.idOrcamentoOrdemServicos = null;
    servicoOrcamentoOrdemServicos.value.idServico = null;
    servicoOrcamentoOrdemServicos.value.codigoServico = "";
    servicoOrcamentoOrdemServicos.value.descricaoServico = "";
    servicoOrcamentoOrdemServicos.value.unidadeServico = "";
    servicoOrcamentoOrdemServicos.value.quantidade = casasDecimaisStore.quantidadeServicoUm;
    servicoOrcamentoOrdemServicos.value.valorUnitario = 'R$ 0,00';
    servicoOrcamentoOrdemServicos.value.valorTotal = 'R$ 0,00';
}


async function addServico(values) {
    let idOrcamentoOrdemServicos = route.params.id;

    values.servicoOrcamentoOrdemServicos.idOrcamentoOrdemServicos = idOrcamentoOrdemServicos;

    if (values.servicoOrcamentoOrdemServicos.idServico == null) {
        snackBarApp.value.open("Selecione um serviço para incluir no orçamento de ordem de serviços.", 6000);
        return;
    }

    let dados = new FormData();
    dados.append("dados", JSON.stringify(values.servicoOrcamentoOrdemServicos));

    try {
        const response = await OrcamentoOrdemServicos.addServico(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O serviço não pode ser incluído no orçamento de ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Serviço incluído com sucesso no orçamento de ordem de serviços.", 1500);

            values.servicoOrcamentoOrdemServicos.idServicoOrcamentoOS = response.data.idServicoOrcamentoOS;

            values.servicoOrcamentoOrdemServicos.valorTotal = Converte.stringToFloat(values.servicoOrcamentoOrdemServicos.valorTotal).toString();
            values.servicoOrcamentoOrdemServicos.quantidade = Converte.stringToFloat(values.servicoOrcamentoOrdemServicos.quantidade).toString();
            emit('add-servico', values.servicoOrcamentoOrdemServicos);

            cleanFormServico();

            document.getElementById("codigoServico").focus();
        }
    } catch (err) {
        console.log(err);
    }
}


async function getServico() {
    if(servicoOrcamentoOrdemServicos.value.codigoServico.toString().trim() == '') {
        servicoOrcamentoOrdemServicos.value.descricaoServico = '';
        servicoOrcamentoOrdemServicos.value.unidadeServico = '';
        servicoOrcamentoOrdemServicos.value.idServico =  null;

        return;
    }

    let dados = new FormData();
    dados.append('codigo', servicoOrcamentoOrdemServicos.value.codigoServico);
    dados.append('codigoModulo', '03.05.02');

    try {
        let response = await Servico.getByCodigo(dados);
        if (response.status == 200) {
            servicoOrcamentoOrdemServicos.value.idServico = null;
            servicoOrcamentoOrdemServicos.value.descricaoServico = '';
            servicoOrcamentoOrdemServicos.value.unidadeServico = '';

            if(response.data.data == false) {
                document.getElementById('codigoServico').focus();
                snackBarApp.value.open('Serviço não encontrado, tente outro código ou faça uma busca.', 6000);
                servicoOrcamentoOrdemServicos.value.codigoServico = '';
                return;
            }

            if(response.data.data.ativoInativoBloqueado == 'I' || response.data.data.ativoInativoBloqueado == 'B') {
                if(response.data.data.ativoInativoBloqueado == 'I') {
                    snackBarApp.value.open('Serviço inativo. O serviço '+response.data.data.codigo+'/'+response.data.data.descricao+
                        ' está inativo, um serviço inativo não pode ser incluído em orçamentos de ordens de serviços.', 6000);
                }
                if(response.data.data.ativoInativoBloqueado == 'B') {
                    snackBarApp.value.open('Serviço bloqueado. O serviço '+response.data.data.codigo+'/'+response.data.data.descricao+
                        ' está bloqueado, um serviço bloqueado não pode ser incluído em orçamentos de ordens de serviços.', 6000);
                }

                servicoOrcamentoOrdemServicos.value.codigoServico = '';
                return;

            }
            else {
                servicoOrcamentoOrdemServicos.value.idServico = response.data.data.idServico;
                servicoOrcamentoOrdemServicos.value.codigoServico = response.data.data.codigo;
                servicoOrcamentoOrdemServicos.value.descricaoServico = response.data.data.descricao;
                servicoOrcamentoOrdemServicos.value.unidadeServico = response.data.data.unidade;
                servicoOrcamentoOrdemServicos.value.valorUnitario = Converte.numberToCurrency(response.data.data.valor);

                calculaTotal();
            }
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar o serviço.');
        console.log(err);
    }
}

function selecionouServico(servico) {
    if (servico == null) {
        return;
    }
    servicoOrcamentoOrdemServicos.value.codigoServico = servico.codigo;
    getServico();

    document.getElementById("quantidade").focus();

    dialogBuscaServico.value = false;
}


function calculaTotal() {
    let quantidade = Converte.stringToFloat(servicoOrcamentoOrdemServicos.value.quantidade);
    let valorUnitario = Converte.stringToFloat(servicoOrcamentoOrdemServicos.value.valorUnitario);
    let total = quantidade * valorUnitario;
    servicoOrcamentoOrdemServicos.value.valorTotal = Converte.numberToCurrency(total);
}

function cadastrouServico(codigoServico) {
    if (codigoServico == null) {
        return;
    }

    console.log('Chamou aquii !!!');

    servicoOrcamentoOrdemServicos.value.codigoServico = codigoServico;
    getServico();
}


</script>

<template>
    <v-card class="mb-8" elevation="16">
        <v-card-title>
            <h3>Inclusão de Serviços no Orçamento de Ordem de Serviços</h3>
        </v-card-title>
        <form ref="formServicoOrcamentoOrdemServicos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Código do Serviço" id="codigoServico" type="text"
                            v-model="servicoOrcamentoOrdemServicos.codigoServico"
                            :error-messages="errors['servicoOrcamentoOrdemServicos.codigoServico']" maxlength="15"
                            @blur="getServico()" />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="Descrição do Serviço" id="descricaoServico" type="text"
                            v-model="servicoOrcamentoOrdemServicos.descricaoServico" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um serviço pela descrição, referência.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaServico = true"
                                            id="btnBuscaServico">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaServico" transition="dialog-top-transition">
                                    <ServicoBusca @selecionouServico="selecionouServico"
                                        :select-inativo-bloqueado=false 
                                        @close-dialog="dialogBuscaServico = false;" />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo serviço.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogServico = true"
                                            id="btnNovoServico">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogServico" transition="dialog-top-transition">
                                    <ServicoCreate @cadastrouServico="cadastrouServico"
                                        @close-dialog="dialogServico = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Unid." id="unidade" type="text"
                            v-model="servicoOrcamentoOrdemServicos.unidadeServico" readonly tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Quantidade label="Quantidade" id="quantidade" type="text"
                            v-model="servicoOrcamentoOrdemServicos.quantidade" tipo="quantidadeServico"
                            :error-messages="errors['servicoOrcamentoOrdemServicos.quantidade']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Unitário" id="valorUnitario" type="text"
                            v-model="servicoOrcamentoOrdemServicos.valorUnitario"
                            :error-messages="errors['servicoOrcamentoOrdemServicos.valorUnitario']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total" id="valorTotal" type="text" v-model="servicoOrcamentoOrdemServicos.valorTotal"
                            :error-messages="errors['servicoOrcamentoOrdemServicos.valorTotal']" maxlength="15" readonly
                            tabindex="-1" />
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-show="showBtnGravar" variant="elevated" type="submit"
                    id="btnAddServicoGravar">Incluir Serviço</v-btn>
                <v-btn color="primary" variant="elevated" @click.prevent='emit("close-dialog")'>Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
