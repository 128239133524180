<script setup>
import { ref, onMounted, defineEmits, inject } from "vue";
import { useRoute } from "vue-router";
import { GrupoFornecedores } from "../../resources/grupoFornecedoresResource";
import { useField, useForm } from "vee-validate";
import { object, string, number } from "yup";
import { usePermissoes } from "../../store/permissoes";

const emit = defineEmits(["close-dialog", "cadastrou-grupo-fornecedores"]);
const snackBarApp = inject("snackBarApp");
const route = useRoute();
let showBtnGravar = ref(true);
const permissoesStore = usePermissoes();

const validationSchema = object({
    grupoFornecedores: object({
        idGrupoFornecedores: number().nullable(),
        descricao: string().required("A descrição é obrigatória").max(50),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const grupoFornecedores = ref({
    idGrupoFornecedores: useField("grupoFornecedores.idGrupoFornecedores").value,
    descricao: useField("grupoFornecedores.descricao").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    if (route.name == "GrupoFornecedores.Update") {
        getGrupoFornecedores(route.params.id);
    } else {
        grupoFornecedores.value.idGrupoFornecedores = null;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});
async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(grupoFornecedores.value));

    try {
        const response = await GrupoFornecedores.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Grupo de fornecedores não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "GrupoFornecedores.Update") {
                snackBarApp.value.open("Grupo de fornecedores alterado com sucesso.");
            } else {
                snackBarApp.value.open("Novo grupo de fornecedores cadastrado com sucesso.");
                actions.resetForm();
                grupoFornecedores.value.idGrupoFornecedores = null;
                document.getElementById("descricao").focus();
                emit("cadastrou-grupo-fornecedores");
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroGrupoFornecedores() {
    emit("close-dialog");
}
async function getGrupoFornecedores(id) {
    let dados = new FormData();
    dados.append("codigoModulo", "01.20.02");
    dados.append("idGrupoFornecedores", id);
    try {
        const response = await GrupoFornecedores.getById(dados);
        if (response.status == 200) {
            setValues({ grupoFornecedores: response.data.data });
            document.getElementById("descricao").focus();
        }
    } catch (err) {
        console.log(err);
    }
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'GrupoFornecedores.Update'">Novo Grupo de Fornecedores</h2>
            <h2 v-show="$route.name == 'GrupoFornecedores.Update'">Alteração de Grupo de Fornecedores</h2>
        </v-card-title>

        <form ref="formGrupoFornecedores" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field label="Descrição" id="descricao" type="text" maxlength="50"
                            v-model="grupoFornecedores.descricao"
                            :error-messages="errors['grupoFornecedores.descricao']" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnGrupoFornecedoresGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="$router.go(-1)" v-if="showBtnGravar"
                    v-show="$route.name == 'GrupoFornecedores.Create' || $route.name == 'GrupoFornecedores.Update'">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar"
                    v-show="$route.name !== 'GrupoFornecedores.Create' && $route.name !== 'GrupoFornecedores.Update'"
                    @click.prevent="closeModalCadastroGrupoFornecedores()">Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
