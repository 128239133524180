<script setup>
    import { ref, onMounted, defineEmits, inject } from 'vue';
    import { useRoute } from 'vue-router';
    import {Banco} from '../../resources/bancoResource';
    import { useField, useForm } from 'vee-validate';
    import {string, number, addMethod, object} from 'yup';
    import { usePermissoes } from '../../store/permissoes';
    
    const isRealValidation = ref(null);
    const validationCodigo = ref(null);
    const snackBarApp = inject('snackBarApp');
    const route = useRoute();
    let  showBtnGravar = ref(true);
    const emit = defineEmits(['close-dialog', 'cadastrou-banco']);

    const permissoesStore = usePermissoes();

    addMethod(number, 'codigo_banco_existe', function codigoBancoExiste(message) {
       return number().test('codigo-banco-existe',  // Name
            message,               // Msg
            function(codigo_banco) { 
                if(isRealValidation.value == null) {
                    return true;
                }
                if (!isRealValidation.value) {
                    return validationCodigo.value;
                }
                isRealValidation.value = false;
                validationCodigo.value = false;

                return new Promise((resolve) => {
                Banco.codigoExiste(codigo_banco, banco.value.idBanco)
                .then(res => {if(res.data == false){
                    validationCodigo.value = true;
                    resolve(true)
                } 
                
                resolve(false)})
                })
        })
    });

  
    const validationSchema  = object({
        banco: object({
            idBanco: undefined,
            codigo: number().
                min(1).
                max(999999999).
                codigo_banco_existe('Esse código já está sendo usado por outro banco, tente outro código.').
                typeError('O código deve ser um número').
                label('O código'),
            nome: string().required('O nome é obrigatório').max(50).label('O nome do banco'),
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const banco =  ref({ 
        idBanco: useField('banco.idBanco').value,
        codigo: useField('banco.codigo').value,
        nome: useField('banco.nome').value,
    });

    const submit = handleSubmit((values, actions) => {
        gravar(values, actions);
    });

    onMounted(() => {
        cleanForm();
        if(route.name == 'Banco.Update') {
            getBanco(route.params.id);
        }

        let tipo = permissoesStore.tipo;
        let validade = permissoesStore.validade;

        if((tipo == 'D' || tipo == 'P') && validade == 'N') {
            showBtnGravar.value = false;
        }

    });

    function cleanForm() {
        if(route.name !== 'Banco.Update' && route.name !== 'Banco.Show') {
            banco.value.idBanco = null;
            proximoCodigo();
        }
        
        banco.value.nome = '';
    }

    async function gravar(values, actions) {
        let dados = new FormData();
        dados.append('data', JSON.stringify(values.banco));

        try {
            const response = await Banco.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open('O banco não pode ser cadastrado, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }
                if(route.name == 'Banco.Update') {
                    snackBarApp.value.open('Banco alterado com sucesso.');
                }
                else{
                    snackBarApp.value.open('Novo banco cadastrado com sucesso.');
                    actions.resetForm();
                    cleanForm();
                    document.getElementById("codigo").focus();
                    emit('cadastrou-banco');
                }
            }
        }  catch(err) {
            snackBarApp.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    function closeModalCadastroBanco() {
        emit('close-dialog');
    }

    async function getBanco(id) {
        let dados = new FormData();
        dados.append('codigoModulo', '01.07.02');
        dados.append('idBanco', id);
        try {
            const response = await Banco.getById(dados);
            if (response.status == 200) {
                setValues({'banco': response.data.data});
                
                document.getElementById("codigo").focus();
            }
        } catch(err) {
            console.log(err);
        }
    }

    async function proximoCodigo() {
        try {
            
            const response  = await Banco.proximoCodigo()
            if(response.status == 200) {
                banco.value.codigo = response.data;
            }
        } catch(err) {
                console.log(err);
        }
    }        

</script>

<template>
        <v-card elevation="16">
            <v-card-title>
            <h2 v-show="$route.name !=='Banco.Update'">Novo Banco</h2>
                <h2 v-show="$route.name =='Banco.Update'">Alteração de Banco</h2>          
            </v-card-title>
            <form ref="formBanco" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="2">
                    <v-text-field label="Código"
                            id="codigo"
                            type="text"
                            v-model='banco.codigo'
                            :error-messages="errors['banco.codigo']"
                            maxlength="9"
                            @blur="(e) => { isRealValidation= true; banco.codigo = e.target.value; }"
                            />
                </v-col>

                <v-col cols="10">
                    <v-text-field label="Nome"
                            id="nome"
                            type="text"
                            v-model='banco.nome'
                            maxlength="50"
                            :error-messages="errors['banco.nome']"/>
                        
                </v-col>
            </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnBancoGravar" >Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                    variant="elevated" 
                    @click.prevent='$router.go(-1)' 
                    v-if="showBtnGravar" v-show="$route.name =='Banco.Create' || $route.name == 'Banco.Update' ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar" 
                    v-show="$route.name !=='Banco.Create' && $route.name !== 'Banco.Update' " 
                    @click.prevent='closeModalCadastroBanco()'>Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
</template>
