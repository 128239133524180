<script setup>
import { ref, defineEmits, defineProps, inject } from "vue";
import  AVistaPedidoCompra  from "./AVistaPedidoCompra.vue";
import  APrazoPedidoCompra  from "./APrazoPedidoCompra.vue";
import  ChequePedidoCompra  from "./ChequePedidoCompra.vue";

const props = defineProps({
    pedidoCompra: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const emit = defineEmits(['close-dialog', 'faturou-pedido-compra']);
const dialogFaturaAVista = ref(false);
const dialogFaturaAPrazo = ref(false);
const dialogFaturaCheque = ref(false);

const pedidoCompra = ref(props.pedidoCompra);
const operacao = ref(props.operacao);

const snackBarApp = inject('snackBarApp');

function closeModalFormaPagamentoPedidoCompra() {
    emit('close-dialog');
}

function abreFaturaAVista() {
    dialogFaturaAVista.value = true;
}

function abreFaturaAPrazo() {
    if(pedidoCompra.value.idFornecedor <= 0) {
        snackBarApp.value.open('Nenhum fornecedor selecionado. Para faturar um pedido de compra a prazo é necessário que o pedido tenha um fornecedor.');
        return;
    }
    dialogFaturaAPrazo.value = true;
}

function abreFaturaCheque() {
    if(pedidoCompra.value.idFornecedor <= 0) {
        snackBarApp.value.open('Nenhum fornecedor selecionado. Para faturar um pedido de compra com cheque é necessário que o pedido tenha um fornecedor.');
        return;
    }
    dialogFaturaCheque.value = true;
}

function faturouPedidoCompra(formaPagamento) {
    if(formaPagamento == 'CAI') {
        dialogFaturaAVista.value = false;
    }

    if(formaPagamento == 'PRA') {
        dialogFaturaAPrazo.value = false;
    }

    if(formaPagamento == 'CHE') {
        dialogFaturaCheque.value = false;
    }

    emit('faturou-pedido-compra', formaPagamento);


}

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2>Forma de Pagamento</h2>
        </v-card-title>
        <v-card-text>
            <v-row >
                <v-col cols="3" class="text-center">
                    <v-btn color="primary" variant="elevated" @click="abreFaturaAVista()">A Vista</v-btn>
                </v-col>    
                <v-col cols="3" class="text-center">
                    <v-btn color="primary" variant="elevated" @click="abreFaturaAPrazo()">A Prazo</v-btn>
                </v-col>
                <v-col cols="3" class="text-center">
                    <v-btn color="primary" variant="elevated" @click="abreFaturaCheque()">Cheque</v-btn>
                </v-col>    
                <v-col cols="3" class="text-center">
                    <v-btn color="primary" variant="elevated" @click="closeModalFormaPagamentoPedidoCompra()">Fechar</v-btn>
                </v-col>
            </v-row>

            <v-dialog v-model="dialogFaturaAVista"  transition="dialog-top-transition">
                <AVistaPedidoCompra :propPedidoCompra="pedidoCompra" :operacao="operacao" 
                    @close-dialog="dialogFaturaAVista = false"
                    @faturou-pedido-compra="faturouPedidoCompra"></AVistaPedidoCompra>
            </v-dialog>

            <v-dialog v-model="dialogFaturaAPrazo"  transition="dialog-top-transition">
                <APrazoPedidoCompra :propPedidoCompra="pedidoCompra" :operacao="operacao" 
                    @close-dialog="dialogFaturaAPrazo = false"
                    @faturou-pedido-compra="faturouPedidoCompra"></APrazoPedidoCompra>
            </v-dialog>

            <v-dialog v-model="dialogFaturaCheque"  transition="dialog-top-transition">
                <ChequePedidoCompra :propPedidoCompra="pedidoCompra" :operacao="operacao" 
                    @close-dialog="dialogFaturaCheque = false"
                    @faturou-pedido-compra="faturouPedidoCompra"></ChequePedidoCompra>
            </v-dialog>


        </v-card-text>
    </v-card>
</template>