<script setup>
    import { ref, inject, defineEmits } from 'vue';
    import { useRelatorio } from "../../store/relatorio";
    import { Relatorio } from "../../resources/relatoriosResource";
    import { Converte } from "../../utils/converte";
    import config from "../../services/config";
    import Data from "../Utils/data.vue";

    const emit = defineEmits(['close-dialog']);
    const snackBarApp = inject('snackBarApp');
    
    const dataInicial = ref(new Date().toLocaleDateString("pt-BR"));
    const dataFinal = ref(new Date().toLocaleDateString("pt-BR"));

    const relatorioStore = useRelatorio();

    async function submit() {
        let nomeRel = relatorioStore.relatorio;

        if(dataInicial.value == '' || dataFinal.value == '') {
            snackBarApp.value.open('Informe as datas inicial e final para a impressão do relatório.');
            return;
        }
        let auxDataInicial = Converte.datePtBrToIso(dataInicial.value);
        let auxDataFinal = Converte.datePtBrToIso(dataFinal.value);
        
        try {
            let response = await Relatorio.get(nomeRel, auxDataInicial, auxDataFinal);
            if (response.status == 200) {
                if(response.data.success == true) {
                    let nomeArquivo = response.data.nomeArquivo;
                    let url = config.baseUrlRel+nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }

                if(response.data.success == false) {
                    snackBarApp.value.open(response.data.message);
                    return;
                }

                snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
            }
        }
        catch (error) {
            console.log(error);
        //    snackBarApp.value.open('Erro ao tentar imprimir o relatório.');
        }
    }        

</script>


<template>
    <v-container>
        <v-card elevation="16">
            <v-card-title>
            <h4>Digite as Datas Inicial e Final e clique em Confirmar</h4>
            </v-card-title>
            
            <form ref="formTelaDatas" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="6">
                    <Data v-model="dataInicial" label="Data Inicial" id="dataInicial" />
                </v-col>
                <v-col cols="6">
                    <Data v-model="dataFinal" label="Data Final" id="dataFinal" />
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnTelaDatasConfirmar" >Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>