<script setup>
import { ref, onMounted, computed, inject } from "vue";
import { TipoDocumento } from "../../resources/tipoDocumentoResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useTipoDocumento } from "../../store/tipoDocumento";

let msgConfirm = ref(null);
let snackBarApp = inject('snackBarApp');

const tipoDocumentoStore = useTipoDocumento();
let tipoDocumento = ref({});
let tiposDocumentos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(tipoDocumentoStore.currentPage);
const filtro = ref(tipoDocumentoStore.filtro);

onMounted(() => {
    search();
});
const direction = computed(() => {
    return tipoDocumentoStore.sort[0].direction;
});
async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(tipoDocumento.value));

    try {
        const response = await TipoDocumento.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Tipo de documento excluído com sucesso.");
                search();
            }
            if (response.data.success == false) {
                snackBarApp.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(tipoDoc) {
    tipoDocumento.value = tipoDoc;
    msgConfirm.value.open("Confirma Exclusão ?", "Confirma a exclusão do tipo de documento: " + tipoDocumento.value.descricao + " ?", { color: "primary" }).then((confirm) => {
        if (confirm) {
            destroy();
        }
    });
}
async function search() {
    let start = tipoDocumentoStore.start;
    let limit = tipoDocumentoStore.limit;
    let sort = JSON.stringify(tipoDocumentoStore.sort);

    tipoDocumentoStore.setFiltro(filtro.value);

    try {
        const response = await TipoDocumento.get(start, limit, filtro.value, sort);
        tiposDocumentos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    tipoDocumentoStore.setCurrentPage(val);
    tipoDocumentoStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = tipoDocumentoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];
    tipoDocumentoStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <v-row>
        <v-col>
            <h1>Tipos de Documentos</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-btn v-show="temPermissao('01.04.01')" color="primary" :to="{ name: 'TipoDocumento.Create' }">Novo Tipo de
                Documento</v-btn>
        </v-col>
        <v-col cols="8">
            <v-form>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Filtro" type="text" v-model="filtro"
                            prepend-inner-icon="mdi-magnify">
                            <template v-slot:append>
                                <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('descricao')">
                            Descrição
                            <v-icon
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in tiposDocumentos" :key="o">
                    <td class="text-center">
                        <v-tooltip text="Altera o tipo de documento.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.04.02')"
                                    :to="{ name: 'TipoDocumento.Update', params: { id: o.idTipoDocumento } }"
                                    size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o tipo de documento.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('01.04.03')" v-bind="props" variant="text"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.descricao }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
            :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>

    <p></p>

    <v-btn Primary v-show="temPermissao('01.04.01')" color="primary" :to="{ name: 'TipoDocumento.Create' }">Novo Tipo de
        Documento</v-btn>
</template>
