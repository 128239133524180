<script setup>
    import { ref, inject, defineEmits, onMounted } from 'vue';
    import { useRelatorio } from "../../store/relatorio";
    import { Relatorio } from "../../resources/relatoriosResource";
    import { Conta } from "../../resources/contaResource";
    import { Converte } from "../../utils/converte";
    import config from "../../services/config";
    import Data from "../Utils/data.vue";
    
    const emit = defineEmits(['close-dialog']);
    const snackBarApp = inject('snackBarApp');
    
    const dataInicial = ref(new Date().toLocaleDateString("pt-BR"));
    const dataFinal = ref(new Date().toLocaleDateString("pt-BR"));
    const idConta = ref(null);
    const detalhesConta = ref('');
    const contas = ref([]);

    const relatorioStore = useRelatorio();

    
    async function submit() {
        let nomeRel = relatorioStore.relatorio;

        if(dataInicial.value == '' || dataFinal.value == '') {
            snackBarApp.value.open('Informe as datas inicial e final para a impressão do relatório.');
            return;
        }

        if(idConta.value == null ) {
            snackBarApp.value.open('A conta precisa ser escolhida, escolha uma conta e tente novamente.');
            return;
        }   

        let auxDataInicial = Converte.datePtBrToIso(dataInicial.value);
        let auxDataFinal = Converte.datePtBrToIso(dataFinal.value);
        
        try {
            let response = await Relatorio.get(nomeRel, auxDataInicial, auxDataFinal, idConta.value);
            if (response.status == 200) {
                if(response.data.success == true) {
                    let nomeArquivo = response.data.nomeArquivo;
                    let url = config.baseUrlRel+nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }

                if(response.data.success == false) {
                    snackBarApp.value.open(response.data.message);
                    return;
                }

                snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
            }
        }
        catch (error) {
            snackBarApp.value.open('Erro ao tentar imprimir o relatório.');
        }
    }    
  
    onMounted(() => {
        getContas();
    });


    async function getContas() {
        const response = await Conta.getContas();
        contas.value = response.data.data;

        if (contas.value.length > 0 && (idConta.value == null || idConta.value == 0)) {
            idConta.value = response.data.data[0].idConta;
        }

        changeConta();
    }

    async function changeConta() {
        let dados = new FormData();
        dados.append('codigoModulo', '04.11');
        dados.append('idConta', idConta.value);
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;
            detalhesConta.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);

            if(conta == []) {
                detalhesConta.value = '';
            }
        }
    }
    

</script>


<template>
    <v-container>
        <v-card elevation="16">
            <v-card-title>
            <h4>Digite as Datas Inicial, Final, Fornecedor e clique em Confirmar</h4>
            </v-card-title>
            
            <form ref="formTelaDatasFornecedor" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <Data v-model="dataInicial" label="Data Inicial" id="dataInicial" />
                    </v-col>
                    <v-col cols="6">
                        <Data v-model="dataFinal" label="Data Final" id="dataFinal" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-select v-model="idConta"  
                            :items="contas" 
                            item-title="apelido" 
                            item-value="idConta" 
                            @update:modelValue="changeConta()" 
                            label="Conta"
                            no-data-text="Nenhuma conta cadastrada"></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field
                            v-model="detalhesConta"
                            label="Detalhes da Conta"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnTelaDatasFornecedorConfirmar" >Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>
