<script setup>
    import { ref, inject, defineEmits } from 'vue';
    import { useRelatorio } from "../../store/relatorio";
    import { Relatorio } from "../../resources/relatoriosResource";
    import { Vendedor } from "../../resources/vendedorResource";
    import { Converte } from "../../utils/converte";
    import config from "../../services/config";
    import Data from "../Utils/data.vue";
    import VendedorBusca from "../Vendedores/VendedorBusca.vue";

    const emit = defineEmits(['close-dialog']);
    const snackBarApp = inject('snackBarApp');
    
    const dataInicial = ref(new Date().toLocaleDateString("pt-BR"));
    const dataFinal = ref(new Date().toLocaleDateString("pt-BR"));
    const idVendedor = ref(null);
    const codigoVendedor = ref('');
    const nomeVendedor = ref('');

    const relatorioStore = useRelatorio();

    const dialogVendedor = ref(false);

    async function submit() {
        let nomeRel = relatorioStore.relatorio;

        if(dataInicial.value == '' || dataFinal.value == '') {
            snackBarApp.value.open('Informe as datas inicial e final para a impressão do relatório.');
            return;
        }

        if(idVendedor.value == null ) {
            snackBarApp.value.open('O vendedor/funcionário precisa ser escolhido, preencha o campo do código do vendedor/funcionário e tente novamente.');
            return;
        }   

        let auxDataInicial = Converte.datePtBrToIso(dataInicial.value);
        let auxDataFinal = Converte.datePtBrToIso(dataFinal.value);
        
        try {
            let response = await Relatorio.get(nomeRel, auxDataInicial, auxDataFinal, idVendedor.value);
            if (response.status == 200) {
                if(response.data.success == true) {
                    let nomeArquivo = response.data.nomeArquivo;
                    let url = config.baseUrlRel+nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }

                if(response.data.success == false) {
                    snackBarApp.value.open(response.data.message);
                    return;
                }

                snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
            }
        }
        catch (error) {
            snackBarApp.value.open('Erro ao tentar imprimir o relatório.');
        }
    }    
    
    function selecionouVendedor(vendedor) {
        if (vendedor == null) {
            return;
        }

        codigoVendedor.value = vendedor.codigo;
        getVendedor();
        dialogVendedor.value = false;
    }

    async function getVendedor() {
        idVendedor.value = null;
        nomeVendedor.value = "";
        if (codigoVendedor.value.toString().trim() == "") {
            return;
        }

        let dados = new FormData();
        dados.append("codigo", codigoVendedor.value);
        dados.append("codigoModulo", "04.09");
        dados.append("getInativosBloqueados", true);

        try {
            let response = await Vendedor.getByCodigo(dados);
            if (response.status == 200) {
                if (response.data.success == false) {
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("O vendedor/funcionário não foi encontrado, tente outro código ou faça uma busca.");
                    codigoVendedor.value = "";
                    return;
                }
                idVendedor.value = response.data.data.idVendedor;
                codigoVendedor.value = response.data.data.codigo;
                nomeVendedor.value = response.data.data.nome;
            }
        } catch (err) {
            console.log("Erro ao buscar o vendedor/funcionário: " + err);
        }
    }
    

</script>


<template>
    <v-container>
        <v-card elevation="16">
            <v-card-title>
            <h4>Digite as Datas Inicial, Final, Vendedor/Funcionário e clique em Confirmar</h4>
            </v-card-title>
            
            <form ref="formTelaDatasVendedor" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <Data v-model="dataInicial" label="Data Inicial" id="dataInicial" />
                    </v-col>
                    <v-col cols="6">
                        <Data v-model="dataFinal" label="Data Final" id="dataFinal" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field id="codigoVendedor" label="Cód.Vendedor" type="text" v-model="codigoVendedor" @blur="getVendedor()"></v-text-field>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="Nome do Vendedor/Funcionário" type="text" v-model="nomeVendedor" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um vendedor/funcionário por nome/CPF.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogVendedor = true">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogVendedor" transition="dialog-top-transition">
                                    <VendedorBusca 
                                        @selecionouVendedor="selecionouVendedor" 
                                        @close-dialog="dialogVendedor = false"
                                        :select-inativo-bloqueado=true />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnTelaDatasVendedorConfirmar" >Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>
