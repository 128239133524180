<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { Produto } from "../../resources/produtoResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useProduto } from "../../store/produto";
import {
    valorFormat,
    pCustoProdutoFormat,
    porcentagemFormat,
    quantidadeProdutoFormat,
} from "../../services/filtros";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

const produtoStore = useProduto();
let produto = ref({});
let produtos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(produtoStore.currentPage);
const filtro = ref(produtoStore.filtro);
const filtrarPor = ref(produtoStore.filtrarPor);
const itemsFiltrarPor = ref([
    { value: "codigo", label: "Código" },
    { value: "descricao", label: "Descrição" },
    { value: "codigoBarras", label: "Código de Barras" },
    { value: "referencia", label: "Referência" },
]);

const direction = computed(() => {
    return produtoStore.sort[0].direction;
});

const orderBy = computed(() => {
    return produtoStore.sort[0].property;
});

onMounted(() => {
    search();
});

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(produto.value));

    try {
        const response = await Produto.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Produto excluído com sucesso.");
                search();
            }
            if (response.data.success == false) {
                snackBarApp.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(serv) {
    produto.value = serv;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão do produto: " + produto.value.descricao + " ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}
async function search() {
    let start = produtoStore.start;
    let limit = produtoStore.limit;
    let sort = JSON.stringify(produtoStore.sort);

    produtoStore.setFiltro(filtro.value);
    produtoStore.setFiltrarPor(filtrarPor.value);
    try {
        const response = await Produto.get(
            start,
            limit,
            filtro.value,
            sort,
            filtrarPor.value
        );
        produtos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    produtoStore.setCurrentPage(val);
    produtoStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = produtoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];
    produtoStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Produtos</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="2">
            <v-btn v-show="temPermissao('01.03.01')" color="primary" :to="{ name: 'Produto.Create' }">Novo
                Produto</v-btn>
        </v-col>
        <v-col cols="10">
            <v-form>
                <v-row>
                    <v-col cols="4">
                        <v-select v-model="filtrarPor" :items="itemsFiltrarPor" item-title="label" item-value="value"
                            label="Filtrar Por"></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Filtro" type="text" v-model="filtro" prepend-inner-icon="mdi-magnify">
                            <template v-slot:append>
                                <v-btn color="primary" @click.prevent="search()"
                                    prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC'
                                    ? 'mdi-order-alphabetical-ascending'
                                    : 'mdi-order-alphabetical-descending'
                                "></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('codigoBarras')">
                            Código de Barras
                            <v-icon v-if="orderBy == 'codigoBarras'" :icon="direction == 'ASC'
                                    ? 'mdi-order-alphabetical-ascending'
                                    : 'mdi-order-alphabetical-descending'
                                "></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('referencia')">
                            Referência
                            <v-icon v-if="orderBy == 'referencia'" :icon="direction == 'ASC'
                                    ? 'mdi-order-alphabetical-ascending'
                                    : 'mdi-order-alphabetical-descending'
                                "></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('descricao')">
                            Descrição do Produto
                            <v-icon v-if="orderBy == 'descricao'" :icon="direction == 'ASC'
                                    ? 'mdi-order-alphabetical-ascending'
                                    : 'mdi-order-alphabetical-descending'
                                "></v-icon>
                        </a>
                    </th>
                    <th>Unidade</th>
                    <th class="text-right">Preço de Custo</th>
                    <th class="text-right">Margem Lucro</th>
                    <th class="text-right">Preço de Venda</th>
                    <th class="text-right">Quantidade</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in produtos" :key="o">
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-center' : 'text-center'">
                        <v-tooltip text="Altera o produto.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.03.02')"
                                    :to="{
                                        name: 'Produto.Update',
                                        params: { id: o.idProduto },
                                    }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o produto.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-tab-search"
                                    v-show="temPermissao('01.03.04')" :to="{
                                        name: 'Produto.Show',
                                        params: { id: o.idProduto },
                                    }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o produto.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('01.03.03')" v-bind="props" variant="text"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>

                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.codigo }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.codigoBarras }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.referencia }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.descricao }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.unidade }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-right' : 'text-right'">{{ pCustoProdutoFormat(o.pCusto) }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-right' : 'text-right'">{{ porcentagemFormat(o.margem) }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-right' : 'text-right'">{{ valorFormat(o.pVenda) }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-right' : 'text-right'">{{ quantidadeProdutoFormat(o.quantidade) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
            :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('01.03.01')" color="primary" :to="{ name: 'Produto.Create' }">Novo
        Produto</v-btn>
</template>
