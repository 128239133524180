import { defineStore } from 'pinia';

export const useConfiguracoesContasReceber = defineStore('configuracoesContasReceber', {
    state: () => {
        return {
            jaCarregado: false,
            multa: 0,
            jurosDiario:0,
            carenciaDiasJuros:0,
        }
    },
    actions : {
        setJaCarregado(jaCarregado) {
            this.jaCarregado = jaCarregado;
        },
        setMulta(multa) {
            this.multa = multa;
        },
        setJurosDiario(jurosDiario) {
            this.jurosDiario = jurosDiario;
        },
        setCarenciaDiasJuros(carenciaDiasJuros) {
            this.carenciaDiasJuros = carenciaDiasJuros;
        },
    }
});


