<script setup>
import { ref, onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ContaReceber } from "../../resources/contaReceberResource";
import {Cliente} from "../../resources/clienteResource";
import {Vendedor} from "../../resources/vendedorResource";
import { GrupoContasReceber } from "../../resources/grupoContasReceberResource";
import { SubgrupoContasReceber } from "../../resources/subgrupoContasReceberResource";
import { TipoDocumento} from "../../resources/tipoDocumentoResource";
import { PermissaoService } from "../../services/permissaoService";
import {ContaReceberService} from "../../services/contaReceberService";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import { useConfiguracoesContasReceber } from "../../store/configuracoesContasReceber";
import GrupoContasReceberCreate from "../GruposContasReceber/GrupoContasReceberCreate.vue";
import SubgrupoContasReceberCreate from "../SubgruposContasReceber/SubgrupoContasReceberCreate.vue";
import VendedorBusca from "../Vendedores/VendedorBusca.vue";
import ClienteCreate from "../Clientes/ClienteCreate.vue";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import TipoDocumentoCreate from "../TiposDocumentos/TipoDocumentoCreate.vue";
import ContasReceberRecebimentos from "./ContasReceberRecebimentos.vue";
import Moeda from "../Utils/moeda.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { isBefore } from "date-fns";

const isRealValidation = ref(null);
const validationLancamento = ref(null);
const isReadOnly = ref(false);
const route = useRoute();
const router = useRouter();
let dialogGrupoContasReceber = ref(false);
let dialogSubgrupoContasReceber = ref(false);
const dialogCliente = ref(false);
const dialogBuscaCliente = ref(false);
const dialogBuscaVendedor = ref(false);
const dialogTiposDocumentos = ref(false);
let showBtnGravar = ref(true);
let primeiraCarga = true;
let dialogUltimosRecebimentos = ref(false);

const snackBarApp = inject("snackBarApp");

const gruposContasReceber = ref([]);
const subgruposContasReceber = ref([]);
const tiposDocumentos = ref([]);

const permissoesStore = usePermissoes();
const configuracoesContasReceberStore = useConfiguracoesContasReceber();

addMethod(string, "max_value_comissao", function maxValue(message) {
    return string().test("max-value-comissao", message, function (value) {
        if (Converte.stringToFloat(value) > 100) {
            return false;
        }
        return true;
    });
});

addMethod(number, "lancamento_conta_receber_existe", function lancamentoContaReceberExiste(message) {
    return number().test(
        "lancamento-conta-receber-existe", // Name
        message, // Msg
        function (lancamento) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationLancamento.value;
            }
            isRealValidation.value = false;
            validationLancamento.value = false;

            return new Promise((resolve) => {
                ContaReceber.lancamentoExiste(lancamento, lancamentoContaReceber.value.idContaReceber).then((res) => {
                    if (res.data == false) {
                        validationLancamento.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    lancamentoContaReceber: object({
        idContaReceber: undefined,
        lancamento: number()
            .min(1)
            .max(999999999)
            .lancamento_conta_receber_existe("Esse lançamento já está sendo usado por outro lançamento, tente outro código.")
            .typeError("O lançamento deve ser um número")
            .label("O lançamento"),
        dataEmissao: string().required("A data de emissão é obrigatória").label("A data de emissão"),
        dataVencimento: string().required("A data de vencimento é obrigatória").label("A data de vencimento"),
        dataUltimoRecebimento: undefined, 
        idTipoDocumento: undefined,
        documento: string().max(10).label("O documento"),
        codigoCliente: number().required("O código do cliente é obrigatório").typeError("O código do cliente deve ser um número").label("O código do cliente"),
        nomeCliente: undefined,
        idCliente: undefined,
        parcela: string().required("A parcela é obrigatória").label("A parcela"),
        codigoVendedor: undefined,
        nomeVendedor: undefined,
        idVendedor: undefined,
        comissao: string().max_value_comissao("A comissão deve ser menor que 100%"),
        historico: string().max(80).label("O histórico"),
        valor: undefined,
        desconto: undefined,
        acrescimo: undefined,
        multa: undefined,
        juros: undefined,
        multaRecebida: undefined, 
        jurosRecebido:undefined,
        valorAReceber: undefined,
        valorRecebido: undefined,
        idGrupoContasReceber: undefined,
        idSubgrupoContasReceber: undefined,
        observacoes: undefined,
        auxDataUltimoRecebimento: undefined,
        valorAReceberJuros: undefined,
        valorAReceberMulta: undefined,
        situacao: string(),
        valorJaRecebido: undefined,

    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const lancamentoContaReceber = ref({
    idContaReceber: useField("lancamentoContaReceber.idContaReceber").value,
    lancamento: useField("lancamentoContaReceber.lancamento").value,
    dataEmissao: useField("lancamentoContaReceber.dataEmissao").value,
    dataVencimento: useField("lancamentoContaReceber.dataVencimento").value,
    dataUltimoRecebimento: useField("lancamentoContaReceber.dataUltimoRecebimento").value,
    idTipoDocumento: useField("lancamentoContaReceber.idTipoDocumento").value,
    documento: useField("lancamentoContaReceber.documento").value,
    codigoCliente: useField("lancamentoContaReceber.codigoCliente").value,
    nomeCliente: useField("lancamentoContaReceber.nomeCliente").value,
    idCliente: useField("lancamentoContaReceber.idCliente").value,
    parcela: useField("lancamentoContaReceber.parcela").value,
    codigoVendedor: useField("lancamentoContaReceber.codigoVendedor").value,
    nomeVendedor: useField("lancamentoContaReceber.nomeVendedor").value,
    idVendedor: useField("lancamentoContaReceber.idVendedor").value,
    comissao: useField("lancamentoContaReceber.comissao").value,
    historico: useField("lancamentoContaReceber.historico").value,
    valor: useField("lancamentoContaReceber.valor").value,
    desconto: useField("lancamentoContaReceber.desconto").value,
    acrescimo: useField("lancamentoContaReceber.acrescimo").value,
    multa: useField("lancamentoContaReceber.multa").value,
    juros: useField("lancamentoContaReceber.juros").value,
    multaRecebida: useField("lancamentoContaReceber.multaRecebida").value,
    jurosRecebido: useField("lancamentoContaReceber.jurosRecebido").value,
    valorAReceber: useField("lancamentoContaReceber.valorAReceber").value,
    valorRecebido: useField("lancamentoContaReceber.valorRecebido").value,
    idGrupoContasReceber: useField("lancamentoContaReceber.idGrupoContasReceber").value,
    idSubgrupoContasReceber: useField("lancamentoContaReceber.idSubgrupoContasReceber").value,
    observacoes: useField("lancamentoContaReceber.observacoes").value,
    auxDataUltimoRecebimento: useField("lancamentoContaReceber.auxDataUltimoRecebimento").value,
    valorAReceberJuros: useField("lancamentoContaReceber.valorAReceberJuros").value,
    valorAReceberMulta: useField("lancamentoContaReceber.valorAReceberMulta").value,
    situacao: useField("lancamentoContaReceber.situacao").value,
    valorJaRecebido: useField("lancamentoContaReceber.valorJaRecebido").value,

});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    isReadOnly.value = false;

    if (route.name == "ContasReceber.Update" || route.name == "ContasReceber.Show" || route.name == "ContasReceber.Quita") {
        getLancamentoContaReceber(route.params.id);
        document.getElementById("codigoVendedor").setAttribute("tabindex", "-1");
        document.getElementById("comissao").setAttribute("tabindex", "-1");
    } else {
        proximoLancamento();
        document.getElementById("lancamento").focus();
    }

    getGruposContasReceber();
    getTiposDocumentos();

    if (route.name == "ContasReceber.Show") {
        isReadOnly.value = true;
    }

    if(route.name == 'ContasReceber.Update') {
        document.getElementById("dataUltimoRecebimento").setAttribute("tabindex", "-1");
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "ContasReceber.Create") {
        lancamentoContaReceber.value.idContaReceber = null;
        proximoLancamento();
    }
    lancamentoContaReceber.value.dataEmissao = new Date().toLocaleDateString("pt-BR");
    lancamentoContaReceber.value.dataVencimento = new Date().toLocaleDateString("pt-BR");
    lancamentoContaReceber.value.dataUltimoRecebimento = "";
    lancamentoContaReceber.value.documento = "";
    lancamentoContaReceber.value.codigoCliente = "";
    lancamentoContaReceber.value.nomeCliente = "";
    lancamentoContaReceber.value.idCliente = null;
    lancamentoContaReceber.value.parcela = "001/001";
    lancamentoContaReceber.value.codigoVendedor = "";
    lancamentoContaReceber.value.nomeVendedor = "";
    lancamentoContaReceber.value.idVendedor = null;
    lancamentoContaReceber.value.comissao = "0,0000 %";
    lancamentoContaReceber.value.historico = "";
    lancamentoContaReceber.value.valor = "R$ 0,00";
    lancamentoContaReceber.value.desconto = "R$ 0,00";
    lancamentoContaReceber.value.acrescimo = "R$ 0,00";
    lancamentoContaReceber.value.valorAReceber = "R$ 0,00";
    lancamentoContaReceber.value.valorRecebido = "R$ 0,00";
    lancamentoContaReceber.value.idGrupoContasReceber = null;
    lancamentoContaReceber.value.idSubgrupoContasReceber = null;
    lancamentoContaReceber.value.observacoes = "";
    lancamentoContaReceber.value.auxDataUltimoRecebimento = "";
    lancamentoContaReceber.value.valorAReceberJuros = 0;
    lancamentoContaReceber.value.valorAReceberMulta = 0;
    lancamentoContaReceber.value.jurosRecebido = 0;
    lancamentoContaReceber.value.multaRecebida = 0;
    lancamentoContaReceber.value.situacao = "A";

    if(configuracoesContasReceberStore.jaCarregado) 
    {
        lancamentoContaReceber.value.multa = Converte.numberToPorcentagem(configuracoesContasReceberStore.multa, 4);
        lancamentoContaReceber.value.juros = Converte.numberToPorcentagem(configuracoesContasReceberStore.jurosDiario, 4);
    }
    else {
        setTimeout(() => {
            lancamentoContaReceber.value.multa = Converte.numberToPorcentagem(configuracoesContasReceberStore.multa, 4);
            lancamentoContaReceber.value.juros = Converte.numberToPorcentagem(configuracoesContasReceberStore.jurosDiario, 4);
        }, 500);
    }

}

async function gravar(values, actions) {
    let dados = new FormData();

    values.lancamentoContaReceber.dataEmissao = Converte.datePtBrToIso(values.lancamentoContaReceber.dataEmissao); 
    values.lancamentoContaReceber.dataVencimento = Converte.datePtBrToIso(values.lancamentoContaReceber.dataVencimento); 
    
    let dataUltimoRecebimento = '';

    if(values.lancamentoContaReceber.dataUltimoRecebimento == '') {
        dataUltimoRecebimento = null;
    } else{
        dataUltimoRecebimento = Converte.datePtBrToIso(values.lancamentoContaReceber.dataUltimoRecebimento); 
    }

    values.lancamentoContaReceber.dataUltimoRecebimento = dataUltimoRecebimento;
    
    dados.append("dados", JSON.stringify(values.lancamentoContaReceber));

    if(route.name == 'ContasReceber.Create') { 
        if((values.lancamentoContaReceber.dataUltimoRecebimento == '' || values.lancamentoContaReceber.dataUltimoRecebimento == null)
             && Converte.stringToFloat(values.lancamentoContaReceber.valorRecebido) > 0) {
            snackBarApp.value.open("Preencha a data do valor do último recebimento, ou deixe zerado o campo 'Valor Recebido'.", 6000);
            document.getElementById("dataUltimoRecebimento").focus();
            return;
        }
        if((values.lancamentoContaReceber.dataUltimoRecebimento !== '' && values.lancamentoContaReceber.dataUltimoRecebimento !== null) 
            && Converte.stringToFloat(values.lancamentoContaReceber.valorRecebido) == 0) {
            snackBarApp.value.open("Preencha o valor recebido, ou deixe em branco o campo 'Data do Último Recebimento'.", 6000);
            document.getElementById("valorRecebido").focus();
            return;
        }


        try {
            const response = await ContaReceber.novoLancamento(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O lançamento não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Novo lançamento de conta a receber cadastrado com sucesso.");
                actions.resetForm();
                cleanForm();
                document.getElementById("lancamento").focus();
            }
        } catch (err) {
           snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ContasReceber.Update') {
        try {
            const response = await ContaReceber.alteraLancamento(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O lançamento de contas a receber não pode ser alterado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                actions.resetForm();
                cleanForm();
                snackBarApp.value.open("Lançamento de contas a receber alterado com sucesso.");
                
                router.push({ name: "ContasReceber.List" });

            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ContasReceber.Quita') {
        try {
            const response = await ContaReceber.quitaLancamento(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O lançamento de contas a receber não pode ser quitado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Lançamento de contas a receber quitado com sucesso.");
                actions.resetForm();
                cleanForm();
                router.push({'name':'ContasReceber.List'});
            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }
}

async function getGruposContasReceber() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoContasReceber: null, descricao: "" };

    const response = await GrupoContasReceber.get(start, limit, "", sort);
    if (response.status == 200) {
        gruposContasReceber.value = response.data.data;
        gruposContasReceber.value.unshift(primeiroRegistro);

        if (lancamentoContaReceber.value.idGrupoContasReceber == null) {
            lancamentoContaReceber.value.idGrupoContasReceber = response.data.data[0].idGrupoContasReceber;
            return;
        }
        getSubgruposContasReceber();
    }
}

async function getSubgruposContasReceber() {
    let dados = new FormData();
    dados.append("idGrupoContasReceber", lancamentoContaReceber.value.idGrupoContasReceber);
    subgruposContasReceber.value = [];
    if (primeiraCarga == false) {
        lancamentoContaReceber.value.idSubgrupoContasReceber = null;
    }

    const response = await SubgrupoContasReceber.getSubgrupos(dados);
    if (response.status == 200) {
        let primeiroRegistro = {
            idSubgrupoContasReceber: null,
            descricao: "",
            idGrupoContasReceber: null,
        };
        subgruposContasReceber.value = response.data.data;
        subgruposContasReceber.value.unshift(primeiroRegistro);
        if ((route.name !== "ContasReceber.Update" && route.name !== "ContasReceber.Show") || primeiraCarga == false) {
            lancamentoContaReceber.value.idSubgrupoContasReceber = response.data.data[0].idSubgrupoContasReceber;
        }

        primeiraCarga = false;
    }
}

async function getTiposDocumentos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idTipoDocumento: null, descricao: "" };

    const response = await TipoDocumento.get(start, limit, "", sort);
    if (response.status == 200) {
        tiposDocumentos.value = response.data.data;
        tiposDocumentos.value.unshift(primeiroRegistro);

        if (lancamentoContaReceber.value.idTipoDocumento == null) {
            lancamentoContaReceber.value.idTipoDocumento = response.data.data[0].idTipoDocumento;
            return;
        }
    }
}


async function getLancamentoContaReceber(id) {
    let dados = new FormData();
    if (route.name == "ContasReceber.Update") {
        dados.append("codigoModulo", "03.02.03");
    } else if (route.name == "ContasReceber.Show") {
        dados.append("codigoModulo", "03.02.09");
    } else if (route.name == "ContasReceber.Quita") {
        dados.append("codigoModulo", "03.02.04");
    } 

    dados.append("idContaReceber", id);

    try {
        let response = await ContaReceber.getById(dados)
        if (response.status == 200) {

            response.data.data.dataVencimento = Converte.dateIsoToPtBr(response.data.data.dataVencimento);
            response.data.data.dataEmissao = Converte.dateIsoToPtBr(response.data.data.dataEmissao);
            if(response.data.data.dataUltimoRecebimento !== null) {
                response.data.data.dataUltimoRecebimento = Converte.dateIsoToPtBr(response.data.data.dataUltimoRecebimento);
            }
            
            while(response.data.data.numeroParcela.length < 3 ) {

                response.data.data.numeroParcela = '0'+ response.data.data.numeroParcela;
            }

            while(response.data.data.numeroParcelas.length < 3 ) {
                response.data.data.numeroParcelas = '0'+  response.data.data.numeroParcelas;
            }

            response.data.data.parcela = response.data.data.numeroParcela + "/" + response.data.data.numeroParcelas;


            response.data.data.multa = Converte.numberToPorcentagem(response.data.data.multa,4);
            response.data.data.juros = Converte.numberToPorcentagem(response.data.data.juros,4);
            response.data.data.comissao = Converte.numberToPorcentagem(response.data.data.comissao,4);

            response.data.data.valor  = Converte.numberToCurrency(response.data.data.valor);
            response.data.data.valorAReceber= Converte.numberToCurrency(response.data.data.valorAReceber);
            response.data.data.valorJaRecebido = Converte.numberToCurrency(response.data.data.valorRecebido);
            response.data.data.valorRecebido = Converte.numberToCurrency(response.data.data.valorRecebido);
            response.data.data.desconto = Converte.numberToCurrency(response.data.data.desconto);
            response.data.data.acrescimo = Converte.numberToCurrency(response.data.data.acrescimo);

            if(response.data.data.jurosRecebido == null) {
                response.data.data.jurosRecebido = 0;
            }

            if(response.data.data.multaRecebida == null) {
                response.data.data.multaRecebida = 0;
            }

            if(response.data.data.valorAReceberJuros == null) {
                response.data.data.valorAReceberJuros = 0;
            }

            if(response.data.data.valorAReceberMulta == null) {
                response.data.data.valorAReceberMulta = 0;
            }

            if(route.name == 'ContasReceber.Quita') {
                response.data.data.valorRecebido = response.data.data.valorAReceber;
                response.data.data.dataUltimoRecebimento = new Date().toLocaleDateString("pt-BR");
            }


            setValues({ lancamentoContaReceber: response.data.data });

            onBlurParcela();

        }
    }
    catch (err) {
        console.log(err);
    }
}

async function proximoLancamento() {
    try {
        const response = await ContaReceber.proximoLancamento();
        if (response.status == 200) {
            lancamentoContaReceber.value.lancamento = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

async function getCliente() {
    
    lancamentoContaReceber.value.idCliente = "";
    lancamentoContaReceber.value.nomeCliente = "";

    if (lancamentoContaReceber.value.codigoCliente.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", lancamentoContaReceber.value.codigoCliente);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.02");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    lancamentoContaReceber.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    snackBarApp.value.open("Um cliente inativo não pode gerar lançamentos de contas a receber.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    lancamentoContaReceber.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    snackBarApp.value.open("Um cliente bloqueado não pode gerar lançamentos de contas a receber.", 6000);
                    return;
                }
                lancamentoContaReceber.value.codigoCliente = "";
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("Cliente não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            lancamentoContaReceber.value.idCliente = response.data.data.idCliente;
            lancamentoContaReceber.value.nomeCliente = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    lancamentoContaReceber.value.codigoCliente = cliente.codigo;
    lancamentoContaReceber.value.nomeCliente = cliente.nome;
    lancamentoContaReceber.value.idCliente = cliente.idCliente;

    getCliente();
    dialogBuscaCliente.value = false;
}

async function getVendedor() {
    lancamentoContaReceber.value.idVendedor = null;
    lancamentoContaReceber.value.nomeVendedor = "";

    if (lancamentoContaReceber.value.codigoVendedor == null || lancamentoContaReceber.value.codigoVendedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", lancamentoContaReceber.value.codigoVendedor);
    dados.append("getInativosBloqueados", false);
    dados.append("codigoModulo", "03.02");

    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    lancamentoContaReceber.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor inativo não pode fazer lançamentos de contas a receber.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    lancamentoContaReceber.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor bloqueado não pode fazer lançamentos de contas a receber.", 6000);
                    return;
                }

                lancamentoContaReceber.value.codigoVendedor = "";
                document.getElementById("codigoVendedor").focus();

                snackBarApp.value.open("Vendedor não encontrado, tente outro código ou faça uma busca.", 6000);

                lancamentoContaReceber.value.codigoVendedor = "";
                return;
            }
            lancamentoContaReceber.value.idVendedor = response.data.data.idVendedor;
            lancamentoContaReceber.value.nomeVendedor = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    lancamentoContaReceber.value.codigoVendedor = vendedor.codigo;
    lancamentoContaReceber.value.nomeVendedor = vendedor.nome;
    lancamentoContaReceber.value.idVendedor = vendedor.idVendedor;

    getVendedor();

    dialogBuscaVendedor.value = false;
}

function cadastrouCliente(codigoCliente) {
    if (codigoCliente == null) {
        return;
    }

    lancamentoContaReceber.value.codigoCliente = codigoCliente;

    getCliente();
}

function calculaValorAReceber() {

    let valorJaRecebido = Converte.stringToFloat(lancamentoContaReceber.value.valorJaRecebido);
    let valor = Converte.stringToFloat(lancamentoContaReceber.value.valor);
    let acrescimo = Converte.stringToFloat(lancamentoContaReceber.value.acrescimo);
    let desconto = Converte.stringToFloat(lancamentoContaReceber.value.desconto);
    let juros = Converte.stringToFloat(lancamentoContaReceber.value.juros);
    let multa = Converte.stringToFloat(lancamentoContaReceber.value.multa);
    let jurosRecebido = parseFloat(lancamentoContaReceber.value.jurosRecebido);
    let multaRecebida = parseFloat(lancamentoContaReceber.value.multaRecebida);
    let valorAReceberMulta = parseFloat(lancamentoContaReceber.value.valorAReceberMulta);
    let valorAReceberJuros = parseFloat(lancamentoContaReceber.value.valorAReceberJuros);

    let auxDataVencimento = Converte.datePtBrToIso(lancamentoContaReceber.value.dataVencimento);
    let auxDataRecebimento = Converte.datePtBrToIso(lancamentoContaReceber.value.dataUltimoRecebimento);
    let auxDataUltimoRecebimento = Converte.datePtBrToIso(lancamentoContaReceber.value.dataUltimoRecebimento);

    if(valorJaRecebido == 0 || auxDataRecebimento == null || auxDataRecebimento == '' ){
        auxDataRecebimento = Converte.datePtBrToIso(new Date().toLocaleDateString("pt-BR"));
    } else
    {
        auxDataRecebimento = Converte.datePtBrToIso(new Date().toLocaleDateString("pt-BR"));
        if(isBefore(auxDataVencimento, auxDataUltimoRecebimento)) {
            auxDataVencimento = auxDataUltimoRecebimento;
        }
    }

    let resultado = ContaReceberService.calculaValorTotalAReceber(
        auxDataVencimento, auxDataRecebimento, valor, acrescimo, valorAReceberJuros,
        valorAReceberMulta, valorJaRecebido, jurosRecebido,
        multaRecebida, desconto, juros, multa, configuracoesContasReceberStore.carenciaDiasJuros,
        lancamentoContaReceber.value.situacao);


    lancamentoContaReceber.value.valorAReceber = Converte.numberToCurrency(resultado.valorAreceber);
}

function onBlurParcela() {
    let value = lancamentoContaReceber.value.parcela;

    let posBarra = value.indexOf("/");

    if(posBarra < 0) {
        return false;
    }

    let parcela = value.substring(0, posBarra);
    let parcelas = value.substring(posBarra+1);

    if(Number.isInteger(parseInt(parcela)) == false ) {
        return false;
    }

    if(Number.isInteger(parseInt(parcelas)) == false ) {
        return false;
    }

    if(parseInt(parcela) > parseInt(parcelas)) {
        return false;
    }


    while(parcela.length < 3 ) {

        parcela = '0'+parcela;
    }

    while(parcelas.length < 3 ) {
        parcelas = '0'+parcelas;
    }

    lancamentoContaReceber.value.parcela = parcela + "/" + parcelas;
}               


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="route.name == 'ContasReceber.Create'">Novo Lançamento de Contas a Receber</h2>
            <h2 v-show="route.name == 'ContasReceber.Update'">Alteração de Lançamento de Contas a Receber</h2>
            <h2 v-show="route.name == 'ContasReceber.Quita'">Quita um Lançamento de Contas a Receber</h2>
            <h2 v-show="route.name == 'ContasReceber.Show'">Consulta de um Lançamento de Contas a Receber</h2>
        </v-card-title>
        <form ref="formContaReceber" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Lançamento"
                            id="lancamento"
                            type="text"
                            v-model="lancamentoContaReceber.lancamento"
                            :error-messages="errors['lancamentoContaReceber.lancamento']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    lancamentoContaReceber.lancamento = e.target.value;
                                }
                            "
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data de Emissão" id="dataEmissao" v-model="lancamentoContaReceber.dataEmissao" 
                            :error-messages="errors['lancamentoContaReceber.dataEmissao']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data de Vencimento" id="dataVencimento" v-model="lancamentoContaReceber.dataVencimento" 
                            :error-messages="errors['lancamentoContaReceber.dataVencimento']" :readonly="isReadOnly"
                            @blur="calculaValorAReceber()" />
                    </v-col>
                    <v-col cols="3">
                        <Data label="D.Último Recebimento" id="dataUltimoRecebimento" v-model="lancamentoContaReceber.dataUltimoRecebimento" 
                            :error-messages="errors['lancamentoContaReceber.dataUltimoRecebimento']" 
                            :readonly="isReadOnly || route.name=='ContasReceber.Update'">
                            <template v-slot:append v-if="route.name !== 'ContasReceber.Create'">
                                <v-tooltip text="Consulta os recebimentos desse lançamento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogUltimosRecebimentos = true"
                                            @blur="calculaValorAReceber()"
                                            id="btnConsultaUltimosRecebimentos">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </template>
                        </Data>
                        <v-dialog v-model="dialogUltimosRecebimentos" transition="dialog-top-transition">
                           <ContasReceberRecebimentos @close-dialog="dialogUltimosRecebimentos = false" />
                        </v-dialog>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select
                            :items="tiposDocumentos"
                            item-title="descricao"
                            item-value="idTipoDocumento"
                            label="Tipo de Documento"
                            v-model="lancamentoContaReceber.idTipoDocumento"
                            :readonly="isReadOnly"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo tipo de documento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogTiposDocumentos = true"
                                            id="btnNovoTipoDocumento"
                                            v-show="temPermissao('01.04.01') && route.name !== 'ContasReceber.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogTiposDocumentos" transition="dialog-top-transition">
                                    <TipoDocumentoCreate @cadastrouTipoDocumento="getTiposDocumentos()" @close-dialog="dialogTiposDocumentos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Documento"
                            id="documento"
                            type="text"
                            v-model="lancamentoContaReceber.documento"
                            maxlength="10"
                            :error-messages="errors['lancamentoContaReceber.documento']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Parcela"
                            id="parcela"
                            type="text"
                            v-model="lancamentoContaReceber.parcela"
                            maxlength="7"
                            :error-messages="errors['lancamentoContaReceber.parcela']"
                            @blur="onBlurParcela()"
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código do Cliente"
                            id="codigoCliente"
                            type="text"
                            v-model="lancamentoContaReceber.codigoCliente"
                            maxlength="10"
                            :error-messages="errors['lancamentoContaReceber.codigoCliente']"
                            @blur="getCliente()"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field
                            label="Nome do Cliente"
                            id="nomeCliente"
                            type="text"
                            v-model="lancamentoContaReceber.nomeCliente"
                            maxlength="50"
                            :error-messages="errors['lancamentoContaReceber.nomeCliente']"
                            readonly 
                            tabindex="-1">
                            <template v-slot:append v-if="route.name !=='ContasReceber.Show'">
                                <v-tooltip text="Faz a busca de um cliente por nome/cpf.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaCliente = true" id="btnBuscaCliente">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaCliente" transition="dialog-top-transition">
                                    <ClienteBusca 
                                        @selecionouCliente="selecionouCliente" 
                                        @close-dialog="dialogBuscaCliente = false"
                                        :select-inativo-bloqueado=false />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo cliente.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnNovoCliente">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                    <ClienteCreate @cadastrouCliente="cadastrouCliente" @close-dialog="dialogCliente = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código do Vendedor"
                            id="codigoVendedor"
                            type="text"
                            v-model="lancamentoContaReceber.codigoVendedor"
                            maxlength="10"
                            :error-messages="errors['lancamentoContaReceber.codigoVendedor']"
                            @blur="getVendedor()"
                            :readonly="isReadOnly || route.name=='ContasReceber.Quita' || route.name=='ContasReceber.Edit' ">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="Nome do Vendedor"
                            id="nomeVendedor"
                            type="text"
                            v-model="lancamentoContaReceber.nomeVendedor"
                            maxlength="50"
                            :error-messages="errors['lancamentoContaReceber.nomeVendedor']"
                            readonly 
                            tabindex="-1">
                            <template v-slot:append v-if="route.name=='ContasReceber.Create'">
                                <v-tooltip text="Faz a busca de um vendedor por nome/cpf.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaVendedor = true" id="btnBuscaVendedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaVendedor" transition="dialog-top-transition">
                                    <VendedorBusca 
                                        @selecionouVendedor="selecionouVendedor" 
                                        @close-dialog="dialogBuscaVendedor = false"
                                        :select-inativo-bloqueado=false />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Porcentagem
                            label="Comissão(%)"
                            id="comissao"
                            type="text"
                            tipo="comissao"
                            v-model="lancamentoContaReceber.comissao"
                            :error-messages="errors['lancamentoContaReceber.comissao']"
                            :readonly="isReadOnly || route.name=='ContasReceber.Quita' || route.name=='ContasReceber.Edit' "/>
                    </v-col>    
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Histórico"
                            id="historico"
                            type="text"
                            v-model="lancamentoContaReceber.historico"
                            maxlength="80"
                            :error-messages="errors['lancamentoContaReceber.historico']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Moeda
                            label="Valor da Parcela"
                            id="valor"
                            type="text"
                            v-model="lancamentoContaReceber.valor"
                            :error-messages="errors['lancamentoContaReceber.valor']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAReceber()"
                        />
                    </v-col>
                    <v-col v-show="route.name !== 'ContasReceber.Create'">
                        <Moeda
                            label="Valor Já Recebido"
                            id="valorJaRecebido"
                            type="text"
                            v-model="lancamentoContaReceber.valorJaRecebido"
                            readonly
                            tabIndex="-1"
                            
                            
                        />
                    </v-col>

                    <v-col>
                        <Moeda
                            label="Descontos(R$)"
                            id="desconto"
                            type="text"
                            v-model="lancamentoContaReceber.desconto"
                            :error-messages="errors['lancamentoContaReceber.desconto']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAReceber()"
                        />
                    </v-col>
                    <v-col>
                        <Moeda
                            label="Acréscimos(R$)"
                            id="acrescimo"
                            type="text"
                            v-model="lancamentoContaReceber.acrescimo"
                            :error-messages="errors['lancamentoContaReceber.acrescimo']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAReceber()"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <Porcentagem
                            label="Multa(%)"
                            id="multa"
                            type="text"
                            tipo="multa"
                            v-model="lancamentoContaReceber.multa"
                            :error-messages="errors['lancamentoContaReceber.multa']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAReceber()"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Porcentagem
                            label="Juros(%)"
                            id="juros"
                            type="text"
                            tipo="juros"
                            v-model="lancamentoContaReceber.juros"
                            :error-messages="errors['lancamentoContaReceber.juros']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAReceber()"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Moeda
                            label="Valor a Receber"
                            id="valorAReceber"
                            type="text"
                            v-model="lancamentoContaReceber.valorAReceber"
                            :error-messages="errors['lancamentoContaReceber.valorAReceber']"
                            readonly
                            tabIndex=-1
                        />
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-col cols="4" v-if="route.name !== 'ContasReceber.Update' && route.name !== 'ContasReceber.Show'">
                        <Moeda
                            
                            label="Valor Recebido"
                            id="valorRecebido"
                            type="text"
                            v-model="lancamentoContaReceber.valorRecebido"
                            :error-messages="errors['lancamentoContaReceber.valorRecebido']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAReceber()"
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            :items="gruposContasReceber"
                            item-title="descricao"
                            item-value="idGrupoContasReceber"
                            label="Grupo de Contas a Receber"
                            v-model="lancamentoContaReceber.idGrupoContasReceber"
                            @update:modelValue="getSubgruposContasReceber()"
                            :readonly="isReadOnly"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de contas a receber.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogGrupoContasReceber = true"
                                            id="btnNovoGrupoContasReceber"
                                            v-show="temPermissao('01.17.01') && route.name !== 'ContasReceber.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoContasReceber" transition="dialog-top-transition">
                                    <GrupoContasReceberCreate @cadastrouGrupoContasReceber="getGruposContasReceber()" @close-dialog="dialogGrupoContasReceber = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12">
                        <v-select
                            :items="subgruposContasReceber"
                            item-title="descricao"
                            item-value="idSubgrupoContasReceber"
                            label="Subgrupo de Contas a Receber"
                            v-model="lancamentoContaReceber.idSubgrupoContasReceber"
                            :readonly="isReadOnly"
                            no-data-text="Nenhum subgrupo cadastrado para esse grupo.">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo subgrupo de contas a receber.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogSubgrupoContasReceber = true"
                                            id="btnNovoSubgrupoContasReceber"
                                            v-show="temPermissao('01.18.01') && route.name !== 'ContasReceber.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogSubgrupoContasReceber" transition="dialog-top-transition">
                                    <SubgrupoContasReceberCreate @cadastrouSubgrupoContasReceber="getGruposContasReceber();" @close-dialog="dialogSubgrupoContasReceber = false" />
                                </v-dialog>
                            </template>

                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            label="Observações"
                            id="observacoes"
                            type="text"
                            v-model="lancamentoContaReceber.observacoes"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" v-show="$route.name !== 'ContasReceber.Show' && showBtnGravar" variant="elevated" type="submit" id="btnContasReceberGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)">
                    Voltar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>