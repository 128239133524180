<template>
    <v-text-field @input="updatevalue" @blur="formatvalue" @focus="selectAll" > 
        <template v-for="(index, name) in $slots" v-slot:[name]> <slot :name="name" /> </template>
    </v-text-field>
</template>

<script setup>
import { defineEmits } from "vue";
import { parse, isValid } from "date-fns";
import { Converte } from "../../utils/converte";

const emit = defineEmits(["update:modelValue"]);

const updatevalue = (value) => {
    if(value.inputType == "deleteContentBackward") {
        if((value.target.value.length == 2 || value.target.value.length == 5) && (value.target.value[1] !== "/" && value.target.value[3] !== "/")) {
            if(value.target.value.indexOf("/") > -1 || value.target.value.length == 2) {
                value.target.value = value.target.value.slice(0, -1);
                emit("update:modelValue", value.target.value);
                return;
            }
            // value.target.value = value.target.value.slice(0, -1);
            // emit("update:modelValue", value.target.value);
            // return;
        }
    }
    if(value.inputType == "deleteContentBackward" || value.inputType == "deleteContentForward") {
        return;
    }

    if (value.target.value.length == 2 || (value.target.value[2] == '/' && value.target.value.length == 5) || 
        (value.target.value[1] == '/' && value.target.value.length == 4)) {
        value.target.value = value.target.value + "/";
    }

    if(value.target.value.length == 3 && value.data == '/' && value.target.value[1] !== '/') {
//        emit("update:modelValue", value.target.value);
        return;    
    }
    
    if (value.data == "/" || value.data == "-") {
        value.target.value = value.target.value.slice(0, -1);
    }

    if(value.target.value[1] == '/' && value.target.value.length == 2) {
        value.target.value = '0' + value.target.value;
    }

    if(value.target.value[4] == '/' && value.target.value.length == 5) {
        value.target.value = value.target.value.slice(0,3) + '0' + value.target.value.slice(3,);
    }


    emit("update:modelValue", value.target.value);
};

const formatvalue = (value) => {
    let date = parse(Converte.datePtBrToIso(value.target.value), "yyyy-MM-dd", new Date());

    if (!isValid(date)) {
        value.target.value = "";
        emit("update:modelValue", "");
        return;
    }
};

const selectAll = (value) => {
    setTimeout(function () {
        value.target.select();
    }, 3);
};
</script>
