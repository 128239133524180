<script setup>
import { ref, onMounted, defineEmits, inject } from "vue";
import { useRoute } from "vue-router";
import { GrupoProdutos } from "../../resources/grupoProdutosResource";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { usePermissoes } from "../../store/permissoes";

const emit = defineEmits(["close-dialog", "cadastrou-grupo-produtos"]);
const snackBarApp = inject("snackBarApp");
const route = useRoute();
let showBtnGravar = ref(true);
const permissoesStore = usePermissoes();

const validationSchema = object({
    grupoProdutos: object({
        idGrupoProdutos: undefined,
        descricao: string().required("A descrição é obrigatória").max(50),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const grupoProdutos = ref({
    idGrupoProdutos: useField("grupoProdutos.idGrupoProdutos").value,
    descricao: useField("grupoProdutos.descricao").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    if (route.name == "GrupoProdutos.Update") {
        getGrupoProdutos(route.params.id);
    } else {
        grupoProdutos.value.idGrupoProdutos = null;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});
async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(grupoProdutos.value));

    try {
        const response = await GrupoProdutos.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open(
                    "Grupo de produtos não pode ser cadastrado, mensagem de erro: " +
                    response.data.message,
                    6000
                );
                return;
            }
            if (route.name == "GrupoProdutos.Update") {
                snackBarApp.value.open("Grupo de produtos alterado com sucesso.");
            } else {
                snackBarApp.value.open("Novo grupo de produtos cadastrado com sucesso.");
                actions.resetForm();
                grupoProdutos.value.idGrupoProdutos = null;
                document.getElementById("descricao").focus();
                emit("cadastrou-grupo-produtos");
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroGrupoProdutos() {
    emit("close-dialog");
}
async function getGrupoProdutos(id) {
    let dados = new FormData();
    dados.append("codigoModulo", "01.10.02");
    dados.append("idGrupoProdutos", id);
    try {
        const response = await GrupoProdutos.getById(dados);
        if (response.status == 200) {
            setValues({ grupoProdutos: response.data.data });
            document.getElementById("descricao").focus();
        }
    } catch (err) {
        console.log(err);
    }
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'GrupoProdutos.Update'">
                Novo Grupo de Produtos
            </h2>
            <h2 v-show="$route.name == 'GrupoProdutos.Update'">
                Alteração de Grupo de Produtos
            </h2>
        </v-card-title>

        <form ref="formGrupoProdutos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field label="Descrição" id="descricao" type="text" maxlength="50"
                            v-model="grupoProdutos.descricao" :error-messages="errors['grupoProdutos.descricao']" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnGrupoProdutosGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="$router.go(-1)" v-if="showBtnGravar" v-show="$route.name == 'GrupoProdutos.Create' ||
                    $route.name == 'GrupoProdutos.Update'
                    ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar" v-show="$route.name !== 'GrupoProdutos.Create' &&
                    $route.name !== 'GrupoProdutos.Update'
                    " @click.prevent="closeModalCadastroGrupoProdutos()">Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
