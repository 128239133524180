<script setup>
import { ref, onMounted, defineEmits, inject } from "vue";
import { useRoute } from "vue-router";
import { Produto } from "../../resources/produtoResource";
import { PermissaoService } from "../../services/permissaoService";
import { ProdutoService } from "../../services/produtoService";
import { GrupoProdutos } from "../../resources/grupoProdutosResource";
import { SubgrupoProdutos } from "../../resources/subgrupoProdutosResource";
import { useField, useForm } from "vee-validate";
import { string, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import GrupoProdutosCreate from "../GruposProdutos/GrupoProdutosCreate.vue";
import SubgrupoProdutosCreate from "../SubgruposProdutos/SubgrupoProdutosCreate.vue";
import Moeda from "../Utils/moeda.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";
import Quantidade from "../Utils/quantidade.vue";
import { Converte } from "../../utils/converte";
import { useCasasDecimais } from "../../store/casasDecimais";

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const snackBarApp = inject("snackBarApp");
const ultimoCodigo = ref("");
const route = useRoute();
const casasDecimaisStore = useCasasDecimais();
const emit = defineEmits(["close-dialog", "cadastrou-produto"]);
const isReadOnly = ref(false);

let dialogGrupoProdutos = ref(false);
let dialogSubgrupoProdutos = ref(false);
let showBtnGravar = ref(true);
let primeiraCarga = true;
const permissoesStore = usePermissoes();
const itemsAtivoInativoBloqueado = ref([
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
    { text: "Bloqueado", value: "B" },
]);

addMethod(string, "max_value_comissao", function maxValue(message) {
    return string().test("max-value-comissao", message, function (value) {
        if (Converte.stringToFloat(value) > 100) {
            return false;
        }
        return true;
    });
});

addMethod(string, "codigo_produto_existe", function codigoProdutoExiste(message) {
    return string().test(
        "codigo-produto-existe", // Name
        message, // Msg
        function (codigo_produto) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                Produto.codigoExiste(codigo_produto, produto.value.idProduto).then(
                    (res) => {
                        if (res.data == false) {
                            validationCodigo.value = true;
                            resolve(true);
                        }

                        resolve(false);
                    }
                );
            });
        }
    );
});

const validationSchema = object({
    produto: object({
        idProduto: undefined,
        codigo: string()
            .codigo_produto_existe(
                "Esse código já está sendo usado por outro produto, tente outro código."
            )
            .required("O código é obrigatório"),
        descricao: string()
            .required("A descrição do produto é obrigatória")
            .max(50)
            .label("A descrição do produto"),
        unidade: string().max(3).label("A unidade"),
        codigoBarras: string().max(14).label("O código de barras"),
        referencia: string().max(20).label("A referência"),
        ncm: string().max(8).label("O NCM"),
        idGrupoProdutos: undefined,
        idSubgrupoProdutos: undefined,
        pCusto: undefined,
        margem: undefined,
        pVenda: undefined,
        comissao: string().max_value_comissao("A comissão deve ser menor que 100%"),
        estoqueMinimo: undefined,
        estoqueMaximo: undefined,
        quantidade: undefined,
        observacoes: undefined,
        ativoInativoBloqueado: undefined,
        motivoBloqueado: string().max(50).label("O motivo do bloqueio"),
        motivoInatividade: string().max(50).label("O motivo da inatividade"),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const gruposProdutos = ref([]);
const subgruposProdutos = ref([]);

const produto = ref({
    idProduto: useField("produto.idProduto").value,
    descricao: useField("produto.descricao").value,
    unidade: useField("produto.unidade").value,
    codigo: useField("produto.codigo").value,
    codigoBarras: useField("produto.codigoBarras").value,
    referencia: useField("produto.referencia").value,
    ncm: useField("produto.ncm").value,
    idGrupoProdutos: useField("produto.idGrupoProdutos").value,
    idSubgrupoProdutos: useField("produto.idSubgrupoProdutos").value,
    pCusto: useField("produto.pCusto").value,
    margem: useField("produto.margem").value,
    pVenda: useField("produto.pVenda").value,
    comissao: useField("produto.comissao").value,
    estoqueMinimo: useField("produto.estoqueMinimo").value,
    estoqueMaximo: useField("produto.estoqueMaximo").value,
    quantidade: useField("produto.quantidade").value,
    observacoes: useField("produto.observacoes").value,
    ativoInativoBloqueado: useField("produto.ativoInativoBloqueado").value,
    motivoBloqueado: useField("produto.motivoBloqueado").value,
    motivoInatividade: useField("produto.motivoInatividade").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "Produto.Update" || route.name == 'Produto.Show') {
        getProduto(route.params.id);
    } else {
        getUltimoCodigo();
    }

    if (route.name == "Produto.Show") {
        isReadOnly.value = true;
    }

    getGruposProdutos();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name !== "Produto.Show" && route.name !== "Produto.Update") {
        produto.value.idProduto = null;
    }
    produto.value.descricao = "";
    produto.value.unidade = "";
    produto.value.codigo = "";
    produto.value.codigoBarras = "";
    produto.value.referencia = "";
    produto.value.ncm = "";
    produto.value.idGrupoProdutos = null;
    produto.value.idSubgrupoProdutos = null;
    produto.value.margem = "0,00 %";
    produto.value.pVenda = "R$ 0,00";
    produto.value.comissao = "0,0000 %";
    produto.value.observacoes = "";
    produto.value.ativoInativoBloqueado = "A";
    produto.value.motivoBloqueado = "";
    produto.value.motivoInatividade = "";

    if (casasDecimaisStore.jaCarregado) {
        produto.value.pCusto = casasDecimaisStore.pCustoProdutoZero;
        produto.value.estoqueMinimo = casasDecimaisStore.quantidadeProdutoZero;
        produto.value.estoqueMaximo = casasDecimaisStore.quantidadeProdutoZero;
        produto.value.quantidade = casasDecimaisStore.quantidadeProdutoZero;
    }
    else {
        setTimeout(() => {
            produto.value.pCusto = casasDecimaisStore.pCustoProdutoZero;
            produto.value.estoqueMinimo = casasDecimaisStore.quantidadeProdutoZero;
            produto.value.estoqueMaximo = casasDecimaisStore.quantidadeProdutoZero;
            produto.value.quantidade = casasDecimaisStore.quantidadeProdutoZero;
        }, 500);
    }

}

async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(values.produto));

    try {
        const response = await Produto.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open(
                    "O produto não pode ser gravado, mensagem de erro: " +
                    response.data.message,
                    6000
                );
                return;
            }
            if (route.name == "Produto.Update") {
                snackBarApp.value.open("Produto alterado com sucesso.");
            } else {
                snackBarApp.value.open("Novo produto cadastrado com sucesso.");
                emit("cadastrou-produto", produto.value.codigo);

                actions.resetForm();
                cleanForm();
                getUltimoCodigo();
                document.getElementById("codigoProdutoCreate").focus();
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroProduto() {
    emit("close-dialog");
}
async function getGruposProdutos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoProdutos: null, descricao: "" };

    const response = await GrupoProdutos.get(start, limit, "", sort);
    gruposProdutos.value = response.data.data;
    gruposProdutos.value.unshift(primeiroRegistro);

    if (produto.value.idGrupoProdutos == null) {
        produto.value.idGrupoProdutos = response.data.data[0].idGrupoProdutos;
        return;
    }

    getSubgruposProdutos();
}

async function getSubgruposProdutos() {
    let dados = new FormData();
    dados.append("idGrupoProdutos", produto.value.idGrupoProdutos);
    subgruposProdutos.value = [];
    if (primeiraCarga == false) {
        produto.value.idSubgrupoProdutos = null;
    }

    const response = await SubgrupoProdutos.getSubgrupos(dados);
    if (response.status == 200) {
        let primeiroRegistro = {
            idSubgrupoProdutos: null,
            descricao: "",
            idGrupoProdutos: null,
        };
        subgruposProdutos.value = response.data.data;
        subgruposProdutos.value.unshift(primeiroRegistro);
        if (route.name != "Produto.Update" || primeiraCarga == false) {
            produto.value.idSubgrupoProdutos = response.data.data[0].idSubgrupoProdutos;
        }

        primeiraCarga = false;
    }
}

async function getProduto(id) {
    let dados = new FormData();
    if (route.name == 'Produto.Update') {
        dados.append("codigoModulo", "01.03.02");
    }
    else {
        dados.append("codigoModulo", "01.03.04");
    }

    dados.append("idProduto", id);
    try {
        const response = await Produto.getById(dados);
        if (response.status == 200) {
            setValues({ produto: response.data.data });

            produto.value.pCusto = Converte.numberToPCustoProduto(
                response.data.data.pCusto
            );
            produto.value.pVenda = Converte.numberToCurrency(response.data.data.pVenda);
            produto.value.comissao = Converte.numberToPorcentagem(
                response.data.data.comissao,
                4
            );
            produto.value.margem = Converte.numberToPorcentagem(
                response.data.data.margem,
                2
            );
            produto.value.quantidade = Converte.numberToQuantidadeProduto(
                response.data.data.quantidade
            );
            produto.value.estoqueMinimo = Converte.numberToQuantidadeProduto(
                response.data.data.estoqueMinimo
            );
            produto.value.estoqueMaximo = Converte.numberToQuantidadeProduto(
                response.data.data.estoqueMaximo
            );

            document.getElementById("codigoProdutoCreate").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

function calculaPVenda() {
    let pCusto = Converte.stringToFloat(produto.value.pCusto);
    let margem = Converte.stringToFloat(produto.value.margem);

    if ((margem == 100 && pCusto > 0) || margem > 100) {
        margem = 0;
        produto.value.margem = "0,0000 %";
        snackBarApp.value.open("Erro", "A margem de lucro não pode ser maior que 100%.");
        document.getElementById("margem").focus();
    }

    produto.value.pVenda = Converte.numberToCurrency(
        ProdutoService.calculaPVenda(pCusto, margem)
    );
}

function calculaMargem() {
    let pCusto = Converte.stringToFloat(produto.value.pCusto);
    let pVenda = Converte.stringToFloat(produto.value.pVenda);

    let margem = ProdutoService.calculaMargem(pCusto, pVenda);

    produto.value.margem = Converte.numberToPorcentagem(margem);
}

async function getUltimoCodigo() {
    const response = await Produto.ultimoCodigo();
    ultimoCodigo.value = response.data;
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <form ref="formProduto" @submit.prevent="submit">
        <v-card elevation="16">
            <v-card-title>
                <h2 v-show="$route.name !== 'Produto.Update' && $route.name !== 'Produto.Show'">Novo Produto</h2>
                <h2 v-show="$route.name == 'Produto.Update'">Alteração de Produto</h2>
                <h2 v-show="$route.name == 'Produto.Show'">Consulta de Produto</h2>
            </v-card-title>
            <v-card-text>
                <v-label v-show="$route.name !== 'Produto.Update' && $route.name !== 'Produto.Show'">Último código
                    cadastrado: {{ ultimoCodigo }}</v-label>
                <v-row>
                    <v-col cols="2">
                        <CodigoProduto label="Código" id="codigoProdutoCreate" type="text" v-model="produto.codigo"
                            :error-messages="errors['produto.codigo']" maxlength="15" @blur="(e) => {
                                    isRealValidation = true;
                                    produto.codigo = e.target.value;
                                }
                                " :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Descrição" id="descricao" type="text" v-model="produto.descricao"
                            maxlength="50" :error-messages="errors['produto.descricao']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Unidade" id="unidade" type="text" maxlength="3" v-model="produto.unidade"
                            :error-messages="errors['produto.unidade']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field label="Código de Barras" id="codigoBarras" type="text" maxlength="14"
                            v-model="produto.codigoBarras" :error-messages="errors['produto.codigoBarras']"
                            :readonly="isReadOnly" />
                    </v-col>

                    <v-col cols="4">
                        <v-text-field label="Referência" id="referencia" type="text" maxlength="20"
                            v-model="produto.referencia" :error-messages="errors['produto.referencia']"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="NCM" id="ncm" type="text" maxlength="8" v-model="produto.ncm"
                            :error-messages="errors['produto.ncm']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select :items="gruposProdutos" item-title="descricao" item-value="idGrupoProdutos"
                            label="Grupo de Produtos" v-model="produto.idGrupoProdutos"
                            @update:modelValue="getSubgruposProdutos()" :readonly="isReadOnly">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de produtos.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogGrupoProdutos = true"
                                            id="btnNovoGrupoProdutos" v-show="temPermissao('01.10.01') &&
                                                route.name !== 'Produto.Show'
                                                ">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoProdutos" transition="dialog-top-transition">
                                    <GrupoProdutosCreate @cadastrouGrupoProdutos="getGruposProdutos()"
                                        @close-dialog="dialogGrupoProdutos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="subgruposProdutos" item-title="descricao" item-value="idSubgrupoProdutos"
                            label="Subgrupo de Produtos" v-model="produto.idSubgrupoProdutos" :readonly="isReadOnly"
                            no-data-text="Nenhum subgrupo cadastrado para esse grupo.">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo subgrupo de produtos.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogSubgrupoProdutos = true"
                                            id="btnNovoSubgrupoProdutos" v-show="temPermissao('01.11.01') &&
                                                route.name !== 'Produto.Show'
                                                ">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogSubgrupoProdutos" transition="dialog-top-transition">
                                    <SubgrupoProdutosCreate @cadastrouSubgrupoProdutos="
                                        getGruposProdutos();
                                    getSubgruposProdutos();
                                    " @close-dialog="dialogSubgrupoProdutos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <moeda label="Preço de Custo" id="pCusto" type="text" tipo="pCustoProduto"
                            v-model="produto.pCusto" @blur="calculaMargem()" :error-messages="errors['produto.pCusto']"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="4">
                        <porcentagem label="Margem de Lucro(%)" id="margem" type="text" tipo="margem"
                            v-model="produto.margem" @blur="calculaPVenda()" :error-messages="errors['produto.margem']"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="4">
                        <moeda label="Preço de Venda" id="pVenda" type="text" v-model="produto.pVenda"
                            @blur="calculaMargem()" :error-messages="errors['produto.pVenda']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <porcentagem label="Comissão de Vendedores(%)" id="comissao" type="text" tipo="comissao"
                            v-model="produto.comissao" :error-messages="errors['produto.comissao']"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col>
                        <quantidade label="Estoque Mínimo" id="estoqueMinimo" type="text" tipo="quantidadeProduto"
                            v-model="produto.estoqueMinimo" :error-messages="errors['produto.estoqueMinimo']"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col>
                        <quantidade label="Estoque Máximo" id="estoqueMaximo" type="text" tipo="quantidadeProduto"
                            v-model="produto.estoqueMaximo" :error-messages="errors['produto.estoqueMaximo']"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <quantidade label="Quantidade Atual em Estoque" id="quantidade" type="text" tipo="quantidadeProduto"
                            v-model="produto.quantidade" :error-messages="errors['produto.quantidade']"
                            :readonly="isReadOnly || produto.idProduto !== null" />
                    </v-col>

                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="produto.observacoes"
                            :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row cols="2">
                    <v-col>
                        <v-select :items="itemsAtivoInativoBloqueado" label="Ativo/Inativo/Bloqueado"
                            v-model="produto.ativoInativoBloqueado" item-title="text" item-value="value"
                            :readonly="isReadOnly"></v-select>
                    </v-col>
                    <v-col cols="9" v-show="produto.ativoInativoBloqueado == 'I'">
                        <v-text-field label="Motivo da inatividade" id="motivoInatividade" type="text" maxlength="50"
                            v-model="produto.motivoInatividade" :error-messages="errors['produto.motivoInatividade']"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="9" v-show="produto.ativoInativoBloqueado == 'B'">
                        <v-text-field label="Motivo do Bloqueio" id="motivoBloqueado" maxlength="50" type="text"
                            v-model="produto.motivoBloqueado" :error-messages="errors['produto.motivoBloqueado']"
                            :readonly="isReadOnly" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnProdutoGravar"
                    v-show="$route.name !== 'Produto.Show'">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="$router.go(-1)" v-if="showBtnGravar" v-show="$route.name == 'Produto.Create' ||
                    $route.name == 'Produto.Update' ||
                    $route.name == 'Produto.Show'
                    ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-show="$route.name !== 'Produto.Create' &&
                    $route.name !== 'Produto.Update' &&
                    $route.name !== 'Produto.Show'
                    " @click.prevent="closeModalCadastroProduto()">Fechar</v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>
