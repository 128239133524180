<script setup>
    import { ref, onMounted, defineEmits, inject } from 'vue';
    import { useRoute } from 'vue-router';
    import {SubgrupoProdutos} from '../../resources/subgrupoProdutosResource';
    import {PermissaoService} from '../../services/permissaoService';
    import {GrupoProdutos} from '../../resources/grupoProdutosResource';
    import { useField, useForm } from 'vee-validate'
    import {object, string, number} from 'yup';
    import { usePermissoes } from '../../store/permissoes';
    import GrupoProdutosCreate from '../GruposProdutos/GrupoProdutosCreate.vue';
    
    const emit = defineEmits(['close-dialog', 'cadastrou-subgrupo-produtos']);
    const snackBarApp = inject('snackBarApp');
    const route = useRoute();
    let dialog = ref(false);
    let  showBtnGravar = ref(true);
    const permissoesStore = usePermissoes();

    const validationSchema  = object({
        subgrupoProdutos: object({
            idSubgrupoProdutos: undefined,
            idGrupoProdutos: number().required('O grupo de produtos é obrigatório').label('O grupo de produtos'),
            descricao: string().required('A descrição é obrigatória').max(50)
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const gruposProdutos = ref([]);
    
    const subgrupoProdutos =  ref({ 
        idSubgrupoProdutos: useField('subgrupoProdutos.idSubgrupoProdutos').value,
        idGrupoProdutos: useField('subgrupoProdutos.idGrupoProdutos').value,
        descricao: useField('subgrupoProdutos.descricao').value 
    });

    const submit = handleSubmit((values, actions) => {
        gravar(values, actions);
    });

    onMounted(() => {
        getGruposProdutos();
        if(route.name == 'SubgrupoProdutos.Update') {
            getSubgrupoProdutos(route.params.id);
        }
        else {
            subgrupoProdutos.value.idSubgrupoProdutos = null;
        }


        let tipo = permissoesStore.tipo;
        let validade = permissoesStore.validade;

        if((tipo == 'D' || tipo == 'P') && validade == 'N') {
            showBtnGravar.value = false;
        }
    });
    async function gravar(values, actions) {
        let dados = new FormData();
        dados.append('data', JSON.stringify(subgrupoProdutos.value));

        try {
            const response = await SubgrupoProdutos.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open('Subgrupo de produtos não pode ser cadastrado, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }
                if(route.name == 'SubgrupoProdutos.Update') {
                    snackBarApp.value.open('Subgrupo de produtos alterado com sucesso.');
                }
                else{
                    snackBarApp.value.open('Novo subgrupo de produtos cadastrado com sucesso.');
                    let idGrupoProdutos = subgrupoProdutos.value.idGrupoProdutos;
                    actions.resetForm();
                    subgrupoProdutos.value.idGrupoProdutos = idGrupoProdutos;
                    subgrupoProdutos.value.idSubgrupoProdutos = null;
                    emit('cadastrou-subgrupo-produtos');
                    document.getElementById("descricao").focus();
                }
            }
        }  catch(err) {
            snackBarApp.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    function closeModalCadastroSubgrupoProdutos() {
        emit('close-dialog');
    }
    async function getGruposProdutos() {
        let start = 0;
        let limit = 999999;
        let sort = null;

        await GrupoProdutos.get(start, limit, '', sort)
            .then(function (response) {
                gruposProdutos.value = response.data.data;

                if(subgrupoProdutos.value.idGrupoProdutos == null) {
                    subgrupoProdutos.value.idGrupoProdutos = response.data.data[0].idGrupoProdutos;
                    return;
                }
            })
            .catch(function () {
            });
    }

    async function getSubgrupoProdutos(id) {
        let dados = new FormData();
        dados.append('codigoModulo', '01.11.02');
        dados.append('idSubgrupoProdutos', id);
        try {
            const response = await SubgrupoProdutos.getById(dados);
            if (response.status == 200) {
                setValues({'subgrupoProdutos': response.data.data});
                document.getElementById("descricao").focus();
            }
        } catch(err) {
            console.log(err);
        }
    }

    function temPermissao(codigoModulo) {
        return PermissaoService.temPermissao(codigoModulo);
    }


</script>


<template>
        <v-card elevation="16">
            <v-card-title>
            <h2 v-show="$route.name !=='SubgrupoProdutos.Update'">Novo Subgrupo de Produtos</h2>
                <h2 v-show="$route.name =='SubgrupoProdutos.Update'">Alteração de Subgrupo de Produtos</h2>          
            </v-card-title>
            <form ref="formSubgrupoProdutos" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select
                        :items = "gruposProdutos"
                        item-title="descricao"
                        item-value="idGrupoProdutos"
                        label="Grupo de Produtos"
                        v-model='subgrupoProdutos.idGrupoProdutos'
                        :error-messages="errors['subgrupoProdutos.idGrupoProdutos']"
                        no-data-text="Nenhum grupo de produtos cadastrado."
                    >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de produtos.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialog = true"
                                            id="btnNovoGrupoProdutos" v-show="temPermissao('01.11.01')">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialog" transition="dialog-top-transition" >
                                    <GrupoProdutosCreate @close-dialog="dialog = false"  @cadastrouGrupoProdutos="getGruposProdutos()" />
                                </v-dialog>
                            </template>
                    
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field label="Descrição"
                            id="descricao"
                            type="text"
                            maxlength="50"
                            v-model='subgrupoProdutos.descricao'
                            :error-messages="errors['subgrupoProdutos.descricao']"/>
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnSubgrupoProdutosGravar" >Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                    variant="elevated" 
                    @click.prevent='$router.go(-1)' 
                    v-if="showBtnGravar" v-show="$route.name =='SubgrupoProdutos.Create' || $route.name == 'SubgrupoProdutos.Update' ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar" 
                    v-show="$route.name !=='SubgrupoProdutos.Create' && $route.name !== 'SubgrupoProdutos.Update' " 
                    @click.prevent='closeModalCadastroSubgrupoProdutos()'>Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
</template>