<script setup>
import { ref, onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ChequeRecebido } from "../../resources/chequeRecebidoResource";
import {Cliente} from "../../resources/clienteResource";
import {Banco} from "../../resources/bancoResource";
import {Vendedor} from "../../resources/vendedorResource";
import {Conta} from "../../resources/contaResource";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ClienteCreate from "../Clientes/ClienteCreate.vue";
import BancoCreate from "../Bancos/BancoCreate.vue";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import VendedorBusca from "../Vendedores/VendedorBusca.vue";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { Converte } from "../../utils/converte";
import {PermissaoService} from '../../services/permissaoService';

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const isReadOnly = ref(false);
const route = useRoute();
const router = useRouter();
const dialogCliente = ref(false);
const dialogBanco = ref(false);
const dialogBuscaCliente = ref(false);
const dialogBuscaVendedor = ref(false);
let showBtnGravar = ref(true);
const bancos = ref([]);
const contas = ref([]);
const detalhesConta = ref("");
const situacao = ref("");

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();

const itensLocalBaixa = [
    { id: "CAI", nome: "Caixa" },
    { id: "CON", nome: "Conta Bancária" },
];  

addMethod(number, "codigo_cheque_recebido_existe", function codigoChequeRecebidoExiste(message) {
    return number().test(
        "codigo-cheque-recebido-existe", // Name
        message, // Msg
        function (codigo) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                ChequeRecebido.codigoExiste(codigo, chequeRecebido.value.idChequeRecebido).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior_zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) > 0) {
                return true;
            }
            return false;
        }
    );
});


const validationSchema = object({
    chequeRecebido: object({
        idChequeRecebido: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_cheque_recebido_existe("Esse código já está sendo usado por outro cheque recebido, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        data: string().required("A data é obrigatória").label("A data"),
        bomPara: string().required("Bom para é obrigatório").label("Bom para"),
        dataBaixa: undefined, 
        idCliente: undefined, 
        codigoCliente: undefined,
        nomeCliente: undefined,
        idBanco: number().required("O banco é obrigatório").label("O banco"),
        agencia: string().max(15).label("A agência"),
        cc: string().max(15).label("A conta"),
        numero: string().max(10).label("O número"),
        documento: string().max(10).label("O documento"),
        valor:string().valor_maior_zero("O valor deve ser maior que zero").required("O valor é obrigatório").label("O valor"),
        titular: string().max(50).label("O titular"),
        codigoVendedor: undefined,
        nomeVendedor: undefined,
        idVendedor:undefined,
        comissao: undefined,
        observacoes: string().label("As observações"),
        situacao: undefined,
        modulo: undefined,
        localBaixa: undefined,
        idConta: undefined,
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const chequeRecebido = ref({
    idChequeRecebido: useField("chequeRecebido.idChequeRecebido").value,
    codigo: useField("chequeRecebido.codigo").value,
    data: useField("chequeRecebido.data").value,
    bomPara: useField("chequeRecebido.bomPara").value,
    dataBaixa: useField("chequeRecebido.dataBaixa").value,
    idCliente: useField("chequeRecebido.idCliente").value,
    codigoCliente: useField("chequeRecebido.codigoCliente").value,
    nomeCliente: useField("chequeRecebido.nomeCliente").value,
    idBanco: useField("chequeRecebido.idBanco").value,
    agencia: useField("chequeRecebido.agencia").value,
    cc: useField("chequeRecebido.cc").value,
    numero: useField("chequeRecebido.numero").value,
    documento: useField("chequeRecebido.documento").value,
    valor: useField("chequeRecebido.valor").value,
    titular: useField("chequeRecebido.titular").value,
    codigoVendedor: useField("chequeRecebido.codigoVendedor").value,
    nomeVendedor: useField("chequeRecebido.nomeVendedor").value,
    idVendedor: useField("chequeRecebido.idVendedor").value,
    comissao: useField("chequeRecebido.comissao").value,
    observacoes: useField("chequeRecebido.observacoes").value,
    situacao: useField("chequeRecebido.situacao").value,
    modulo: useField("chequeRecebido.modulo").value,
    localBaixa: useField("chequeRecebido.localBaixa").value,
    idConta: useField("chequeRecebido.idConta").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    situacao.value = "";
    getBancos();
    getContas();
    cleanForm();
    isReadOnly.value = false;

    if (route.name == "ChequesRecebidos.Update" || route.name == "ChequesRecebidos.Show" || route.name == "ChequesRecebidos.Baixa") {
        getChequeRecebido(route.params.id);
    } else {
        proximoCodigo();
        document.getElementById("codigo").focus();
        
    }

    if (route.name == "ChequesRecebidos.Show") {
        isReadOnly.value = true;
    }

    if(route.name == 'ChequesRecebidos.Update') {
        document.getElementById("dataBaixa").setAttribute("tabindex", "-1");
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "ChequesRecebidos.Create") {
        chequeRecebido.value.idChequeRecebido = null;
        proximoCodigo();
    }
    chequeRecebido.value.data = new Date().toLocaleDateString("pt-BR");
    chequeRecebido.value.bomPara = new Date().toLocaleDateString("pt-BR");
    chequeRecebido.value.dataBaixa = "";
    chequeRecebido.value.idCliente = null;
    chequeRecebido.value.codigoCliente = "";
    chequeRecebido.value.nomeCliente = "";
    chequeRecebido.value.agencia = "";
    chequeRecebido.value.cc = "";
    chequeRecebido.value.numero = "";
    chequeRecebido.value.valor = "R$ 0,00";
    chequeRecebido.value.documento = "";
    chequeRecebido.value.titular = "";
    chequeRecebido.value.codigoVendedor = "";
    chequeRecebido.value.nomeVendedor = "";
    chequeRecebido.value.idVendedor = null;
    chequeRecebido.value.comissao = "0,0000 %";
    chequeRecebido.value.observacoes = "";
    chequeRecebido.value.situacao = 'A';
    chequeRecebido.value.modulo = 'CHERE';
    chequeRecebido.value.localBaixa = 'CAI';
    chequeRecebido.value.idConta = null;
}

async function getContas() {
    const response = await Conta.getContas();
    contas.value = response.data.data;

    if (contas.value.length > 0 && (chequeRecebido.value.idConta == null || chequeRecebido.value.idConta<= 0)) {
        chequeRecebido.value.idConta = response.data.data[0].idConta;
    }

    changeConta();
}

async function changeConta() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.11');
    dados.append('idConta', chequeRecebido.value.idConta);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;

            detalhesConta.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);
    
            if(conta == []) {
                detalhesConta.value = "";
            }
        }
    }
    catch (err) {
        console.log(err);
    }
}



async function gravar(values, actions) {
    let dados = new FormData();

    values.chequeRecebido.data = Converte.datePtBrToIso(values.chequeRecebido.data); 
    values.chequeRecebido.bomPara = Converte.datePtBrToIso(values.chequeRecebido.bomPara);

    if(values.chequeRecebido.idCliente=="") {
        values.chequeRecebido.idCliente = null;
    }

    if(values.chequeRecebido.idVendedor=="") {
        values.chequeRecebido.idVendedor = null;
    }

    let dataBaixa = '';

    if(values.chequeRecebido.dataBaixa == '') {
        dataBaixa = null;
    } else{
        dataBaixa = Converte.datePtBrToIso(values.chequeRecebido.dataBaixa); 
    }
    values.chequeRecebido.dataBaixa = dataBaixa;

    dados.append("data", JSON.stringify(values.chequeRecebido));    

    if(route.name == 'ChequesRecebidos.Create') { 
        try {
            const response = await ChequeRecebido.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O cheque recebido não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Novo cheque recebido cadastrado com sucesso.");
                cleanForm();
                document.getElementById("codigo").focus();
            }
        } catch (err) {
           snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ChequesRecebidos.Update') {
        try {
            const response = await ChequeRecebido.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O cheque recebido não pode ser alterado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                actions.resetForm();
                cleanForm();
                snackBarApp.value.open("Cheque recebido alterado com sucesso.");
                
                router.push({ name: "ChequesRecebidos.List" });

            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ChequesRecebidos.Baixa') {
        try {
            const response = await ChequeRecebido.baixar(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O cheque recebido não pode ser baixado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Cheque recebido baixado com sucesso.");
                actions.resetForm();
                cleanForm();
                router.push({'name':'ChequesRecebidos.List'});
            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }
}

async function getBancos() {
    const response = await Banco.getBancos();
    bancos.value = response.data.data;

    if (bancos.value.length > 0 && (chequeRecebido.value.idBanco == null || chequeRecebido.value.idBanco == 0)) {
        chequeRecebido.value.idBanco = response.data.data[0].idBanco;
    }
}

async function getChequeRecebido(id) {
    let dados = new FormData();

    if (route.name == "ChequesRecebidos.Update") {
        dados.append("codigoModulo", "03.11.02");
    } else if (route.name == "ChequesRecebidos.Show") {
        dados.append("codigoModulo", "03.11.03");
    } else if (route.name == "ChequesRecebidos.Baixa") {
        dados.append("codigoModulo", "03.11.04");
    } 

    dados.append("idChequeRecebido", id);

    try {
        let response = await ChequeRecebido.getById(dados)
        if (response.status == 200) {

            response.data.data.bomPara = Converte.dateIsoToPtBr(response.data.data.bomPara);
            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            if(response.data.data.dataBaixa !== null) {
                response.data.data.dataBaixa = Converte.dateIsoToPtBr(response.data.data.dataBaixa);
            }
            response.data.data.valor = Converte.numberToCurrency(response.data.data.valor);
            response.data.data.comissao = Converte.numberToPorcentagem(response.data.data.comissao,4);

            let sit = response.data.data.situacao;
            if(sit == "A") {
                situacao.value = "Situação do cheque: Em Aberto";
            } else if (sit == 'B')  {
                situacao.value = "Situação do cheque: Baixado";
            } else if (sit == 'C') {
                situacao.value = "Situação do cheque: Cancelado";
            } else if (sit == 'V') {
                situacao.value = "Situação do cheque: Voltou";
            }


            if(route.name == 'ChequesRecebidos.Baixa') {
                response.data.data.dataBaixa = new Date().toLocaleDateString("pt-BR");
            }


            setValues({ chequeRecebido: response.data.data });

            if(chequeRecebido.value.localBaixa == "" || chequeRecebido.value.localBaixa == null) {
                chequeRecebido.value.localBaixa = 'CAI';
            }

            if (contas.value.length > 0 && (chequeRecebido.value.idConta == null || chequeRecebido.value.idConta<= 0)) {
                chequeRecebido.value.idConta = contas.value[0].idConta;
            }

            changeConta();

        }
    }
    catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await ChequeRecebido.proximoCodigo();
        if (response.status == 200) {
            chequeRecebido.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

async function getCliente() {
    chequeRecebido.value.idCliente = null;
    chequeRecebido.value.nomeCliente = "";

    if (chequeRecebido.value.codigoCliente == null || chequeRecebido.value.codigoCliente.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", chequeRecebido.value.codigoCliente);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.11");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    snackBarApp.value.open("Um cliente inativo não pode gerar novos lançamentos de cheques recebidos.", 6000);
                    document.getElementById("codigoCliente").focus();
                    chequeRecebido.value.codigoCliente = "";
                    return;
                }
                if (response.data.message == "bloqueado") {
                    snackBarApp.value.open("Um cliente bloqueado não pode gerar novos lançamentos de cheques recebidos.", 6000);
                    document.getElementById("codigoCliente").focus();
                    chequeRecebido.value.codigoCliente = "";
                    return;
                }
                chequeRecebido.value.codigoCliente = "";
                document.getElementById("codigoCliente").focus();

                snackBarApp.value.open("Cliente não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            chequeRecebido.value.idCliente = response.data.data.idCliente;
            chequeRecebido.value.nomeCliente = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    chequeRecebido.value.codigoCliente = cliente.codigo;
    chequeRecebido.value.nomeCliente = cliente.nome;
    chequeRecebido.value.idCliente = cliente.idCliente;

    getCliente();
    dialogBuscaCliente.value = false;

    setTimeout(() => {
        document.getElementById("banco").focus();
    }, 100);
}

function cadastrouCliente(codigoCliente) {
    if (codigoCliente == null) {
        return;
    }

    chequeRecebido.value.codigoCliente = codigoCliente;

    getCliente();
}


async function getVendedor() {
    chequeRecebido.value.idVendedor = null;
    chequeRecebido.value.nomeVendedor = "";

    if (chequeRecebido.value.codigoVendedor == null || chequeRecebido.value.codigoVendedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", chequeRecebido.value.codigoVendedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.11");

    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    chequeRecebido.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor inativo não pode ter novos lançamentos de cheques recebidos.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    chequeRecebido.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor bloqueado não pode ter novos lançamentos de cheques recebidos.", 6000);
                    return;
                }
                chequeRecebido.value.codigoVendedor = "";
                document.getElementById("codigoVendedor").focus();
                snackBarApp.value.open("Vendedor/funcionário não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            chequeRecebido.value.idVendedor = response.data.data.idVendedor;
            chequeRecebido.value.nomeVendedor = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    chequeRecebido.value.codigoVendedor = vendedor.codigo;
    chequeRecebido.value.nomeVendedor = vendedor.nome;
    chequeRecebido.value.idVendedor = vendedor.idVendedor;

    getVendedor();
    dialogBuscaVendedor.value = false;

    setTimeout(() => {
        document.getElementById("observacoes").focus();
    }, 100);
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="route.name == 'ChequesRecebidos.Create'">Novo Cheque Recebido</h2>
            <h2 v-show="route.name == 'ChequesRecebidos.Update'">Alteração de Cheque Recebido</h2>
            <h2 v-show="route.name == 'ChequesRecebidos.Baixa'">Baixa de Cheque Recebido</h2>
            <h2 v-show="route.name == 'ChequesRecebidos.Show'">Consulta de um Cheque Recebido</h2>
        </v-card-title>
        <form ref="formChequeRecebido" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            v-model="chequeRecebido.codigo"
                            :error-messages="errors['chequeRecebido.codigo']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    chequeRecebido.codigo = e.target.value;
                                }
                            "
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data Emissão" id="data" v-model="chequeRecebido.data" 
                            :error-messages="errors['chequeRecebido.data']" :readonly="isReadOnly" 
                        ></Data>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Documento" id="documento" type="text" 
                            v-model="chequeRecebido.documento" 
                            maxlength="10" 
                            :error-messages="errors['chequeRecebido.documento']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor" id="valor" type="text" v-model="chequeRecebido.valor" :error-messages="errors['chequeRecebido.valor']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código do Cliente"
                            id="codigoCliente"
                            type="text"
                            v-model="chequeRecebido.codigoCliente"
                            maxlength="10"
                            :error-messages="errors['chequeRecebido.codigoCliente']"
                            @blur="getCliente()"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field
                            label="Nome/Razão Social do Cliente"
                            id="nomeCliente"
                            type="text"
                            v-model="chequeRecebido.nomeCliente"
                            maxlength="50"
                            :error-messages="errors['chequeRecebido.nomeCliente']"
                            readonly 
                            tabindex="-1">
                            <template v-slot:append v-if="route.name !=='ChequesRecebidos.Show'">
                                <v-tooltip text="Faz a busca de um cliente por apelido/nome fantasia/cpf.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaCliente = true" id="btnBuscaCliente">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaCliente" transition="dialog-top-transition">
                                    <ClienteBusca 
                                        @selecionouCliente = "selecionouCliente" 
                                        @close-dialog="dialogBuscaCliente = false" 
                                        :select-inativo-bloqueado=false />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo cliente.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnNovoCliente">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                    <ClienteCreate @cadastrouCliente="cadastrouCliente" @close-dialog="dialogCliente = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select v-model="chequeRecebido.idBanco" id="banco" :items="bancos" no-data-text="Nenhum banco cadastrado."
                            item-title="nome" 
                            item-value="idBanco" 
                            label="Banco" 
                            :readonly="isReadOnly"
                            :error-messages="errors['chequeRecebido.idBanco']">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo banco.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogBanco = true"
                                            id="btnNovoBanco"
                                            v-show="temPermissao('01.07.01') && route.name !== 'ChequesRecebidos.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBanco" transition="dialog-top-transition">
                                    <BancoCreate @cadastrouBanco="getBancos()" @close-dialog="dialogBanco = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>    
                    <v-col cols="3">
                        <v-text-field label="Agência" id="agencia" type="text" v-model="chequeRecebido.agencia" maxlength="15" :error-messages="errors['chequeRecebido.agencia']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Conta" id="cc" type="text" v-model="chequeRecebido.cc" maxlength="15" :error-messages="errors['chequeRecebido.cc']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field label="Número do Cheque" id="numero" type="text" v-model="chequeRecebido.numero" maxlength="10" :error-messages="errors['chequeRecebido.numero']" :readonly="isReadOnly" />
                    </v-col>

                    <v-col cols="4">
                        <Data label="Bom Para" id="bomPara" v-model="chequeRecebido.bomPara" :error-messages="errors['chequeRecebido.bomPara']" 
                            :readonly="isReadOnly || route.name =='ChequesRecebidos.Update'"    />
                    </v-col>
                    <v-col cols="4">
                        <Data label="Data Baixa" id="dataBaixa" v-model="chequeRecebido.dataBaixa" :error-messages="errors['chequeRecebido.dataBaixa']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Titular" id="titular" type="text" v-model="chequeRecebido.titular" maxlength="50" :error-messages="errors['chequeRecebido.titular']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código do Vendedor"
                            id="codigoVendedor"
                            type="text"
                            v-model="chequeRecebido.codigoVendedor"
                            maxlength="10"
                            :error-messages="errors['chequeRecebido.codigoVendedor']"
                            @blur="getVendedor()"
                            :readonly="isReadOnly || route.name=='ChequesRecebidos.Show' || route.name=='ChequesRecebidos.Edit' ">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="Nome do Vendedor"
                            id="nomeVendedor"
                            type="text"
                            v-model="chequeRecebido.nomeVendedor"
                            maxlength="50"
                            :error-messages="errors['chequeRecebido.nomeVendedor']"
                            readonly 
                            tabindex="-1">
                            <template v-slot:append v-if="route.name=='ChequesRecebidos.Create'">
                                <v-tooltip text="Faz a busca de um vendedor por nome/cpf.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaVendedor = true" id="btnBuscaVendedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaVendedor" transition="dialog-top-transition">
                                    <VendedorBusca 
                                        @selecionouVendedor="selecionouVendedor" 
                                        @close-dialog="dialogBuscaVendedor = false"
                                        :select-inativo-bloqueado=false />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Porcentagem
                            label="Comissão(%)"
                            id="comissao"
                            type="text"
                            tipo="comissao"
                            v-model="chequeRecebido.comissao"
                            :error-messages="errors['chequeRecebido.comissao']"
                            :readonly="isReadOnly || route.name=='ChequesRecebidos.Quita' || route.name=='ChequesRecebidos.Edit' "/>
                    </v-col>    
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="chequeRecebido.observacoes" 
                            :error-messages="errors['chequeRecebido.observacoes']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row v-show="chequeRecebido.dataBaixa !== null && chequeRecebido.dataBaixa !== ''">
                    <v-col cols="3">
                        <v-select v-model="chequeRecebido.localBaixa" 
                            :items="itensLocalBaixa" 
                            item-title="nome" 
                            item-value="id" 
                            label="Local da Baixa" 
                            :readonly="isReadOnly" 
                            >
                        </v-select>
                    </v-col>
                    <v-col cols="3" v-show="chequeRecebido.localBaixa == 'CON'">
                        <v-select 
                            v-model="chequeRecebido.idConta"  
                            :items="contas" 
                            item-title="apelido" 
                            item-value="idConta" 
                            :readonly="isReadOnly" 
                            @update:modelValue="changeConta()" 
                            label="Conta"
                            no-data-text="Nenhuma conta bancária cadastrada">
                        </v-select>
                    </v-col>    
                    <v-col cols="6" v-show="chequeRecebido.localBaixa == 'CON'">
                        <v-text-field label="Detalhes da Conta" v-model="detalhesConta" readonly tabindex=-1></v-text-field>
                    </v-col>    
                </v-row>
                {{ situacao }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" v-show="$route.name !== 'ChequesRecebidos.Show' && showBtnGravar" variant="elevated" 
                    type="submit" id="btnChequesRecebidosGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)">
                    Voltar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>