   let url = 'https://app.osgold.com.br/php/';
  // let url = 'http://localhost:9000/php';
const config = {
    maxPerPage: 50,
    baseUrl: url,
    baseUrlRel: url+"/Serv/temp/",
    baseUrlImagem: url+"/Serv/uploads/",
};

export default config;


