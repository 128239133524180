<script setup>
import { ref, onMounted, inject } from "vue";
import { Caixa } from "../../resources/caixaResource";
import { useField, useForm } from "vee-validate";
import { string, object, addMethod } from "yup";
import { usePermissoes } from "../../store/permissoes";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const snackBarApp = inject("snackBarApp");
const showBtnGravar = ref(true);

const permissoesStore = usePermissoes();
const itemsDC = ref([
    { text: "Débito", value: "D" },
    { text: "Crédito", value: "C" },
]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});


const validationSchema = object({
    caixa: object({
        idCaixa: undefined,
        dc:undefined,
        documento: string().max(10).label("O documento"),
        descricao: string().max(80).label("A descrição"),
        valor: string().required("O valor é obrigatório").valor_maior_zero("O valor deve ser maior que zero"),
        data: string().required("A data é obrigatória"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const caixa = ref({
    idCaixa: useField("caixa.idCaixa").value,
    dc: useField("caixa.dc").value,
    documento: useField("caixa.documento").value,
    descricao: useField("caixa.descricao").value,
    valor: useField("caixa.valor").value,
    data: useField("caixa.data").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    caixa.value.data = new Date().toLocaleDateString("pt-BR");
    caixa.value.dc = "C";
    caixa.value.documento = "";
    caixa.value.descricao = "";
    caixa.value.valor = "R$ 0,00";
}

async function gravar(values, actions) {
    let dados = new FormData();

    values.caixa.data = Converte.datePtBrToIso(values.caixa.data); 

    dados.append("data", JSON.stringify(values.caixa));

    try {
        
        const response = await Caixa.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O lançamento de caixa não pode ser gravado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            snackBarApp.value.open("Novo lançamento de caixa gravado com sucesso.");
            actions.resetForm();
            cleanForm();
            document.getElementById("data").focus();
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Novo Lançamento de Caixa</h2>
        </v-card-title>
        <form ref="formCaixa" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <Data
                            label="Data"
                            id="data"
                            v-model="caixa.data"
                            :error-messages="errors['caixa.data']"
                            maxlength="10"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            :items="itemsDC"
                            item-title="text"
                            item-value="value"
                            label="Crédito/Débito"
                            v-model="caixa.dc"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Documento"
                            id="documento"
                            type="text"
                            v-model="caixa.documento"
                            maxlength="10"
                            :error-messages="errors['caixa.documento']"
                        />
                    </v-col>
                    <v-col cols="3">
                        <moeda label="Valor" id="valor" v-model="caixa.valor" :error-messages="errors['caixa.valor']" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descrição"
                            id="descricao"
                            type="text"
                            v-model="caixa.descricao"
                            maxlength="80"
                            :error-messages="errors['caixa.descricao']"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" v-show="showBtnGravar" type="submit" id="btnCaixaGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)">Voltar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>