<script setup>
import { ref, onMounted, defineEmits, inject } from "vue";
import { useRoute } from "vue-router";
import { Cartao } from "../../resources/cartaoResource";
import { Conta } from "../../resources/contaResource";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import Moeda from "../Utils/moeda.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { Converte } from "../../utils/converte";

const snackBarApp = inject("snackBarApp");
const route = useRoute();
let showBtnGravar = ref(true);
const emit = defineEmits(["close-dialog"]);
const isReadOnly = ref(false);

const permissoesStore = usePermissoes();

const localCredito = ref([
    { text: "Caixa", value: "CAI" },
    { text: "Conta Bancária", value: "CON" },
]);

const dividirTarifa = ref([
    { text: "Sim", value: "S" },
    { text: "Não", value: "N" },
]);

const quitar = ref([
    { text: "Sim", value: "S" },
    { text: "Não", value: "N" },
]);

const ativoInativo = ref([
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
]);

addMethod(string, "max_value_tarifa", function maxValue(message) {
    return string().test("max-value-tarifa", message, function (value) {
        if (Converte.stringToFloat(value) > 100) {
            return false;
        }
        return true;
    });
});

const validationSchema = object({
    cartao: object({
        idCartao: undefined,
        nome: string().required("O nome do cartão/convênio é obrigatório").max(50).label("O nome do cartão/convênio"),
        tarifaR: string(),
        tarifaP: string().max_value_tarifa("A tarifa deve ser menor que 100%"),
        nroMaxParcelas: number().min(0).max(999).label("Número máximo de parcelas"),
        dividirTarifa: undefined,
        quitar: undefined,
        ativoInativo: undefined,
        localCredito: undefined,
        observacoes: undefined,
        idConta: undefined,
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const contas = ref([]);

const cartao = ref({
    idCartao: useField("cartao.idCartao").value,
    nome: useField("cartao.nome").value,
    tarifaR: useField("cartao.tarifaR").value,
    tarifaP: useField("cartao.tarifaP").value,
    nroMaxParcelas: useField("cartao.nroMaxParcelas").value,
    dividirTarifa: useField("cartao.dividirTarifa").value,
    quitar: useField("cartao.quitar").value,
    ativoInativo: useField("cartao.ativoInativo").value,
    localCredito: useField("cartao.localCredito").value,
    idConta: useField("cartao.idConta").value,
    observacoes: useField("cartao.observacoes").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    
    if (route.name == "Cartao.Update" || route.name == "Cartao.Show") {
        getCartao(route.params.id);
    }

    getContas();

    if(route.name == "Cartao.Show") {
        isReadOnly.value = true;
    }

  
    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "Cartao.Create") {
        cartao.value.idCartao = null;
    }
    cartao.value.nome = "";
    cartao.value.tarifaR = "R$ 0,00";
    cartao.value.tarifaP = "0,0000 %";
    cartao.value.nroMaxParcelas = "1";
    cartao.value.dividirTarifa = "S";
    cartao.value.quitar = "N";
    cartao.value.ativoInativo = "A";
    cartao.value.localCredito = "CAI";
    cartao.value.idConta = null;
    cartao.value.observacoes = "";
}

async function gravar(values, actions) {
    let dados = new FormData();

    if (values.cartao.localCredito == "CAI") {
        values.cartao.idConta = null;
    } else {
        if (values.cartao.idConta == null) {
            snackBarApp.value.open("A conta deve ser selecionada, selecione a conta bancária e tente novamente.", 6000);
            return;
        }
    }

    dados.append("data", JSON.stringify(values.cartao));

    try {
        const response = await Cartao.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O cartão/convênio não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "Cartao.Update") {
                snackBarApp.value.open("Cartão/convênio alterado com sucesso.");
            } else {
                snackBarApp.value.open("Novo cartão/convênio cadastrado com sucesso.");
                actions.resetForm();
                cleanForm();
                document.getElementById("nome").focus();
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

function closeModalCadastroCartao() {
    emit("close-dialog");
}

async function getContas() {
    const response = await Conta.getContas();
    contas.value = response.data.data;

    if (contas.value.length > 0 && cartao.value.idConta == null) {
        cartao.value.idConta = response.data.data[0].idConta;
    }
}

async function getCartao(id) {
    let dados = new FormData();
    if (route.name == "Cartao.Update") {
        dados.append("codigoModulo", "01.09.02");
    } else {
        dados.append("codigoModulo", "01.09.04");
    }

    dados.append("idCartao", id);
    try {
        const response = await Cartao.getById(dados);
        if (response.status == 200) {
            setValues({ cartao: response.data.data });

            cartao.value.tarifaP = Converte.numberToPorcentagem(response.data.data.tarifaP, 4);
            cartao.value.tarifaR = Converte.numberToCurrency(response.data.data.tarifaR);

            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}
</script>

<template>
    <v-container>
        <v-card class="align-center" elevation="16">
            <v-card-title>
                <h2 v-show="$route.name !== 'Cartao.Update' && $route.name !== 'Cartao.Show'">Novo Cartão/Convênio</h2>
                <h2 v-show="$route.name == 'Cartao.Update'">Alteração de Cartão/Convênio</h2>
                <h2 v-show="$route.name == 'Cartao.Show'">Consulta de Cartão/Convênio</h2>
            </v-card-title>
            <form ref="formCartao" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Nome do Cartão/Convênio"
                                id="nome"
                                type="text"
                                v-model="cartao.nome"
                                :error-messages="errors['cartao.nome']"
                                maxlength="50"
                                :readonly="isReadOnly"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <moeda label="Tarifa em R$" id="tarifaR" type="text" v-model="cartao.tarifaR" :error-messages="errors['cartao.tarifaR']" :readOnly="isReadOnly" />
                        </v-col>
                        <v-col cols="4">
                            <porcentagem
                                label="Tarifa em %"
                                id="tarifaP"
                                type="text"
                                tipo="tarifaP"
                                v-model="cartao.tarifaP"
                                :error-messages="errors['cartao.tarifaP']"
                                :readonly="isReadOnly"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                label="Número Máximo de Parcelas"
                                id="nroMaxParcelas"
                                type="text"
                                v-model="cartao.nroMaxParcelas"
                                :error-messages="errors['cartao.nroMaxParcelas']"
                                :readonly="isReadOnly"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <v-select
                                :items="dividirTarifa"
                                item-title="text"
                                item-value="value"
                                label="Dividir a Tarifa Entre as Parcelas"
                                v-model="cartao.dividirTarifa"
                                :readonly="isReadOnly"
                            ></v-select>
                        </v-col>

                        <v-col cols="4">
                            <v-select
                                :items="quitar"
                                item-title="text"
                                item-value="value"
                                label="Quitar Assim que Lançado"
                                v-model="cartao.quitar"
                                :readonly="isReadOnly"
                            ></v-select>
                        </v-col>

                        <v-col cols="4">
                            <v-select
                                :items="ativoInativo"
                                item-title="text"
                                item-value="value"
                                label="Ativo/Inativo"
                                v-model="cartao.ativoInativo"
                                :readonly="isReadOnly"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-select
                                :items="localCredito"
                                item-title="text"
                                item-value="value"
                                label="Local do Crédito"
                                v-model="cartao.localCredito"
                                :readonly="isReadOnly"
                            ></v-select>
                        </v-col>

                        <v-col cols="9" v-show="cartao.localCredito == 'CON'">
                            <v-select :items="contas" item-title="apelido" 
                                item-value="idConta" 
                                label="Conta" 
                                v-model="cartao.idConta" 
                                :readonly="isReadOnly"
                                no-data-text="Nenhuma conta bancária cadastrada.">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-textarea label="Observações" id="observacoes" type="text" v-model="cartao.observacoes" :readonly="isReadOnly" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" type="submit" id="btnCartaoGravar" v-show="$route.name !== 'Cartao.Show'">Salvar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        variant="elevated"
                        @click.prevent="$router.go(-1)"
                        v-if="showBtnGravar"
                        v-show="$route.name == 'Cartao.Create' || $route.name == 'Cartao.Update' || $route.name == 'Cartao.Show'"
                        >Voltar</v-btn
                    >
                    <v-btn
                        color="primary"
                        variant="elevated"
                        v-if="showBtnGravar"
                        v-show="$route.name !== 'Cartao.Create' && $route.name !== 'Cartao.Update' && $route.name !== 'Cartao.Show'"
                        @click.prevent="closeModalCadastroCartao()"
                        >Fechar</v-btn
                    >
                </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>
