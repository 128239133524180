<script setup>
    import { ref, inject, defineEmits, onMounted } from 'vue';
    import { useRelatorio } from "../../store/relatorio";
    import { Relatorio } from "../../resources/relatoriosResource";
    import { Cartao } from "../../resources/cartaoResource";
    import { Converte } from "../../utils/converte";
    import config from "../../services/config";
    import Data from "../Utils/data.vue";
    
    const emit = defineEmits(['close-dialog']);
    const snackBarApp = inject('snackBarApp');
    
    const dataInicial = ref(new Date().toLocaleDateString("pt-BR"));
    const dataFinal = ref(new Date().toLocaleDateString("pt-BR"));
    const idCartao = ref(null);
    const cartoes = ref([]);
    const relatorioStore = useRelatorio();

    onMounted(() => {
        getCartoes();
    });

    async function getCartoes() {
        try {
            let response = await Cartao.getCartoes();
            cartoes.value  = response.data.data;
            if(idCartao.value  == null && cartoes.value > 0) {
                idCartao.value = response.data.data[0].idCartao;
            }
        }
        catch (err) {
            snackBarApp.value.open('Erro ao buscar os cartões.');
        }
    }

    async function submit() {
        let nomeRel = relatorioStore.relatorio;

        if(dataInicial.value == '' || dataFinal.value == '') {
            snackBarApp.value.open('Informe as datas inicial e final para a impressão do relatório.');
            return;
        }

        if(idCartao.value == null ) {
            snackBarApp.value.open('O cartão/convênio precisa ser escolhido, escolha um cartão/convênio e tente novamente.');
            return;
        }   

        let auxDataInicial = Converte.datePtBrToIso(dataInicial.value);
        let auxDataFinal = Converte.datePtBrToIso(dataFinal.value);
        
        try {
            let response = await Relatorio.get(nomeRel, auxDataInicial, auxDataFinal, idCartao.value);
            if (response.status == 200) {
                if(response.data.success == true) {
                    let nomeArquivo = response.data.nomeArquivo;
                    let url = config.baseUrlRel+nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }

                if(response.data.success == false) {
                    snackBarApp.value.open(response.data.message);
                    return;
                }

                snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
            }
        }
        catch (error) {
            snackBarApp.value.open('Erro ao tentar imprimir o relatório.');
        }
    }   
    


</script>


<template>
    <v-container>
        <v-card elevation="16">
            <v-card-title>
                <h4>Digite as Datas Inicial, Final e escolha um cartão/convênio e clique em Confirmar</h4>
            </v-card-title>
            
            <form ref="formTelaDatasCartao" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <Data v-model="dataInicial" label="Data Inicial" id="dataInicial" />
                        </v-col>
                        <v-col cols="6">
                            <Data v-model="dataFinal" label="Data Final" id="dataFinal" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select label="Cartão/Convênio" 
                                v-model="idCartao" 
                                :items="cartoes" 
                                item-title="nome" 
                                item-value="idCartao"
                                no-data-text="Nenhum cartão/convênio cadastrado.">
                            </v-select>
                        </v-col>
                    </v-row>                        
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" type="submit" id="btnTelaDatasContaConfirmar" >Confirmar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>


<!-- <template>
<div>
    <div class="container-fluid">
        <div class="panel panel-default">
            <div class="panel-body">

                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label" for="idCartao">Cartão/Convênio</label>
                                <select class="form-control"
                                        id="idCartao"
                                        name="idCartao"
                                        v-model='idCartao'>
                                    <option v-for="(o) in cartoes" :value="o.idCartao" :key="o">{{ o.nome }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="dataInicial">Data Inicial</label>
                                <div class="input-group">
                                    <date-picker
                                            class="form-control"
                                            v-model="dataInicial"
                                            id="dataInicial"
                                            name="dataInicial"
                                            :config="configDatePicker">
                                    </date-picker>
                                    <div class="input-group-addon">
                                        <span class="glyphicon glyphicon-calendar"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="dataFinal">Data Final</label>
                                <div class="input-group">
                                    <date-picker
                                            class="form-control"
                                            v-model="dataFinal"
                                            id="dataFinal"
                                            name="dataFinal"
                                            :config="configDatePicker">
                                    </date-picker>
                                    <div class="input-group-addon">
                                        <span class="glyphicon glyphicon-calendar"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p></p>

                    <div class="row">
                        <div class="col-md-2">
                            <a class="btn btn-primary" href="#" @click.prevent="submit()">Confirmar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import {Cartao} from '../../resources/cartaoResource';
    import {Relatorio} from '../../resources/relatoriosResource';
    import store from '../../store/store';
    import config from '../../services/config';
    import Datepicker from 'vue-bootstrap-datetimepicker';

    export default {
        components: {
            'date-picker':Datepicker,
        },
        data() {
            return {
                dataInicial: this.$moment() ,
                dataFinal: this.$moment() ,
                idCartao: null,
                cartoes:{},
                configDatePicker: store.state.datePicker,
            }
        },
        mounted() {
            this.getCartoes();

        },

        methods: {
            submit() {
                let self = this;

                let nomeRel = store.state.relatorio.relatorio;

                let dataInicial = this.$moment(this.dataInicial, 'YYYY-MM-DD').format('YYYY-MM-DD');
                let dataFinal = this.$moment(this.dataFinal, 'YYYY-MM-DD').format('YYYY-MM-DD');

                if(this.idCartao == null ) {
                    self.$notify({
                        title: 'Cartão/Convênio',
                        message: 'O cartão/convênio precisa ser escolhido, selecione um cartão/convênio e tente novamente.',
                        type: 'error'
                    });
                    return;
                }

                Relatorio.get(nomeRel, dataInicial, dataFinal, this.idCartao )
                    .then(function (response) {
                        if (response.status == 200) {
                            if(response.data.success == true) {
                                let nomeArquivo = response.data.nomeArquivo;
                                let url = config.baseUrlRel+nomeArquivo;

                                window.open(url, '_blank');
                                return;

                            }

                            if(response.data.success == false) {
                                self.$notify({
                                    title: 'Impressão',
                                    message: response.data.message,
                                    type: 'info'
                                });
                                return;
                            }
                            self.$notify({
                                title: 'Impressão',
                                message: 'Não foi possível fazer a impressão do relatório, tente novamente.',
                                type: 'error'
                            });
                        }
                    })
                    .catch(function () {
                    });

            },
            getCartoes() {
                let self = this;
                Cartao.getCartoes()
                    .then(function (response) {
                        self.cartoes = response.data.data;

                        if(self.idCartao == null || self.idCartao == 0) {
                            self.idCartao = response.data.data[0].idCartao;
                        }
                    })
                    .catch(function () {

                    });
            },
        }
    }
</script> -->
