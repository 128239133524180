<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { LancamentoCartao } from "@/resources/lancamentoCartaoResource";
import { Cartao } from "../../resources/cartaoResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useLancamentoCartao } from "../../store/lancamentoCartao";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { dateFormat, valorFormat} from "../../services/filtros";

let msgConfirm = ref(null);
let snackBarApp = inject('snackBarApp');

const lancamentoCartaoStore = useLancamentoCartao();
let auxLancamento = ref({});
let lancamentos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const abertos = ref(lancamentoCartaoStore.abertos);
const filtrarPor = ref(lancamentoCartaoStore.filtrarPor);
const cancelados = ref(lancamentoCartaoStore.cancelados);
const recebidos = ref(lancamentoCartaoStore.recebidos);
const currentPage = ref(lancamentoCartaoStore.currentPage);
const dataInicial = ref(lancamentoCartaoStore.dataInicial);
const dataFinal = ref(lancamentoCartaoStore.dataFinal);
const idCartao = ref(lancamentoCartaoStore.idCartao);
const cartoes = ref([]);
const dataRecebimento = ref(new Date().toLocaleDateString("pt-BR"));

const dialogRecebimentoLancamentoCartao = ref(false);
const dialogRecebimentoTodosLancamentosCartoes = ref(false);
const itemsFiltrarPor = ref([
    { value: 'data', label: 'Data de Emissão' },
    { value: 'dataRecebimento', label: 'Data do Recebimento' },
]);

const direction = computed(() => {
    return lancamentoCartaoStore.sort[0].direction;
});

const orderBy = computed(() => {
    return lancamentoCartaoStore.sort[0].property;
});

onMounted(() => {
    getCartoes();
});

async function getCartoes() {
    try {
        let response = await Cartao.getCartoes();
        cartoes.value  = response.data.data;
        if(idCartao.value  == null && cartoes.value > 0) {
            idCartao.value = response.data.data[0].idCartao;
        }
        else {
            idCartao.value = lancamentoCartaoStore.idCartao;
        }
        filtrar();
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os cartões.');
        console.log(err);
    }
}


async function excluir(idLancamentoCartao) {
    let dados = new FormData();
    dados.append("idLancamentoCartao", JSON.stringify(idLancamentoCartao));
    dados.append('operacao','Excluir');

    try {
        const response = await LancamentoCartao.excluirCancelar(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBarApp.value.open("Lançamento de cartão/convênio excluído com sucesso.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível Excluir o lançamento de cartão/convênio.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}


function openModalDelete(lanc) {
//    lancamento.value = lanc;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão do lançamento de cartão/convênio ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                excluir(lanc.idLancamentoCartao);
            }
        });
}

function confirmaReceber() {
    if(dataRecebimento.value == "" || dataRecebimento.value == null) {
        snackBarApp.value.open("Informe a data do recebimento.");
        return;
    }

    msgConfirm.value
        .open(
            "Confirma o Recebimento ?",
            "Confirma o recebimento desse lançamento de cartão/convênio ?",
            { 
                color: "primary",
                zIndex: 9999
                
            }
        )
        .then((confirm) => {
            if (confirm) {
                receber(auxLancamento)
            }
        });
}

function confirmaReceberTodos() {
    if(dataRecebimento.value == "" || dataRecebimento.value == null) {
        snackBarApp.value.open("Informe a data do recebimento.");
        return;
    }

    msgConfirm.value
        .open(
            "Confirma o Recebimento ?",
            "Confirma o recebimento de todos os lançamentos de cartões/convênio filtrados ?",
            { 
                color: "primary",
                zIndex: 9999
            }
        )
        .then((confirm) => {
            if (confirm) {
                receberTodos();
            }
        });
    
}

async function receber(lancamento) {
    let auxDataRecebimento;
    
    if(dataRecebimento.value == '' || dataRecebimento.value == null) {
        auxDataRecebimento = null;
    } else{
        auxDataRecebimento = Converte.datePtBrToIso(dataRecebimento.value);
    }

    if(auxDataRecebimento == null )
    {
        snackBarApp.value.open("Não foi possível receber o lançamento, a data do recebimento precisa ser preenchida.");
        return;
    }

    let dados = new FormData();
    lancamento.value.dataRecebimento = auxDataRecebimento;

    dados.append('data', JSON.stringify(lancamento.value));

    try {
        const response = await LancamentoCartao.receber(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Lançamento de cartão/convênio recebido com sucesso.");  
                dialogRecebimentoLancamentoCartao.value = false;
                filtrar();
            }
            if (response.data.success == false) {
                snackBarApp.value.open("Não foi possível receber o lançamento de cartão/convênio.");
            }
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function receberTodos() {
    let dados = new FormData();

    let auxDataRecebimento =  null;    

    if(dataRecebimento.value == '' || dataRecebimento.value == null) {
        auxDataRecebimento = null;
    } else{
        auxDataRecebimento = Converte.datePtBrToIso(dataRecebimento.value);
    }

    if(auxDataRecebimento == null )
    {
        snackBarApp.value.open("Não foi possível receber os lançamentos, a data do recebimento precisa ser preenchida.");
        return;
    }

    dados.append('dados', JSON.stringify(lancamentos.value));
    dados.append('dataRecebimento', auxDataRecebimento);

    try {
        const response = await LancamentoCartao.receberTodos(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Não foi possível receber os lançamentos de cartão/convênio, mensagem:" + response.data.message);
                return;
            }

            snackBarApp.value.open("Lançamentos de cartão/convênio recebidos com sucesso.");
            dialogRecebimentoTodosLancamentosCartoes.value = false;
            filtrar();
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function filtrar(tipoFiltro, mudouPagina = false) {
    let start = lancamentoCartaoStore.start;
    let limit = lancamentoCartaoStore.limit;
    let sort = JSON.stringify(lancamentoCartaoStore.sort);

    if(tipoFiltro == null || tipoFiltro == "" || tipoFiltro == undefined) {
        tipoFiltro = lancamentoCartaoStore.tipoFiltro;
    }
    lancamentoCartaoStore.setIdCartao(idCartao.value);
    lancamentoCartaoStore.setDataInicial(dataInicial.value);
    lancamentoCartaoStore.setDataFinal(dataFinal.value);
    lancamentoCartaoStore.setAbertos(abertos.value);
    lancamentoCartaoStore.setCancelados(cancelados.value);
    lancamentoCartaoStore.setRecebidos(recebidos.value);
    lancamentoCartaoStore.setFiltrarPor(filtrarPor.value);
                
    let auxDataInicial = null;
    let auxDataFinal = null;
    if (dataInicial.value != "") {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if (dataFinal.value != "") {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await LancamentoCartao.listar(start, limit, sort, filtrarPor.value, 
            auxDataInicial, auxDataFinal, abertos.value, cancelados.value, recebidos.value, idCartao.value);
        lancamentos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}

function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    lancamentoCartaoStore.setCurrentPage(val);
    lancamentoCartaoStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = lancamentoCartaoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [ 
        {
            property: property,
            direction: direction,
        },
    ];

    lancamentoCartaoStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function openModalCancelar(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
                "Confirma o cancelamento desse lançamento de cartão/convênio ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(lancamento.idLancamentoCartao);
            }
        });
}

async function cancelar(idLancamentoCartao) {
    let dados = new FormData();
    dados.append("idLancamentoCartao", JSON.stringify(idLancamentoCartao));
    dados.append('operacao','Cancelar');

    try {
        const response = await LancamentoCartao.excluirCancelar(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBarApp.value.open("Lançamento de cartão/convênio cancelado com sucesso.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar o lançamento de cartão/convênio.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function estiloLancamento(situacao) {
    if(situacao == 'A') {
        return 'text-green';
    }
    if(situacao == 'C') {
        return 'text-red';
    }
}

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>


    <v-row>
        <v-col>
            <h1>Lançamentos de Cartões/Convênios</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.12.02')" color="primary" @click.prevent="dialogRecebimentoTodosLancamentosCartoes = true">Receber Todos os Lançamentos de Cartão/Convênio Filtrados</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select density="compact" label="Filtrar Por" v-model="filtrarPor" :items="itemsFiltrarPor"
                                item-title="label" item-value="value"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="8">
            <v-card elevation="16" density="compact">
                <v-card-title color="primary">Mostrar</v-card-title>
                <v-card-text density="compact">
                    <v-row>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="abertos" label="Abertos" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="cancelados" label="Cancelados" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="recebidos" label="Recebidos" @change="filtrar()"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Filtro</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                        </v-col>
                        <v-col cols="2">
                            <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                        </v-col>
                        <v-col cols="8">
                            <v-select label="Cartão/Convênio" 
                                v-model="idCartao" 
                                :items="cartoes" 
                                item-title="nome" 
                                item-value="idCartao"
                                no-data-text="Nenhum cartão/convênio cadastrado">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data
                            <v-icon v-if="orderBy == 'cdata'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataRecebimento')">
                            Data do Recebimento
                            <v-icon v-if="orderBy == 'dataRecebimento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('documento')">
                            Documento
                            <v-icon v-if="orderBy == 'documento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Valor</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeCartao')">
                            Cartão/Convênio
                            <v-icon v-if="orderBy == 'nomeCartao'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Valor Tarifas(R$)</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentos" :key="o">
                    <td :class="estiloLancamento(o.situacao)">
                        <v-tooltip text="Faz o recebimento do lançamento">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cash-check"
                                    v-show="temPermissao('03.12.01') && o.situacao == 'A'"
                                    @click.prevent="auxLancamento = o; dialogRecebimentoLancamentoCartao = true;"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela o lançamento de cartão/convênio.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('03.12.03') && o.situacao !== 'C'"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o lançamento de cartão/convênio.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-trash-can"
                                    v-show="temPermissao('03.12.04')"
                                    @click.prevent="openModalDelete(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td :class="estiloLancamento(o.situacao)">{{ dateFormat(o.data) }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.situacaoExtenso }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ dateFormat(o.dataRecebimento) }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.documento }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ valorFormat(o.valor) }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.nomeCartao }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ valorFormat(o.valorTarifas) }}</td>

                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.12.02')" color="primary" @click.prevent="dialogRecebimentoTodosLancamentosCartoes = true">Receber Todos os Lançamentos de Cartão/Convênio Filtrados</v-btn>
        </v-col>
    </v-row>

    <v-dialog v-model="dialogRecebimentoLancamentoCartao" max-width="600" transition="dialog-top-transition" persistent>
        <v-card>
            <v-card-title>Recebimento de Lançamento de Cartão/Convênio</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <Data label="Data do Recebimento" type="text" v-model="dataRecebimento"></Data>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" @click.prevent="confirmaReceber()">Receber</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="dialogRecebimentoLancamentoCartao = false">Fechar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRecebimentoTodosLancamentosCartoes" max-width="600" transition="dialog-top-transition" persistent>
        <v-card>
            <v-card-title>Recebimento de Lançamentos de Cartão/Convênio</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <Data label="Data do Recebimento" type="text" v-model="dataRecebimento"></Data>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" @click.prevent="confirmaReceberTodos()">Receber</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="dialogRecebimentoTodosLancamentosCartoes = false">Fechar</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
</template>


<!-- <template>
    <div class="container-fluid">
        <h2>Lançamentos de Cartões/Convênios</h2>

        <div class="modal fade" id="modalReceberLancamentoCartao" tabindex="-1" role="dialog" aria-labelledby="modalReceberLancamentoCartao">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title">Recebimento de Lançamento de Cartão/Convênio</h4>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="dataRecebimento">Data do Recebimento</label>
                                  <div class="input-group">
                                      <date-picker
                                              class="form-control"
                                              v-model="dataRecebimento"
                                              id="dataRecebimento"
                                              name="dataRecebimento"
                                              :config="configDatePicker">
                                      </date-picker>
                                      <div class="input-group-addon">
                                          <span class="glyphicon glyphicon-calendar"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Fechar</button>
                        <button class="btn btn-success" @click="receber()">Receber</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalReceberTodosLancamentosCartoes" tabindex="-1" role="dialog" aria-labelledby="modalReceberTodosLancamentosCartoes">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title">Recebimento de Todos os Lançamento</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="dataRecebimento">Data do Recebimento</label>
                                    <div class="input-group">
                                        <date-picker
                                                class="form-control"
                                                v-model="dataRecebimento"
                                                id="dataRecebimento"
                                                name="dataRecebimento"
                                                :config="configDatePicker">
                                        </date-picker>
                                        <div class="input-group-addon">
                                            <span class="glyphicon glyphicon-calendar"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Fechar</button>
                        <button class="btn btn-success" @click="receberTodos()">Receber Todos</button>
                    </div>
                </div>
            </div>
        </div>

        <a href="#" v-show="temPermissao('03.12.02')" class="btn btn-primary" @click="openModalReceberTodos()">Receber Todos Lançamentos Listados</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p></p>

        <div class="row">
            <div class="col-md-2">
                <div class="panel panel-default">
                    <div class="panel-heading">Mostrar</div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" checked="abertos"
                                               v-model="abertos"> Abertos
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                               v-model="recebidos"> Recebidos
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                               v-model="cancelados"> Cancelados
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <p></p>
                        </div>
                        <div class="row">
                            <p></p>
                        </div>
                        <div class="row">
                            <p></p>
                        </div>
                        <div class="row">
                            <p></p>
                        </div>
                        <div class="row">
                            <p></p>
                        </div>
                        <div class="row">
                            <p></p>
                        </div>
                        <div class="row">
                            <p></p>
                        </div>
                        <div class="row">
                            <p></p>
                        </div>


                    </div>
                </div>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">Filtro</div>
                            <div class="panel-body">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="dataInicial">Data Inicial</label>
                                        <div class="input-group">
                                            <date-picker
                                                    class="form-control"
                                                    v-model="dataInicial"
                                                    id="dataInicial"
                                                    name="dataInicial"
                                                    :config="configDatePicker">
                                            </date-picker>
                                            <div class="input-group-addon">
                                                <span class="glyphicon glyphicon-calendar"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="dataFinal">Data Final</label>
                                        <div class="input-group">
                                            <date-picker
                                                    class="form-control"
                                                    v-model="dataFinal"
                                                    id="dataFinal"
                                                    name="dataFinal"
                                                    :config="configDatePicker">
                                            </date-picker>
                                            <div class="input-group-addon">
                                                <span class="glyphicon glyphicon-calendar"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="control-label" for="idCartao">Cartão/Convênio</label>
                                        <select class="form-control"
                                                id="idCartao"
                                                name="idCartao"
                                                v-model='idCartao'>
                                            <option v-for="(o) in cartoes" :value="o.idCartao" :key="o">{{ o.nome }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label for="filtrar">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <button class="btn btn-primary form-control"
                                            id="filtrar"
                                            name="filtrar"
                                            @click.prevent="filtrar()">
                                        <span class="glyphicon glyphicon-filter" aria-hidden="true"></span>Filtrar
                                    </button>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>

                <div class="row">
                     <div class="col-md-12">
                         <div class="panel panel-default">
                             <div class="panel-heading">Filtrar Por</div>
                             <div class="panel-body  ">
                                 <div class="row">
                                     <div class="col-md-4">
                                         <select class="form-control" id="filtrarPor"  v-model="filtrarPor">
                                             <option value="data">Data de Emissão</option>
                                             <option value="dataPagamento">Data do Recebimento</option>
                                         </select>
                                     </div>
                                 </div>

                             </div>
                         </div>

                     </div>
                </div>

            </div>
        </div>

        <p></p>
        <div style="overflow-x:auto;">
            <table class="table table-hover table-striped table-bordered table-responsive">
                <thead>
                <tr>
                    <th>Ações</th>
                    <th>
                        <a href="#" @click.prevent="setSort('data')">
                            Data.
                            <span v-if="orderBy == 'data'" :class="[direction == 'ASC' ?
                                'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                  aria-hidden="true">
                            </span>
                        </a>
                    </th>
                    <th>Situação</th>
                    <th>
                        <a href="#" @click.prevent="setSort('dataRecebimento')">
                            Data do Recebimento
                            <span v-if="orderBy == 'dataRecebimento'" :class="[direction == 'ASC' ?
                                'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                  aria-hidden="true">
                            </span>
                        </a>
                    </th>
                    <th>
                        <a href="#" @click.prevent="setSort('documento')">
                            Documento
                            <span v-if="orderBy == 'documento'" :class="[direction == 'ASC' ?
                                'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                  aria-hidden="true">
                            </span>
                        </a>
                    </th>
                    <th class="text-right">Valor</th>
                    <th>
                        <a href="#" @click.prevent="setSort('nomeCartao')">
                            Cartão/Convênio
                            <span v-if="orderBy == 'nomeCartao'" :class="[direction == 'ASC' ?
                                'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                  aria-hidden="true">
                            </span>
                        </a>
                    </th>
                    <th class="text-right">Valor Tarifas(R$)</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(o) in lancamentosCartoes" :key="o">
                    <td>
                        <a v-show="temPermissao('03.12.01') && o.situacao == 'A'" href="#" @click.prevent = "openModalReceber(o)"
                           data-toggle="tooltip"
                           title="Faz o recebimento do lançamento de cartão"
                           data-placement="bottom">
                            <span class="glyphicon glyphicon-usd" aria-hidden="true"></span>
                        </a>
                        <span v-show="temPermissao('03.12.01') && o.situacao == 'A'"> |</span>
                        <a v-show="temPermissao('03.12.04')" href="#" @click.prevent = "excluir(o.idLancamentoCartao)"
                           data-toggle="tooltip"
                           title="Faz a exclusão do lançamento"
                           data-placement="bottom">
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        </a>
                    <span v-show="o.situacao !== 'C' && temPermissao('03.12.03')"> |</span>
                        <a v-show="o.situacao !== 'C' && temPermissao('03.12.03')" href="#" @click.prevent = "cancelar(o.idLancamentoCartao)"
                           data-toggle="tooltip"
                           title="Faz o cancelamento do lançamento"
                           data-placement="bottom">
                            <span class="glyphicon glyphicon-remove-circle" aria-hidden="true"></span>
                        </a>
                    </td>
                    <td>{{ dateFormat(o.data) }}</td>
                    <td>{{ o.situacaoExtenso }}</td>
                    <td>{{ dateFormat(o.dataRecebimento)}}</td>
                    <td>{{ o.documento}}</td>
                    <td class="text-right">{{ valorFormat(o.valor) }}</td>
                    <td>{{ o.nomeCartao }}</td>
                    <td class="text-right">{{ valorFormat(o.valorTarifas) }}</td>
                </tr>

                </tbody>
            </table>
        </div>

        <div class="block text-right">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :v-model:current-page="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :v-model:total="total">
            </el-pagination>
        </div>

        <p></p>
        <a href="#" v-show="temPermissao('03.12.02')" class="btn btn-primary" @click="openModalReceberTodos()">Receber Todos os Lançamentos de Cartão</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
</template>

<script>
    import {LancamentoCartao} from '../../resources/lancamentoCartaoResource';
    import {Cartao} from '../../resources/cartaoResource';
    import store from '../../store/store';
    import {Pagination} from 'element-plus';
    import config from '../../services/config';
    import Datepicker from 'vue-bootstrap-datetimepicker';
    import {PermissaoService} from '../../services/permissaoService';

    export default {
        components: {
            'el-pagination': Pagination,
            'date-picker':Datepicker,
        },
        data() {
            return {
                lancamentoCartao:{},
                lancamentosCartoes:{},
                cartoes:{},
                dataRecebimento: this.$moment(),
                idCartao: store.state.lancamentoCartao.idCartao,
                dataInicial: store.state.lancamentoCartao.dataInicial,
                dataFinal: store.state.lancamentoCartao.dataFinal,
                abertos: store.state.lancamentoCartao.abertos,
                cancelados: store.state.lancamentoCartao.cancelados,
                recebidos: store.state.lancamentoCartao.recebidos,
                total:0,
                pageSize: config.maxPerPage,
                currentPage:store.state.lancamentoCartao.currentPage,
                configDatePicker: store.state.datePicker,
                filtrarPor:store.state.lancamentoCartao.filtrarPor,
            }
        },
        mounted() {
            let self = this;

            self.getCartoes();

           this.$('[data-toggle="tooltip"]').tooltip();

            this.filtrar();

            var zIndex = 1040 + (10 *this.$('.modal:visible').length);
           this.$(this).css('z-index', zIndex);
            setTimeout(function() {
               this.$('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
            }, 0);


        },
        computed:{
            direction() {
                return store.state.lancamentoCartao.sort[0].direction;
            },
            orderBy() {
                return store.state.lancamentoCartao.sort[0].property;
            }
        },
        methods:{
            filtrar(mudouPagina = false) {
                let self = this;
                let start = store.state.lancamentoCartao.start;
                let limit = store.state.lancamentoCartao.limit;
                let sort = JSON.stringify(store.state.lancamentoCartao.sort);

                store.commit('lancamentoCartao/setIdCartao', this.idCartao);
                store.commit('lancamentoCartao/setDataInicial', this.dataInicial);
                store.commit('lancamentoCartao/setDataFinal', this.dataFinal);
                store.commit('lancamentoCartao/setAbertos', this.abertos);
                store.commit('lancamentoCartao/setCancelados', this.cancelados);
                store.commit('lancamentoCartao/setRecebidos', this.recebidos);
                store.commit('lancamentoCartao/setFiltrarPor', this.filtrarPor);
                let dataInicial = this.$moment(self.dataInicial, 'YYYY-MM-DD').format('YYYY-MM-DD');
                let dataFinal = this.$moment(self.dataFinal, 'YYYY-MM-DD').format('YYYY-MM-DD');

                if(mudouPagina == false) {
                    self.handleCurrentChange(1);
                    return;
                }

                LancamentoCartao.listar(start, limit, sort, this.filtrarPor, dataInicial, dataFinal, this.abertos, this.cancelados, this.recebidos, this.idCartao)
                    .then(function (response) {
                        self.lancamentosCartoes = response.data.data;
                        self.total = parseInt(response.data.total);
                    })
                    .catch(function () {

                    });
            },
            handleCurrentChange(val) {
                let start = ( val - 1) * this.pageSize;
                store.commit('lancamentoCartao/setCurrentPage', val);
                store.commit('lancamentoCartao/setStart', start);
                this.filtrar(true);
            },
            setSort(property) {
                let sort = store.state.lancamentoCartao.sort;
                let directionAtual = sort[0].direction;
                let direction = '';

                if (property !== sort[0].property) {

                    direction = 'ASC';
                }
                else
                {
                    if(directionAtual == 'ASC') {
                        direction = 'DESC'
                    }
                    else
                    {
                        direction = 'ASC'
                    }
                }

                let aSort = [{
                    'property':property,
                    'direction':direction
                }];
                store.commit('lancamentoCartao/setSort', aSort);
                this.filtrar();
            },
            excluir(idLancamentoCartao) {
                let self = this;

                let dados = new FormData();
                dados.append('idLancamentoCartao',idLancamentoCartao);
                dados.append('operacao','Excluir');

                this.$confirm('Deseja excluir o lançamento?', 'Confirme', {
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',

                }).then(() => {
                    LancamentoCartao.excluirCancelar(dados)
                        .then(function (response) {
                            if (response.status == 200) {
                                if(response.data.success== true) {
                                    self.$notify({
                                        title: 'Lançamento de Cartão/Convênio',
                                        message: 'Lançamento excluído com sucesso.',
                                        type: 'success'
                                    });
                                    self.filtrar();
                                }
                                else {
                                    self.$notify({
                                        title: 'Lançamento de Cartão/Convênio',
                                        message: 'Não foi possível excluir o lançamento.',
                                        type: 'error'
                                    });
                                }
                            }
                        })
                        .catch(function () {
                        });
                });
            },
            cancelar(idLancamentoCartao) {
                let self = this;

                let dados = new FormData();
                dados.append('idLancamentoCartao',idLancamentoCartao);
                dados.append('operacao','Cancelar');

                this.$confirm('Deseja cancelar o lançamento de cartão/convênio?', 'Confirme', {

                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',

                }).then(() => {
                    LancamentoCartao.excluirCancelar(dados)
                        .then(function (response) {
                            if (response.status == 200) {
                                if(response.data.success == true) {
                                    self.$notify({
                                        title: 'Lançamento de Cartão/Convênio',
                                        message: 'Lançamento cancelado com sucesso.',
                                        type: 'success'
                                    });
                                    self.filtrar();
                                } else {
                                    self.$notify({
                                        title: 'Lançamento de Cartão/Convênio',
                                        message: 'Não foi possível cancelar o lançamento.',
                                        type: 'error'
                                    });
                                }
                            }
                        })
                        .catch(function () {
                        });
                });
            },
            temPermissao(codigoModulo) {
                return PermissaoService.temPermissao(codigoModulo);
            },
            openModalReceber(lancamentoCartao) {
                this.lancamentoCartao = lancamentoCartao;
               this.$('#modalReceberLancamentoCartao').modal('show')
            },
            receber() {
                let self = this;

                let dataRecebimento;

                if(this.dataRecebimento == '' || this.dataRecebimento == 'Invalid date' || this.dataRecebimento == null) {
                    dataRecebimento = null;
                } else{
                    dataRecebimento = this.$moment(this.dataRecebimento, 'YYYY-MM-DD').format('YYYY-MM-DD');
                }

                if(dataRecebimento == null )
                {
                    self.$notify({
                        title: 'Data do Recebimento',
                        message: 'Não foi possível receber o lançametno, a data do recebimento precisa ser preenchida.',
                        type: 'error'
                    });

                    return;
                }

                let dados = new FormData();
                this.lancamentoCartao.dataRecebimento = dataRecebimento;

                dados.append('data', JSON.stringify(this.lancamentoCartao));

                LancamentoCartao.receber(dados)
                    .then(function (response) {
                        if (response.status == 200) {
                            if (response.data.success == "true") {
                                self.$notify({
                                    title: 'Lançamento de Cartão/Convênio',
                                    message: 'Lançamento recebido com sucesso.',
                                    type: 'success'
                                });
                               this.$('#modalReceberLancamentoCartao').modal('hide');
                                self.filtrar();
                            }
                            if (response.data.success == false) {
                                self.$notify({
                                    title: 'Lancamento de Cartão/Convênio',
                                    message: 'Não foi possível receber o lançamento',
                                    type: 'error'
                                });

                            }
                        }
                    })
                    .catch(function () {
                    });
            },
            receberTodos() {
                let self = this;
                let dados = new FormData();

                let dataRecebimento;

                if(this.dataRecebimento == '' || this.dataRecebimento == 'Invalid date' || this.dataRecebimento == null) {
                    dataRecebimento = null;
                } else{
                    dataRecebimento = this.$moment(this.dataRecebimento, 'YYYY-MM-DD').format('YYYY-MM-DD');
                }

                if(dataRecebimento == null )
                {
                    self.$notify({
                        title: 'Data do Recebimento',
                        message: 'Não foi possível receber os lançamentos, a data do recebimento precisa ser preenchida.',
                        type: 'error'
                    });

                    return;
                }

                dados.append('dados', JSON.stringify(this.lancamentosCartoes));
                dados.append('dataRecebimento', dataRecebimento);

                LancamentoCartao.receberTodos(dados)
                    .then(function (response) {
                        if (response.status == 200) {

                            if (response.data.success == false) {

                                self.$notify({
                                    title: 'Erro',
                                    message: 'Os lançamentos de cartão/conênio não puderam der recebidos, mensagem: ' +
                                        response.data.message,
                                    type: 'error',
                                    duration: 6000
                                });

                                return;
                            }

                            self.$notify({
                                title: 'Lançamentos de Cartões/Convênios',
                                message: 'Lançamentos recebidos com sucesso.',
                                type: 'success'
                            });

                           this.$('#modalReceberTodosLancamentosCartoes').modal('hide');
                            self.filtrar();
                        }
                    }
                )
                .catch(function () {

                });
            },
            openModalReceberTodos() {
               this.$('#modalReceberTodosLancamentosCartoes').modal('show')
            },
            getCartoes() {
                let self = this;
                Cartao.getCartoes()
                    .then(function (response) {
                       let primeiroRegistro = {'idCartao':null, 'nome':''};
                       self.cartoes = response.data.data;

                       self.cartoes.unshift(primeiroRegistro);

                        if(self.idCartao == null || self.idCartao == 0) {
                            self.idCartao = response.data.data[0].idCartao;
                            store.commit('lancamentoCartao/setIdCartao', self.idCartao);
                        }
                        else {
                            self.idCartao = store.state.lancamentoCartao.idCartao;
                        }

                        self.filtrar();


                    })
                    .catch(function () {

                    });
            },

        },
    }

</script>


-->

