<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import { Banco } from '../../resources/bancoResource';
import config from '../../services/config';
import { PermissaoService } from '../../services/permissaoService';
import MsgConfirm from '../Utils/MsgConfirm';
import { useBanco } from '../../store/banco';

let msgConfirm = ref(null);
let snackBarApp = inject('snackBarApp');

const bancoStore = useBanco();
let banco = ref({});
let bancos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);

const currentPage = ref(bancoStore.currentPage);
const filtro = ref(bancoStore.filtro);
const filtrarPor = ref(bancoStore.filtrarPor)
const itemsFiltrarPor = ref([
    { value: 'codigo', label: 'Código' },
    { value: 'nome', label: 'Nome' },
]);

const direction = computed(() => {
    return bancoStore.sort[0].direction;
});

const orderBy = computed(() => {
    return bancoStore.sort[0].property;
});

onMounted(() => {
    search();
});

async function destroy() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(banco.value));

    try {
        const response = await Banco.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open('Banco excluído com sucesso.');
                search();
            }
            if (response.data.success == false) {
                snackBarApp.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(ban) {
    banco.value = ban;
    msgConfirm.value.open('Confirma Exclusão ?', 'Confirma a exclusão do banco: ' +
        banco.value.codigo + ' ' + banco.value.nome + ' ?', { color: 'primary' }).then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}

async function search() {
    let start = bancoStore.start;
    let limit = bancoStore.limit;
    let sort = JSON.stringify(bancoStore.sort);

    bancoStore.setFiltro(filtro.value);
    bancoStore.setFiltrarPor(filtrarPor.value);
    try {
        const response = await Banco.get(start, limit, filtro.value, sort, filtrarPor.value);
        bancos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    bancoStore.setCurrentPage(val);
    bancoStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = bancoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = '';

    if (property !== sort[0].property) {
        direction = 'ASC';
    }
    else {
        if (directionAtual == 'ASC') {
            direction = 'DESC'
        }
        else {
            direction = 'ASC'
        }
    }

    let aSort = [{
        'property': property,
        'direction': direction
    }];
    bancoStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Bancos</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols=2>
            <v-btn v-show="temPermissao('01.07.01')" color="primary" :to="{ name: 'Banco.Create' }">Novo Banco</v-btn>
        </v-col>
        <v-col cols=10>
            <v-form>
                <v-row>
                    <v-col cols=4>
                        <v-select v-model="filtrarPor" :items="itemsFiltrarPor" item-title="label" item-value="value"
                            label="Filtrar Por"></v-select>
                    </v-col>
                    <v-col cols=8>
                        <v-text-field label="Filtro" type="text" v-model="filtro"
                            prepend-inner-icon="mdi-magnify">
                            <template v-slot:append>
                                <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </template> 
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>

    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nome')">
                            Nome
                            <v-icon v-if="orderBy == 'nome'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(o) in bancos" :key="o">
                    <td class="text-center">
                        <v-tooltip text="Altera o banco.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.07.02')"
                                    :to="{ name: 'Banco.Update', params: { id: o.idBanco } }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o banco.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('01.07.03')" v-bind="props" variant="text"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.codigo }}</td>
                    <td>{{ o.nome }}</td>
                </tr>

            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
            :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('01.07.01')" color="primary" :to="{ name: 'Banco.Create' }">Novo Banco</v-btn>
</template>
