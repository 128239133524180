<script setup>
import { ref, onMounted, inject } from 'vue';
import { NovoCadastro } from '../../resources/novoCadastroResource';
import { useField, useForm } from 'vee-validate'
import { boolean, object, string } from 'yup';
import { useRouter } from "vue-router";

const snackBarApp = inject('snackBarApp');
const router = useRouter();
const disabledBtnCadastre = ref(false);
const labelBtnCadastre = ref('Criar Novo Cadastro');

const termos = `Para utilizar o OS Gold, é indispensável a aceitação dos termos descritos a seguir.
Este Contrato de Licença de Usuário Final é um acordo legal entre licenciado (pessoa física ou jurídica) denominado LICENCIADO e EMERSON HENING ME, inscrita no CNPJ nº 05.675.227/0001-50, com sede administrativa na Rua Gerson Felício dos Santos, 44 – Sobrado 2 – Capão Raso na cidade de Curitiba - PR, doravante denominado LICENCIANTE, para uso do sistema OS Gold – Sistema online para controle de empresas prestadoras de serviços em geral mediante as cláusulas e condições seguintes.

1. DO OBJETO
1.1. O presente instrumento tem como objeto, o direito de uso do SOFTWARE durante a vigência do prazo do Contrato.
1.2. Sujeito aos termos e condições do presente instrumento, concede ao LICENCIADO uma licença revogável, não exclusiva e intransferível para usar o SOFTWARE. Em nenhuma hipótese o LICENCIADO terá acesso ao código fonte do SOFTWARE ora licenciado, por este se tratar de propriedade intelectual da LICENCIANTE.
1.3. A licença atribuída neste instrumento, restringe-se à utilização do SOFTWARE, para benefício próprio, e utilização dos recursos conforme plano adquirido, o LICENCIADO não adquire, pelo presente instrumento, nenhum direito de propriedade intelectual ou outros direitos exclusivos.

2. DECLARAÇÃO DE ACEITE
2.1. O LICENCIADO declara ter conhecimento dos direitos e obrigações decorrentes do presente instrumento, constituindo este instrumento o acordo completo entre as partes. Declara, ainda, ter lido, compreendido e aceito todos os termos e condições.

3. DAS OBRIGAÇÕES DAS PARTES
3.1. Obrigação do LICENCIADO:
3.1.1. Manter equipe capacitada para a operação do SOFTWARE e para a comunicação com a LICENCIANTE e prover, sempre que ocorrerem quaisquer problemas com o SOFTWARE, toda a documentação e informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os trabalhos, assim como quaisquer detalhes solicitados pela LICENCIANTE;
3.1.2. Responder pelas informações inseridas no SOFTWARE, pelo cadastramento, permissões, senhas e modo de uso de seus usuários. A LICENCIANTE em hipótese alguma será responsável pelo conteúdo (informações, senhas, cópias de informações, etc) sobre o SOFTWARE, não sendo, portanto, estas informações revisadas em momento algum. A responsabilidade pelas informações do SOFTWARE é sempre do LICENCIADO.
3.1.3. Efetuar os pagamentos de acordo com as condições estipuladas neste contrato.
3.2. Obrigação da LICENCIANTE: 
3.2.1. A LICENCIANTE garante ao LICENCIADO que o SOFTWARE deverá funcionar regularmente, se respeitadas as condições de uso definidas neste contrato. Na ocorrência de falhas de programação, a LICENCIANTE obrigar-se-á a corrigir tais falhas, podendo a seu critério, substituir a cópia dos Programas com falhas por cópias corrigidas;
3.2.2. Suspender o acesso ao SOFTWARE que esteja desrespeitando as cláusulas deste objeto ou as normas legais em vigor ou ainda, ao final do prazo de validade deste instrumento, independentemente de aviso prévio; 
3.2.3. Alterar as especificações e/ou características do SOFTWARE licenciados para a melhoria e/ou correções de erros; 
3.2.4. Disponibilizar suporte técnico via e-mail de segunda a sexta-feira das 9:00 as 11:30 e das 13:30 as 17:00, exceto em feriados nacionais, municipais(cidade de Curitiba) e estaduais(Paraná).
3.2.5. A LICENCIANTE se isenta de quaisquer obrigações nas situações:
3.2.5.1. Por falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista culpa da LICENCIANTE;
3.2.5.2. Pelos danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base nas informações fornecidas pelo SOFTWARE;
3.2.5.3. Por eventuais problemas oriundos de ações de terceiros que possam interferir na qualidade do serviço;
3.2.5.4. O OS Gold não poderá ser utilizado para nenhum propósito ilegal ou não autorizado. No uso do OS Gold não poderá o LICENCIADO violar nenhuma lei;
3.2.5.5. Em nenhum caso a LICENCIANTE será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o software, por qualquer outro motivo. sob nenhuma circunstância a responsabilidade integral da LICENCIANTE com relação ao licenciado por todos os danos excederá a quantia paga pelo LICENCIADO à LICENCIANTE pela obtenção da presente licença de SOFTWARE.

4. PRAZO DE VIGÊNCIA
4.1. O presente contrato entra em vigor na data de seu aceite pelo LICENCIADO e vigorará pelo prazo do Plano contratado pelo LICENCIADO.

5. DO PAGAMENTO
5.1. O LICENCIADO deve pagar ao LICENCIANTE o valor de acordo com o respectivo plano escolhido no ato da contratação;
5.2. Caso o LICENCIADO, no decorrer da vigência do presente instrumento, opte por outro plano de licenciamento, os valores serão alterados de acordo com o respectivo plano escolhido;
5.3. A falta de pagamento nas datas determinadas para seu vencimento acarretará na suspensão de acesso ao SOFTWARE até que as pendências financeiras sejam regularizadas, caso não seja feita regularização em um prazo de 3(três) meses as informações do usuário poderão ser excluídas do sistema;
5.4. Os valores estabelecidos no ato do licenciamento do SOFTWARE poderão ser atualizados anualmente, sempre no mês de aniversário de cadastro da empresa, com base no IGPM acumulado desde o último aumento.

6. CANCELAMENTO E EXTINÇÃO DO CONTRATO
6.1. O LICENCIADO poderá cancelar os serviços e rescindir estes Termos e Condições a qualquer momento;
6.2. O serviço não é reembolsado em nenhuma hipótese. Não haverá reembolso ou crédito para contas canceladas ou desistência do uso do serviço. Para tratar todos com igualdade, nenhuma exceção será concedida.

7. FORO
7.1. As PARTES elegem o Foro da cidade de Curitiba – PR, como único competente para dirimir as dúvidas ou controvérsias decorrentes deste Contrato, com a exclusão de qualquer outro, por mais privilegiado que seja.`


const validationSchema = object({
    novoCadastro: object({
        cnpj: string().max(14).typeError('deve ser um número').label('CNPJ/CPF'),
        email: string().email().required().label('Email'),
        repitaEmail: string().email().required().label('Repita o email').test(
            "email-igual",
            "Os emails não são iguais",
            (value) => value === novoCadastro.value.email),
        login: string().required().label('Login'),
        senha: string().required().label('Senha'),
        repitaSenha: string().required().test(
            "senha-igual",
            "As senhas não são iguais",
            (value) => value === novoCadastro.value.senha
        ).label('Repita a senha'),
        termosDeUso: boolean().oneOf([true], 'Você deve aceitar os termos de uso').required().label('Termos de uso')
    }).required()
});
const { handleSubmit, errors } = useForm({ validationSchema });

const novoCadastro = ref({
    cnpj: useField('novoCadastro.cnpj').value,
    email: useField('novoCadastro.email').value,
    repitaEmail: useField('novoCadastro.repitaEmail').value,
    login: useField('novoCadastro.login').value,
    senha: useField('novoCadastro.senha').value,
    repitaSenha: useField('novoCadastro.repitaSenha').value,
    termosDeUso: useField('novoCadastro.termosDeUso').value
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    novoCadastro.value.cnpj = '';
    novoCadastro.value.email = '';
    novoCadastro.value.repitaEmail = '';
    novoCadastro.value.login = '';
    novoCadastro.value.senha = '';
    novoCadastro.value.repitaSenha = '';
    novoCadastro.value.termosDeUso = false;

    document.getElementById("cnpj").focus();
});

async function gravar(values, actions) {
    let dados = new FormData();
    dados.append('dados', JSON.stringify(values.novoCadastro));

    try {
        disabledBtnCadastre.value = true;
        labelBtnCadastre.value = 'Aguarde...';

        const response = await NovoCadastro.criarCadastro(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível criar o novo cadastro, mensagem de erro: ' + response.data.message, 6000);
                disabledBtnCadastre.value = false;
                labelBtnCadastre.value = 'Criar Novo Cadastro';
                return;
            }

            snackBarApp.value.open('Novo cadastro criado com sucesso. Você terá 30 dias para testar o sistema completo. Você será redirecionado para a tela de login.', 4000);
            actions.resetForm();

            setTimeout(() => {
                disabledBtnCadastre.value = false;
                labelBtnCadastre.value = 'Criar Novo Cadastro';
                router.push({ 'name': 'Login' });
            }, 4000);
        }
    } catch (err) {
        snackBarApp.value.open('Erro, mensagem de erro: ' + err);
    }

    disabledBtnCadastre.value = false;
    labelBtnCadastre.value = 'Criar Novo Cadastro';
}

</script>


<template>
    <div class="d-flex align-center flex-column">
        <v-card elevation="16" compact min-width="80%" max-width="1024">
            <v-card-title>
                <h4>Novo Cadastro no Sistema OS Gold</h4>
            </v-card-title>

            <form ref="formNovoCadastro" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field label="CPF/CNPJ(Opcional)" id="cnpj" type="text" maxlength="14"
                                v-model='novoCadastro.cnpj' :error-messages="errors['novoCadastro.cnpj']" />
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-text-field label="Email" id="email" type="text" maxlength="80"
                                v-model='novoCadastro.email' :error-messages="errors['novoCadastro.email']" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-text-field label="Repita o Email" id="repitaEmail" type="text" maxlength="80"
                                v-model='novoCadastro.repitaEmail'
                                :error-messages="errors['novoCadastro.repitaEmail']" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field label="Login" id="login" type="text" maxlength="10"
                                v-model='novoCadastro.login' :error-messages="errors['novoCadastro.login']" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field label="Senha" id="senha" type="password" v-model='novoCadastro.senha'
                                :error-messages="errors['novoCadastro.senha']" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Repita a Senha" id="repitaSenha" type="password"
                                v-model='novoCadastro.repitaSenha'
                                :error-messages="errors['novoCadastro.repitaSenha']" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea v-model="termos" label="Termos de Uso" readonly auto-grow rows="5"
                                max-rows="10">
                            </v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="novoCadastro.termosDeUso" label="Li e Aceito os termos de uso"
                                :error-messages="errors['novoCadastro.termosDeUso']"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" type="submit" id="btnNovoCadastroCriarCadastro"
                        :disabled="disabledBtnCadastre">{{ labelBtnCadastre }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="elevated" class="btn btn-primary"
                        :to="{ name: 'Login' }">Voltar</v-btn>
                </v-card-actions>
            </form>
            <v-progress-linear height="6" indeterminate rounded color="primary"
                v-show="disabledBtnCadastre"></v-progress-linear>

        </v-card>
    </div>
</template>