<script setup>
import { ref, onMounted, defineEmits, inject } from "vue";
import { useRoute } from "vue-router";
import { TipoDocumento } from "../../resources/tipoDocumentoResource";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { usePermissoes } from "../../store/permissoes";

const emit = defineEmits(["close-dialog", "cadastrou-tipo-documento"]);
const snackBarApp = inject('snackBarApp');
const route = useRoute();
let showBtnGravar = ref(true);
const permissoesStore = usePermissoes();

const validationSchema = object({
    tipoDocumento: object({
        idTipoDocumento: undefined,
        descricao: string().required("A descrição é obrigatória").max(50),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const tipoDocumento = ref({
    idTipoDocumento: useField("tipoDocumento.idTipoDocumento").value,
    descricao: useField("tipoDocumento.descricao").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    if (route.name == "TipoDocumento.Update") {
        getTipoDocumento(route.params.id);
    } else {
        tipoDocumento.value.idTipoDocumento = null;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});
async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(values.tipoDocumento));

    try {
        const response = await TipoDocumento.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Tipo de documento não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "TipoDocumento.Update") {
                snackBarApp.value.open("Tipo de documento alterado com sucesso.", 2000);
            } else {
                snackBarApp.value.open("Novo tipo de documento cadastrado com sucesso.", 2000);
                actions.resetForm();
                tipoDocumento.value.idTipoDocumento = null;
                document.getElementById("descricao").focus();
                emit("cadastrou-tipo-documento");
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

function closeModalCadastroTipoDocumento() {
    emit("close-dialog");
}

async function getTipoDocumento(id) {
    let dados = new FormData();
    dados.append("codigoModulo", "01.04.02");
    dados.append("idTipoDocumento", id);
    try {
        const response = await TipoDocumento.getById(dados);
        if (response.status == 200) {
            setValues({ tipoDocumento: response.data.data });
            document.getElementById("descricao").focus();
        }
    } catch (err) {
        console.log(err);
    }
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'TipoDocumento.Update'">Novo Tipo de Documento</h2>
            <h2 v-show="$route.name == 'TipoDocumento.Update'">Alteração de Tipo de Documento</h2>
        </v-card-title>

        <form ref="formTipoDocumento" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field label="Descrição" id="descricao" type="text" maxlength="50"
                            v-model="tipoDocumento.descricao" :error-messages="errors['tipoDocumento.descricao']" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnTipoDocumentoGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="$router.go(-1)" v-if="showBtnGravar"
                    v-show="$route.name == 'TipoDocumento.Create' || $route.name == 'TipoDocumento.Update'">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar"
                    v-show="$route.name !== 'TipoDocumento.Create' && $route.name !== 'TipoDocumento.Update'"
                    @click.prevent="closeModalCadastroTipoDocumento()">Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
