<script setup>
import { ref, onMounted, defineEmits, inject } from 'vue';
import { useRoute } from 'vue-router';
import { GrupoServicos } from '../../resources/grupoServicosResource';
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup';
import { usePermissoes } from '../../store/permissoes';

const emit = defineEmits(['close-dialog', 'cadastrou-grupo-servicos']);
const snackBarApp = inject("snackBarApp");
const route = useRoute();
let showBtnGravar = ref(true);
const permissoesStore = usePermissoes();

const validationSchema = object({
    grupoServicos: object({
        idGrupoServicos: undefined,
        descricao: string().required('A descrição é obrigatória').max(50)
    }).required()
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const grupoServicos = ref({
    idGrupoServicos: useField('grupoServicos.idGrupoServicos').value,
    descricao: useField('grupoServicos.descricao').value
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    if (route.name == 'GrupoServicos.Update') {
        getGrupoServicos(route.params.id);
    }
    else {
        grupoServicos.value.idGrupoServicos = null;

    }


    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == 'D' || tipo == 'P') && validade == 'N') {
        showBtnGravar.value = false;
    }
});
async function gravar(values, actions) {
    let dados = new FormData();
    dados.append('data', JSON.stringify(grupoServicos.value));

    try {
        const response = await GrupoServicos.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Grupo de serviços não pode ser cadastrado, mensagem de erro: ' + response.data.message, 6000);
                return;
            }
            if (route.name == 'GrupoServicos.Update') {
                snackBarApp.value.open('Grupo de serviços alterado com sucesso.');
            }
            else {
                snackBarApp.value.open('Novo grupo de serviços cadastrado com sucesso.');
                actions.resetForm();
                grupoServicos.value.idGrupoServicos = null;
                emit('cadastrou-grupo-servicos');
                document.getElementById("descricao").focus();
            }
        }
    } catch (err) {
        snackBarApp.value.open('Erro, mensagem de erro: ' + err);
    }
}
function closeModalCadastroGrupoServicos() {
    emit('close-dialog');
}
async function getGrupoServicos(id) {
    let dados = new FormData();
    dados.append('codigoModulo', '01.12');
    dados.append('idGrupoServicos', id);
    try {
        const response = await GrupoServicos.getById(dados);
        if (response.status == 200) {
            setValues({ 'grupoServicos': response.data.data });
            document.getElementById("descricao").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

</script>


<template>
    <v-card elevation="16">
        <form ref="formGrupoServicos" @submit.prevent="submit">
            <v-card-title>
                <h2 v-show="$route.name !== 'GrupoServicos.Update'">Novo Grupo de Serviços</h2>
                <h2 v-show="$route.name == 'GrupoServicos.Update'">Alteração de Grupo de Serviços</h2>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Descrição" id="descricao" type="text" maxlength="50"
                            v-model='grupoServicos.descricao' :error-messages="errors['grupoServicos.descricao']" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnGrupoServicosGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent='$router.go(-1)' v-if="showBtnGravar"
                    v-show="$route.name == 'GrupoServicos.Create' || $route.name == 'GrupoServicos.Update'">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar"
                    v-show="$route.name !== 'GrupoServicos.Create' && $route.name !== 'GrupoServicos.Update'"
                    @click.prevent='closeModalCadastroGrupoServicos()'>Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
