<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import { Servico } from '../../resources/servicoResource';
import config from '../../services/config';
import { PermissaoService } from '../../services/permissaoService';
import MsgConfirm from '../Utils/MsgConfirm';
import { useServico } from '../../store/servico';
import { valorFormat } from '../../services/filtros';

let msgConfirm = ref(null);
let snackBarApp = inject('snackBarApp');

const servicoStore = useServico();
let servico = ref({});
let servicos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(servicoStore.currentPage);
const filtro = ref(servicoStore.filtro);
const filtrarPor = ref(servicoStore.filtrarPor)
const itemsFiltrarPor = ref([
    { value: 'codigo', label: 'Código' },
    { value: 'descricao', label: 'Descrição' }
]);

const direction = computed(() => {
    return servicoStore.sort[0].direction;
});

const orderBy = computed(() => {
    return servicoStore.sort[0].property;
});

onMounted(() => {
    search();
});

async function destroy() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(servico.value));

    try {
        const response = await Servico.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open('Serviço excluído com sucesso.');
                search();
            }
            if (response.data.success == false) {
                snackBarApp.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(serv) {
    servico.value = serv;
    msgConfirm.value.open('Confirma Exclusão ?', 'Confirma a exclusão do serviço: ' +
        servico.value.descricao + ' ?', { color: 'primary' }).then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}
async function search() {
    let start = servicoStore.start;
    let limit = servicoStore.limit;
    let sort = JSON.stringify(servicoStore.sort);

    servicoStore.setFiltro(filtro.value);
    servicoStore.setFiltrarPor(filtrarPor.value);
    try {
        const response = await Servico.get(start, limit, filtro.value, sort, filtrarPor.value);
        servicos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    servicoStore.setCurrentPage(val);
    servicoStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = servicoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = '';

    if (property !== sort[0].property) {
        direction = 'ASC';
    }
    else {
        if (directionAtual == 'ASC') {
            direction = 'DESC'
        }
        else {
            direction = 'ASC'
        }
    }

    let aSort = [{
        'property': property,
        'direction': direction
    }];
    servicoStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <v-row>
        <v-col>
            <h1>Serviços</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols=2>
            <v-btn v-show="temPermissao('01.02.01')" color="primary" :to="{ name: 'Servico.Create' }">Novo Serviço</v-btn>
        </v-col>
        <v-col cols=10>
            <v-form>
                <v-row>
                    <v-col cols=4>
                        <v-select v-model="filtrarPor" :items="itemsFiltrarPor" item-title="label" item-value="value"
                            label="Filtrar Por"></v-select>
                    </v-col>
                    <v-col cols=8>
                        <v-text-field label="Filtro" type="text" v-model="filtro"
                            prepend-inner-icon="mdi-magnify">
                            <template v-slot:append>
                                <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>

    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('descricao')">
                            Descrição do Serviço
                            <v-icon v-if="orderBy == 'descricao'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Unidade</th>
                    <th class="text-right">Valor</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(o) in servicos" :key="o">
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-center' : 'text-center'">
                        <v-tooltip text="Altera o serviço.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.02.02')"
                                    :to="{ name: 'Servico.Update', params: { id: o.idServico } }">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o serviço.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-tab-search"
                                    v-show="temPermissao('01.02.04')"
                                    :to="{ name: 'Servico.Show', params: { id: o.idServico } }"
                                    size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o serviço.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('01.02.03')" v-bind="props" variant="text"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>

                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.codigo }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.descricao }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.unidade }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-right' : 'text-right'">{{ valorFormat(o.valor) }}</td>
                </tr>

            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
            :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('01.02.01')" color="primary" :to="{ name: 'Servico.Create' }">Novo Serviço</v-btn>
</template>