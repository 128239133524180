<script setup>
    import {defineEmits, defineProps} from 'vue';

    const emit = defineEmits(['close-dialog']);

    const props = defineProps({
        dataValidade: String
    });

    function adquirir() {
        window.open('http://www.osgold.com.br/adquirir.php', '_blank');
        return;
    }

</script>


<template>
    <v-card elevation="16">
        <v-card-title>
        <h4>OS GOLD - Versão de Demonstração</h4>
        </v-card-title>
        <v-card-text>
            <p>Seu período de teste vence em {{ props.dataValidade }}. Esperamos que esteja gostando do sistema! Para ver os preços ou assinar alguns dos nossos planos clique no botão abaixo.</p>
            <br />
            <div class="row">
                <div class="text-center">
                    <v-btn color="primary" variant="elevated" @click="adquirir">Ver Preços/Assinar Plano de Acesso</v-btn>
                </div>
            </div>
            <br />
            <p>Caso tenha alguma dúvida é só entrar em contato por email osgold@osgold.com.br, WhatsApp <a target="_blank" href="https://wa.me/554130789507">041-3078-9507</a> ou formulários de contato</p>
        </v-card-text>

        <v-card-actions>
            <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>