<script setup>
import { ref, onMounted, inject } from 'vue';
import { CasasDecimais } from '../../resources/casasDecimaisResource';
import { useField, useForm } from 'vee-validate'
import { object, number } from 'yup';
import { useCasasDecimais } from '../../store/casasDecimais';

const snackBarApp = inject('snackBarApp');
const casasDecimaisStore = useCasasDecimais();

const validationSchema = object({
    casasDecimais: object({
        pCustoProduto: number().integer().min(2).max(4).typeError('deve ser um número').label('Casas decimais do campo de preço de custo do produto'),
        quantidadeProduto: number().integer().min(0).max(3).typeError('deve ser um número').label('Casas decimais do campo de quantidade de produto'),
        quantidadeServico: number().integer().min(0).max(3).typeError('deve ser um número').label('Casas decimais do campo de quantidade de serviço'),
    }).required()
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const casasDecimais = ref({
    pCustoProduto: useField('casasDecimais.pCustoProduto').value,
    quantidadeProduto: useField('casasDecimais.quantidadeProduto').value,
    quantidadeServico: useField('casasDecimais.quantidadeServico').value,
});

const submit = handleSubmit(() => {
    gravar();
});

onMounted(() => {
    getCasasDecimais();
});
async function gravar() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(casasDecimais.value));

    try {
        const response = await CasasDecimais.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível alterar as configurações de casas decimais, mensagem de erro: ' + response.data.message, 6000);
                return;
            }
            casasDecimaisStore.setPCustoProduto(casasDecimais.value.pCustoProduto);
            casasDecimaisStore.setQuantidadeProduto(casasDecimais.value.quantidadeProduto);
            casasDecimaisStore.setQuantidadeServico(casasDecimais.value.quantidadeServico);

            CasasDecimais.inicializa();

            snackBarApp.value.open('Configurações de casas decimais alteradas com sucesso.');
        }
    } catch (err) {
        snackBarApp.value.open('Erro, mensagem de erro: ' + err);
    }
}
async function getCasasDecimais() {
    try {
        const response = await CasasDecimais.get();
        if (response.status == 200) {
            setValues({ 'casasDecimais': response.data.data });
            document.getElementById("pCustoProduto").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

</script>


<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Configurações de Casas Decimais</h2>
        </v-card-title>

        <form ref="formCasasDecimais" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Quantidade de casas decimais no campo de preço de custo do produto"
                            id="pCustoProduto" type="text" maxlength="1" v-model='casasDecimais.pCustoProduto'
                            :error-messages="errors['casasDecimais.pCustoProduto']" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Quantidade de casas decimais no campo quantidade de produto"
                            id="quantidadeProduto" type="text" maxlength="1" v-model='casasDecimais.quantidadeProduto'
                            :error-messages="errors['casasDecimais.quantidadeProduto']" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Quantidade de casas decimais no campo quantidade de serviço"
                            id="quantidadeServico" type="text" maxlength="1" v-model='casasDecimais.quantidadeServico'
                            :error-messages="errors['casasDecimais.quantidadeServico']" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnCasasDecimaisGravar">Salvar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>