<script setup>
import { ref, onMounted, inject } from 'vue';
import { ConfiguracoesOrdensServicos } from '../../resources/configuracoesOrdensServicosResource';
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup';
import { useConfiguracoesOrdensServicos } from '../../store/configuracoesOrdensServicos';

const snackBarApp = inject('snackBarApp');
const configuracoesOrdensServicosStore = useConfiguracoesOrdensServicos();

const validationSchema = object({
    configuracoesOrdensServicos: object({
        labelCampo1: string().max(15),
        labelCampo2: string().max(15),
        labelCampo3: string().max(15),
        labelCampo4: string().max(15),
        labelCampo5: string().max(15),
    }).required()
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const configuracoesOrdensServicos = ref({
    labelCampo1: useField('configuracoesOrdensServicos.labelCampo1').value,
    labelCampo2: useField('configuracoesOrdensServicos.labelCampo2').value,
    labelCampo3: useField('configuracoesOrdensServicos.labelCampo3').value,
    labelCampo4: useField('configuracoesOrdensServicos.labelCampo4').value,
    labelCampo5: useField('configuracoesOrdensServicos.labelCampo5').value,
});

const submit = handleSubmit(() => {
    gravar();
});

onMounted(() => {
    getConfiguracoesOrdensServicos();
});
async function gravar() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(configuracoesOrdensServicos.value));

    try {
        const response = await ConfiguracoesOrdensServicos.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível alterar as configurações de ordens de serviços, mensagem de erro: ' +
                    response.data.message, 6000);
                return;
            }

            configuracoesOrdensServicosStore.setLabelCampo1(configuracoesOrdensServicos.value.labelCampo1);
            configuracoesOrdensServicosStore.setLabelCampo2(configuracoesOrdensServicos.value.labelCampo2);
            configuracoesOrdensServicosStore.setLabelCampo3(configuracoesOrdensServicos.value.labelCampo3);
            configuracoesOrdensServicosStore.setLabelCampo4(configuracoesOrdensServicos.value.labelCampo4);
            configuracoesOrdensServicosStore.setLabelCampo5(configuracoesOrdensServicos.value.labelCampo5);
            ConfiguracoesOrdensServicos.inicializa();

            snackBarApp.value.open('Configurações de ordens de serviços alteradas com sucesso.');
        }
    } catch (err) {
        snackBarApp.value.open('Erro, mensagem de erro: ' + err);
    }
}
async function getConfiguracoesOrdensServicos() {
    try {
        const response = await ConfiguracoesOrdensServicos.get();
        if (response.status == 200) {
            setValues({ 'configuracoesOrdensServicos': response.data.data });

            document.getElementById("labelCampo1").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

</script>


<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Configurações de Ordens de Serviços</h2>
        </v-card-title>

        <form ref="formConfiguracoesOrdensServicos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Título do 1º campo configurável da ordem de serviços" id="labelCampo1"
                            type="text" maxlength="15" v-model='configuracoesOrdensServicos.labelCampo1'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo1']" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Título do 2º campo configurável da ordem de serviços" id="labelCampo2"
                            type="text" maxlength="15" v-model='configuracoesOrdensServicos.labelCampo2'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo2']" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Título do 3º campo configurável da ordem de serviços" id="labelCampo3"
                            type="text" maxlength="15" v-model='configuracoesOrdensServicos.labelCampo3'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo3']" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Título do 4º campo configurável da ordem de serviços" id="labelCampo4"
                            type="text" maxlength="15" v-model='configuracoesOrdensServicos.labelCampo4'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo4']" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Título do 5º campo configurável da ordem de serviços" id="labelCampo5"
                            type="text" maxlength="15" v-model='configuracoesOrdensServicos.labelCampo5'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo5']" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit"
                    id="btnConfiguracoesOrdensServicos">Salvar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
