<script setup>
import { ref, onMounted, defineEmits, inject } from "vue";
import { useRoute } from "vue-router";
import { Fornecedor } from "../../resources/fornecedorResource";
import { PermissaoService } from "../../services/permissaoService";
import { GrupoFornecedores } from "../../resources/grupoFornecedoresResource";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object, date } from "yup";
import { usePermissoes } from "../../store/permissoes";
import GrupoFornecedoresCreate from "../GruposFornecedores/GrupoFornecedoresCreate.vue";
import { parse, format, parseISO } from "date-fns";
import Data from "../Utils/data.vue";

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const snackBarApp = inject("snackBarApp");
const route = useRoute();
let dialogGrupoFornecedores = ref(false);
let showBtnGravar = ref(true);
const emit = defineEmits(["close-dialog", "cadastrou-fornecedor"]);
const isReadOnly = ref(false);
const inputCodigo = ref(null);

const permissoesStore = usePermissoes();
const itemsAtivoInativoBloqueado = ref([
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
    { text: "Bloqueado", value: "B" },
]);

addMethod(number, "codigo_fornecedor_existe", function codigoFornecedorExiste(message) {
    return number().test(
        "codigo-fornecedor-existe", // Name
        message, // Msg
        function (codigo_fornecedor) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                Fornecedor.codigoExiste(codigo_fornecedor, fornecedor.value.idFornecedor).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }

                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    fornecedor: object({
        idFornecedor: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_fornecedor_existe("Esse código já está sendo usado por outro fornecedor, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        razao: string().required("A razão social/nome do fornecedor é obrigatório").max(50).label("A razão social/nome"),
        fantasia: string().max(50).label("Nome fantasia/apelido"),
        endereco: string().max(50).label("O endereço"),
        numero: string().max(50).label("O número"),
        complemento: string().max(50).label("O complemento"),
        bairro: string().max(30).label("O bairro"),
        cidade: string().max(30).label("A cidade"),
        estado: string().max(2).label("O estado"),
        cep: string().max(10).label("O CEP"),
        cnpj: string().max(18).label("O CPF/CNPJ"),
        inscricao: string().max(20).label("A Inscrição Estadual/RG"),
        fone1: string().max(15).label("O telefone 1"),
        fone2: string().max(15).label("O telefone 2"),
        fone3: string().max(15).label("O telefone 3"),
        contato: string().max(30).label("O contato"),
        email: string().email().max(100).label("O email"),
        homePage: string().max(100).label("O site"),
        dataCadastramento: date()
            .transform((value, originalValue) => parse(originalValue, "dd/MM/yyyy", new Date()))
            .typeError("A data de cadastramento deve ser uma data válida")
            .label("A data de cadastramento "),
        observacoes: undefined,
        idGrupoFornecedores: undefined,
        ativoInativoBloqueado: undefined,
        motivoInatividade: string().max(50).label("O motivo da inatividade"),
        motivoBloqueado: string().max(50).label("O motivo do bloqueio"),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const gruposFornecedores = ref([]);

const fornecedor = ref({
    idFornecedor: useField("fornecedor.idFornecedor").value,
    codigo: useField("fornecedor.codigo").value,
    razao: useField("fornecedor.razao").value,
    fantasia: useField("fornecedor.fantasia").value,
    endereco: useField("fornecedor.endereco").value,
    numero: useField("fornecedor.numero").value,
    complemento: useField("fornecedor.complemento").value,
    bairro: useField("fornecedor.bairro").value,
    cidade: useField("fornecedor.cidade").value,
    estado: useField("fornecedor.estado").value,
    cep: useField("fornecedor.cep").value,
    cnpj: useField("fornecedor.cnpj").value,
    inscricao: useField("fornecedor.inscricao").value,
    fone1: useField("fornecedor.fone1").value,
    fone2: useField("fornecedor.fone2").value,
    fone3: useField("fornecedor.fone3").value,
    contato: useField("fornecedor.contato").value,
    email: useField("fornecedor.email").value,
    homePage: useField("fornecedor.homePage").value,
    dataCadastramento: useField("fornecedor.dataCadastramento").value,
    observacoes: useField("fornecedor.observacoes").value,
    idGrupoFornecedores: useField("fornecedor.idGrupoFornecedores").value,
    ativoInativoBloqueado: useField("fornecedor.ativoInativoBloqueado").value,
    motivoInatividade: useField("fornecedor.motivoInatividade").value,
    motivoBloqueado: useField("fornecedor.motivoBloqueado").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "Fornecedor.Update" || route.name=="Fornecedor.Show") {
        getFornecedor(route.params.id);
    }

    if(route.name == "Fornecedor.Show"){
        isReadOnly.value = true;
    }

    getGruposFornecedores();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name !== "Fornecedor.Update" && route.name !== "Fornecedor.Show") {
        fornecedor.value.idFornecedor = null;
        proximoCodigo();
    }
    fornecedor.value.razao = "";
    fornecedor.value.fantasia = "";
    fornecedor.value.endereco = "";
    fornecedor.value.numero = "";
    fornecedor.value.complemento = "";
    fornecedor.value.bairro = "";
    fornecedor.value.cidade = "";
    fornecedor.value.estado = "";
    fornecedor.value.cep = "";
    fornecedor.value.cnpj = "";
    fornecedor.value.inscricao = "";
    fornecedor.value.fone1 = "";
    fornecedor.value.fone2 = "";
    fornecedor.value.fone3 = "";
    fornecedor.value.contato = "";
    fornecedor.value.email = "";
    fornecedor.value.homePage = "";
    fornecedor.value.dataCadastramento = new Date().toLocaleDateString("pt-BR");
    fornecedor.value.observacoes = "";
    fornecedor.value.idGrupoFornecedores = null;
    fornecedor.value.ativoInativoBloqueado = "A";
    fornecedor.value.motivoInatividade = "";
    fornecedor.value.motivoBloqueado = "";
}

async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(values.fornecedor));

    try {
        const response = await Fornecedor.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O fornecedor não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "Fornecedor.Update") {
                snackBarApp.value.open("Fornecedor alterado com sucesso.");
            } else {
                snackBarApp.value.open("Novo fornecedor cadastrado com sucesso.");
                emit("cadastrou-fornecedor", fornecedor.value.codigo);
                actions.resetForm();
                cleanForm();
                // document.getElementById("codigo").focus();
                inputCodigo.value.focus();
                
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroFornecedor() {
    emit("close-dialog");
}
async function getGruposFornecedores() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoFornecedor: null, descricao: "" };

    const response = await GrupoFornecedores.get(start, limit, "", sort);
    gruposFornecedores.value = response.data.data;
    gruposFornecedores.value.unshift(primeiroRegistro);

    if (fornecedor.value.idGrupoFornecedores == null) {
        fornecedor.value.idGrupoFornecedores = response.data.data[0].idGrupoFornecedores;
        return;
    }
}

async function getFornecedor(id) {
    let dados = new FormData();
    if(route.name == "Fornecedor.Show"){
        dados.append("codigoModulo", "01.05.04");
    }
    else {
        dados.append("codigoModulo", "01.05.02");
    }
    dados.append("idFornecedor", id);
    try {
        const response = await Fornecedor.getById(dados);
        if (response.status == 200) {
            setValues({ fornecedor: response.data.data });

            fornecedor.value.dataCadastramento = format(parseISO(response.data.data.dataCadastramento), "dd/MM/yyyy");

            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await Fornecedor.proximoCodigo();
        if (response.status == 200) {
            fornecedor.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'Fornecedor.Update' && $route.name !== 'Fornecedor.Show'">Novo Fornecedor</h2>
            <h2 v-show="$route.name == 'Fornecedor.Update'">Alteração de Fornecedor</h2>
            <h2 v-show="$route.name == 'Fornecedor.Show'">Consulta de Fornecedor</h2>
        </v-card-title>
        <form ref="formFornecedor" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            ref="inputCodigo"
                            v-model="fornecedor.codigo"
                            :error-messages="errors['fornecedor.codigo']"
                            maxlength="9"
                            @blur="(e) => { isRealValidation = true; fornecedor.codigo = e.target.value;  }"
                            :readonly="isReadOnly"
                        />
                    </v-col>

                    <v-col cols="10">
                        <v-text-field
                            label="Razão Social/Nome"
                            id="razao"
                            type="text"
                            v-model="fornecedor.razao"
                            maxlength="50"
                            :error-messages="errors['fornecedor.razao']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            label="Nome Fantasia/Apelido"
                            id="fantasia"
                            type="text"
                            v-model="fornecedor.fantasia"
                            maxlength="50"
                            :error-messages="errors['fornecedor.fantasia']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            label="Endereço"
                            id="endereco"
                            type="text"
                            v-model="fornecedor.endereco"
                            maxlength="50"
                            :error-messages="errors['fornecedor.endereco']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Número"
                            id="numero"
                            type="text"
                            v-model="fornecedor.numero"
                            maxlength="10"
                            :error-messages="errors['fornecedor.numero']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            label="Complemento"
                            id="complemento"
                            type="text"
                            v-model="fornecedor.complemento"
                            maxlength="30"
                            :error-messages="errors['fornecedor.complemento']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Bairro"
                            id="bairro"
                            type="text"
                            v-model="fornecedor.bairro"
                            maxlength="30"
                            :error-messages="errors['fornecedor.bairro']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Cidade"
                            id="cidade"
                            type="text"
                            v-model="fornecedor.cidade"
                            maxlength="30"
                            :error-messages="errors['fornecedor.cidade']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Estado"
                            id="estado"
                            type="text"
                            v-model="fornecedor.estado"
                            maxlength="2"
                            :error-messages="errors['fornecedor.estado']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="CEP" id="cep" type="text" v-model="fornecedor.cep" maxlength="10" :error-messages="errors['fornecedor.cep']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="CPF/CNPJ"
                            id="cnpj"
                            type="text"
                            v-model="fornecedor.cnpj"
                            maxlength="18"
                            :error-messages="errors['fornecedor.cnpj']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="RG/Inscrição Estadual"
                            id="inscricao"
                            type="text"
                            v-model="fornecedor.inscricao"
                            maxlength="20"
                            :error-messages="errors['fornecedor.inscricao']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Telefone 1"
                            id="fone1"
                            type="text"
                            v-model="fornecedor.fone1"
                            maxlength="15"
                            :error-messages="errors['fornecedor.fone1']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Telefone 2"
                            id="fone2"
                            type="text"
                            v-model="fornecedor.fone2"
                            maxlength="15"
                            :error-messages="errors['fornecedor.fone2']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Telefone 3"
                            id="fone3"
                            type="text"
                            v-model="fornecedor.fone3"
                            maxlength="15"
                            :error-messages="errors['fornecedor.fone3']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Contato"
                            id="contato"
                            type="text"
                            v-model="fornecedor.contato"
                            maxlength="30"
                            :error-messages="errors['fornecedor.contato']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            label="Email"
                            id="email"
                            type="text"
                            v-model="fornecedor.email"
                            maxlength="100"
                            :error-messages="errors['fornecedor.email']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="Site"
                            id="homePage"
                            type="text"
                            v-model="fornecedor.homePage"
                            maxlength="100"
                            :error-messages="errors['fornecedor.homePage']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Data
                            label="Data de Cadastramento"
                            id="dataCadastramento"
                            type="text"
                            v-model="fornecedor.dataCadastramento"
                            maxlength="10"
                            :error-messages="errors['fornecedor.dataCadastramento']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="9">
                        <v-select
                            :items="gruposFornecedores"
                            item-title="descricao"
                            item-value="idGrupoFornecedores"
                            label="Grupo de Fornecedores"
                            v-model="fornecedor.idGrupoFornecedores"
                            :readonly="isReadOnly"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de fornecedores.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogGrupoFornecedores = true"
                                            id="btnNovoGrupoFornecedores"
                                            v-show="temPermissao('01.20.01') && route.name !== 'Fornecedor.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoFornecedores" transition="dialog-top-transition">
                                    <GrupoFornecedoresCreate @cadastrouGrupoFornecedores="getGruposFornecedores()" @close-dialog="dialogGrupoFornecedores = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="fornecedor.observacoes" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row cols="2">
                    <v-col>
                        <v-select
                            :items="itemsAtivoInativoBloqueado"
                            label="Ativo/Inativo/Bloqueado"
                            v-model="fornecedor.ativoInativoBloqueado"
                            item-title="text"
                            item-value="value"
                            :readonly="isReadOnly"
                        ></v-select>
                    </v-col>
                    <v-col cols="9" v-show="fornecedor.ativoInativoBloqueado == 'I'">
                        <v-text-field
                            label="Motivo da inatividade"
                            id="motivoInatividade"
                            type="text"
                            maxlength="50"
                            v-model="fornecedor.motivoInatividade"
                            :error-messages="errors['fornecedor.motivoInatividade']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="9" v-show="fornecedor.ativoInativoBloqueado == 'B'">
                        <v-text-field
                            label="Motivo do Bloqueio"
                            id="motivoBloqueado"
                            maxlength="50"
                            type="text"
                            v-model="fornecedor.motivoBloqueado"
                            :error-messages="errors['fornecedor.motivoBloqueado']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnFornecedorGravar" v-show="$route.name !== 'Fornecedor.Show'">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-if="showBtnGravar"
                    v-show="$route.name == 'Fornecedor.Create' || $route.name == 'Fornecedor.Update' || $route.name == 'Fornecedor.Show'"
                    >Voltar</v-btn
                >
                <v-btn
                    color="primary"
                    variant="elevated"
                    v-if="showBtnGravar"
                    v-show="$route.name !== 'Fornecedor.Create' && $route.name !== 'Fornecedor.Update' && $route.name !== 'Fornecedor.Show'"
                    @click.prevent="closeModalCadastroFornecedor()"
                    >Fechar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>
