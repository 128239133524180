<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import { Fornecedor } from '../../resources/fornecedorResource';
import config from '../../services/config';
import { PermissaoService } from '../../services/permissaoService';
import MsgConfirm from '../Utils/MsgConfirm';
import { useFornecedor } from '../../store/fornecedor';

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

const fornecedorStore = useFornecedor();
let fornecedor = ref({});
let fornecedores = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(fornecedorStore.currentPage);
const filtro = ref(fornecedorStore.filtro);
const filtrarPor = ref(fornecedorStore.filtrarPor)
const itemsFiltrarPor = ref([
    { value: 'codigo', label: 'Código' },
    { value: 'razao', label: 'Razão Social/Nome' },
    { value: 'fantasia', label: 'Nome Fantasia/Apelido' },
    { value: 'cnpj', label: 'CNPJ/CPF' },
]);

const direction = computed(() => {
    return fornecedorStore.sort[0].direction;
});

const orderBy = computed(() => {
    return fornecedorStore.sort[0].property;
});

onMounted(() => {
    search();
});

async function destroy() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(fornecedor.value));

    try {
        const response = await Fornecedor.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open('Fornecedor excluído com sucesso.');
                search();
            }
            if (response.data.success == false) {
                snackBarApp.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(fornec) {
    fornecedor.value = fornec;
    msgConfirm.value.open('Confirma Exclusão ?', 'Confirma a exclusão do fornecedor: ' +
        fornecedor.value.codigo + ' ' + fornecedor.value.razao + ' ?', { color: 'primary' }).then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}
async function search() {
    let start = fornecedorStore.start;
    let limit = fornecedorStore.limit;
    let sort = JSON.stringify(fornecedorStore.sort);

    fornecedorStore.setFiltro(filtro.value);
    fornecedorStore.setFiltrarPor(filtrarPor.value);
    try {
        const response = await Fornecedor.get(start, limit, filtro.value, sort, filtrarPor.value);
        fornecedores.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    fornecedorStore.setCurrentPage(val);
    fornecedorStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = fornecedorStore.sort;
    let directionAtual = sort[0].direction;
    let direction = '';

    if (property !== sort[0].property) {
        direction = 'ASC';
    }
    else {
        if (directionAtual == 'ASC') {
            direction = 'DESC'
        }
        else {
            direction = 'ASC'
        }
    }

    let aSort = [{
        'property': property,
        'direction': direction
    }];
    fornecedorStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Fornecedores</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols=3>
            <v-btn v-show="temPermissao('01.05.01')" color="primary" :to="{ name: 'Fornecedor.Create' }">Novo
                Fornecedor</v-btn>
        </v-col>
        <v-col cols=9>
            <v-form>
                <v-row>
                    <v-col cols=4>
                        <v-select v-model="filtrarPor" :items="itemsFiltrarPor" item-title="label" item-value="value"
                            label="Filtrar Por"></v-select>
                    </v-col>
                    <v-col cols=8>
                        <v-text-field label="Filtro" type="text" v-model="filtro"
                            prepend-inner-icon="mdi-magnify">
                            <template v-slot:append>
                                <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </template> 
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>

    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('razao')">
                            Razão Social/Nome
                            <v-icon v-if="orderBy == 'razao'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('fantasia')">
                            Nome Fantasia/Apelido
                            <v-icon v-if="orderBy == 'fantasia'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('cnpj')">
                            CNPJ/CPF
                            <v-icon v-if="orderBy == 'cnpj'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="(o) in fornecedores" :key="o">
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-center' : 'text-center'">
                        <v-tooltip text="Altera o fornecedor.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.05.02')"
                                    :to="{ name: 'Fornecedor.Update', params: { id: o.idFornecedor } }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o fornecedor.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-tab-search"
                                    v-show="temPermissao('01.05.04')"
                                    :to="{ name: 'Fornecedor.Show', params: { id: o.idFornecedor } }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o fornecedor.">
                            <template v-slot:activator="{ props }">

                                <v-btn v-show="temPermissao('01.05.03')" v-bind="props" variant="text"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>

                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.codigo }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.razao }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.fantasia }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.cnpj }}</td>
                </tr>

            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
            :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('01.05.01')" color="primary" :to="{ name: 'Fornecedor.Create' }">Novo
        Fornecedor</v-btn>
</template>
