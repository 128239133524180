<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { Vendedor } from "../../resources/vendedorResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useVendedor } from "../../store/vendedor";

let msgConfirm = ref(null);
let snackBarApp = inject('snackBarApp');

const vendedorStore = useVendedor();
let vendedor = ref({});
let vendedores = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);

const currentPage = ref(vendedorStore.currentPage);
const filtro = ref(vendedorStore.filtro);
const filtrarPor = ref(vendedorStore.filtrarPor);
const itemsFiltrarPor = ref([
    { value: "codigo", label: "Código" },
    { value: "nome", label: "Nome" },
    { value: "cpf", label: "CPF" },
]);

const direction = computed(() => {
    return vendedorStore.sort[0].direction;
});

const orderBy = computed(() => {
    return vendedorStore.sort[0].property;
});

onMounted(() => {
    search();
});

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(vendedor.value));

    try {
        const response = await Vendedor.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Vendedor excluído com sucesso.");
                search();
            }
            if (response.data.success == false) {
                snackBarApp.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(vend) {
    vendedor.value = vend;
    msgConfirm.value
        .open("Confirma Exclusão ?", "Confirma a exclusão do vendedor: " + vendedor.value.codigo + " " + vendedor.value.nome + " ?", { color: "primary" })
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}
async function search() {
    let start = vendedorStore.start;
    let limit = vendedorStore.limit;
    let sort = JSON.stringify(vendedorStore.sort);

    vendedorStore.setFiltro(filtro.value);
    vendedorStore.setFiltrarPor(filtrarPor.value);
    try {
        const response = await Vendedor.get(start, limit, filtro.value, sort, filtrarPor.value);
        vendedores.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    vendedorStore.setCurrentPage(val);
    vendedorStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = vendedorStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];
    vendedorStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Vendedores/Funcionários</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="3">
            <v-btn v-show="temPermissao('01.06.01')" color="primary" :to="{ name: 'Vendedor.Create' }">Novo
                Vendedor</v-btn>
        </v-col>
        <v-col cols="9">
            <v-form>
                <v-row>
                    <v-col cols="4">
                        <v-select v-model="filtrarPor" :items="itemsFiltrarPor" item-title="label" item-value="value"
                            label="Filtrar Por"></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Filtro" type="text" v-model="filtro"
                            prepend-inner-icon="mdi-magnify">
                            <template v-slot:append>
                                <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </template> 
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nome')">
                            Nome
                            <v-icon v-if="orderBy == 'nome'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>CPF</th>
                    <th>Celular</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in vendedores" :key="o">
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-center' : 'text-center'">
                        <v-tooltip text="Altera o vendedor.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.06.02')"
                                    :to="{ name: 'Vendedor.Update', params: { id: o.idVendedor } }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o vendedor.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-tab-search"
                                    v-show="temPermissao('01.06.04')"
                                    :to="{ name: 'Vendedor.Show', params: { id: o.idVendedor } }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o vendedor.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('01.06.03')" v-bind="props" variant="text"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>

                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.codigo }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.nome }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.cpf }}</td>
                    <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''">{{ o.celular }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
            :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('01.06.01')" color="primary" :to="{ name: 'Vendedor.Create' }">Novo
        Vendedor</v-btn>
</template>
