<script setup>
import { ref, onMounted, inject } from 'vue';
import { ConfiguracoesContasReceber } from '../../resources/configuracoesContasReceberResource';
import { useField, useForm } from 'vee-validate'
import { object, number, string } from 'yup';
import { useConfiguracoesContasReceber } from '../../store/configuracoesContasReceber';
import Porcentagem from "../Utils/porcentagem.vue";
import { Converte } from "../../utils/converte";

const snackBarApp = inject('snackBarApp');
const configuracoesContasReceberStore = useConfiguracoesContasReceber();

const validationSchema = object({
    configuracoesContasReceber: object({
        multa: string(),
        jurosDiario: string(),
        carenciaDiasJuros: number().integer().min(0).max(999).typeError('deve ser um número').label('Carência em número de dias para começar a cobrar multa e juros'),
    }).required()
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const configuracoesContasReceber = ref({
    multa: useField('configuracoesContasReceber.multa').value,
    jurosDiario: useField('configuracoesContasReceber.jurosDiario').value,
    carenciaDiasJuros: useField('configuracoesContasReceber.carenciaDiasJuros').value,
});

const submit = handleSubmit(() => {
    gravar();
});

onMounted(() => {
    getConfiguracoesContasReceber();
});
async function gravar() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(configuracoesContasReceber.value));

    try {
        const response = await ConfiguracoesContasReceber.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível alterar as configurações de contas a receber mensagem de erro: ' + response.data.message, 6000);
                return;
            }
            configuracoesContasReceberStore.setMulta(configuracoesContasReceber.value.multa);
            configuracoesContasReceberStore.setJurosDiario(configuracoesContasReceber.value.jurosDiario);
            configuracoesContasReceberStore.setCarenciaDiasJuros(configuracoesContasReceber.value.carenciaDiasJuros);

            ConfiguracoesContasReceber.inicializa();

            snackBarApp.value.open('Configurações de contas a receber alteradas com sucesso.');
        }
    } catch (err) {
        snackBarApp.value.open('Erro, mensagem de erro: ' + err);
    }
}
async function getConfiguracoesContasReceber() {
    try {
        const response = await ConfiguracoesContasReceber.get();
        if (response.status == 200) {
            setValues({ 'configuracoesContasReceber': response.data.data });

            configuracoesContasReceber.value.multa = Converte.numberToPorcentagem(response.data.data.multa, 4);
            configuracoesContasReceber.value.jurosDiario = Converte.numberToPorcentagem(response.data.data.jurosDiario, 4);

            document.getElementById("multa").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

</script>


<template>
    <v-container>
        <v-card class="align-center" elevation="16">
            <v-card-title>
                <h2>Configurações de Contas a Receber</h2>
            </v-card-title>

            <form ref="formConfiguracoesContasReceber" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <Porcentagem label="Multa por atraso(%)" id="multa"
                                v-model='configuracoesContasReceber.multa'
                                :error-messages="errors['configuracoesContasReceber.multa']" tipo="multa" />
                        </v-col>
                        <v-col cols="6">
                            <Porcentagem label="Juros diário por atraso(%)" id="jurosDiario"
                                v-model='configuracoesContasReceber.jurosDiario'
                                :error-messages="errors['configuracoesContasReceber.jurosDiario']" tipo="juros" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Carência em número de dias para começar a cobrar multa e juros"
                                id="carenciaDiasJuros" type="text"
                                v-model='configuracoesContasReceber.carenciaDiasJuros'
                                :error-messages="errors['configuracoesContasReceber.carenciaDiasJuros']" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" type="submit"
                        id="btnConfiguracoesContasReceber">Salvar</v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>
