<script setup>
import { ref, onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ContaPagar } from "../../resources/contaPagarResource";
import {Fornecedor} from "../../resources/fornecedorResource";
import { GrupoContasPagar } from "../../resources/grupoContasPagarResource";
import { SubgrupoContasPagar } from "../../resources/subgrupoContasPagarResource";
import { TipoDocumento} from "../../resources/tipoDocumentoResource";
import { PermissaoService } from "../../services/permissaoService";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import GrupoContasPagarCreate from "../GruposContasPagar/GrupoContasPagarCreate.vue";
import SubgrupoContasPagarCreate from "../SubgruposContasPagar/SubgrupoContasPagarCreate.vue";
import FornecedorCreate from "../Fornecedores/FornecedorCreate.vue";
import FornecedorBusca from "../Fornecedores/FornecedorBusca.vue";
import TipoDocumentoCreate from "../TiposDocumentos/TipoDocumentoCreate.vue";
import ContasPagarPagamentos from "./ContasPagarPagamentos.vue";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const isRealValidation = ref(null);
const validationLancamento = ref(null);
const isReadOnly = ref(false);
const route = useRoute();
const router = useRouter();
let dialogGrupoContasPagar = ref(false);
let dialogSubgrupoContasPagar = ref(false);
const dialogFornecedor = ref(false);
const dialogBuscaFornecedor = ref(false);
const dialogTiposDocumentos = ref(false);
let showBtnGravar = ref(true);
let primeiraCarga = true;
let dialogUltimosPagamentos = ref(false);
    
const snackBarApp = inject("snackBarApp");

const gruposContasPagar = ref([]);
const subgruposContasPagar = ref([]);
const tiposDocumentos = ref([]);

const permissoesStore = usePermissoes();

addMethod(number, "lancamento_conta_pagar_existe", function lancamentoContaPagarExiste(message) {
    return number().test(
        "lancamento-conta-pagar-existe", // Name
        message, // Msg
        function (lancamento) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationLancamento.value;
            }
            isRealValidation.value = false;
            validationLancamento.value = false;

            return new Promise((resolve) => {
                ContaPagar.lancamentoExiste(lancamento, lancamentoContaPagar.value.idContaPagar).then((res) => {
                    if (res.data == false) {
                        validationLancamento.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    lancamentoContaPagar: object({
        idContaPagar: undefined,
        lancamento: number()
            .min(1)
            .max(999999999)
            .lancamento_conta_pagar_existe("Esse lançamento já está sendo usado por outro lançamento, tente outro código.")
            .typeError("O lançamento deve ser um número")
            .label("O lançamento"),
        dataEmissao: string().required("A data de emissão é obrigatória").label("A data de emissão"),
        dataVencimento: string().required("A data de vencimento é obrigatória").label("A data de vencimento"),
        dataUltimoPagamento: undefined, 
        idTipoDocumento: undefined,
        documento: string().max(10).label("O documento"),
        codigoFornecedor: number().required("O código do fornecedor é obrigatório").typeError("O código do fornecedor deve ser um número").label("O código do fornecedor"),
        razaoFornecedor: undefined,
        idFornecedor: undefined,
        parcela: string().required("A parcela é obrigatória").label("A parcela"),
        historico: string().max(80).label("O histórico"),
        valor: undefined,
        desconto: undefined,
        acrescimo: undefined,
        valorAPagar: undefined,
        valorPago: undefined,
        idGrupoContasPagar: undefined,
        idSubgrupoContasPagar: undefined,
        observacoes: undefined,
        auxDataUltimoPagamento: undefined,
        situacao: string(),
        valorJaPago: undefined,

    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const lancamentoContaPagar = ref({
    idContaPagar: useField("lancamentoContaPagar.idContaPagar").value,
    lancamento: useField("lancamentoContaPagar.lancamento").value,
    dataEmissao: useField("lancamentoContaPagar.dataEmissao").value,
    dataVencimento: useField("lancamentoContaPagar.dataVencimento").value,
    dataUltimoPagamento: useField("lancamentoContaPagar.dataUltimoPagamento").value,
    idTipoDocumento: useField("lancamentoContaPagar.idTipoDocumento").value,
    documento: useField("lancamentoContaPagar.documento").value,
    codigoFornecedor: useField("lancamentoContaPagar.codigoFornecedor").value,
    razaoFornecedor: useField("lancamentoContaPagar.razaoFornecedor").value,
    idFornecedor: useField("lancamentoContaPagar.idFornecedor").value,
    parcela: useField("lancamentoContaPagar.parcela").value,
    historico: useField("lancamentoContaPagar.historico").value,
    valor: useField("lancamentoContaPagar.valor").value,
    desconto: useField("lancamentoContaPagar.desconto").value,
    acrescimo: useField("lancamentoContaPagar.acrescimo").value,
    valorAPagar: useField("lancamentoContaPagar.valorAPagar").value,
    valorPago: useField("lancamentoContaPagar.valorPago").value,
    idGrupoContasPagar: useField("lancamentoContaPagar.idGrupoContasPagar").value,
    idSubgrupoContasPagar: useField("lancamentoContaPagar.idSubgrupoContasPagar").value,
    observacoes: useField("lancamentoContaPagar.observacoes").value,
    auxDataUltimoPagamento: useField("lancamentoContaPagar.auxDataUltimoPagamento").value,
    situacao: useField("lancamentoContaPagar.situacao").value,
    valorJaPago: useField("lancamentoContaPagar.valorJaPago").value,

});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    isReadOnly.value = false;

    if (route.name == "ContasPagar.Update" || route.name == "ContasPagar.Show" || route.name == "ContasPagar.Quita") {
        getLancamentoContaPagar(route.params.id);
    } else {
        proximoLancamento();
        document.getElementById("lancamento").focus();
    }

    getGruposContasPagar();
    getTiposDocumentos();

    if (route.name == "ContasPagar.Show") {
        isReadOnly.value = true;
    }

    if(route.name == 'ContasPagar.Update') {
        document.getElementById("dataUltimoPagamento").setAttribute("tabindex", "-1");
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "ContasPagar.Create") {
        lancamentoContaPagar.value.idContaPagar = null;
        proximoLancamento();
    }
    lancamentoContaPagar.value.dataEmissao = new Date().toLocaleDateString("pt-BR");
    lancamentoContaPagar.value.dataVencimento = new Date().toLocaleDateString("pt-BR");
    lancamentoContaPagar.value.dataUltimoPagamento = "";
    lancamentoContaPagar.value.documento = "";
    lancamentoContaPagar.value.codigoForncedor = "";
    lancamentoContaPagar.value.razaoFornecedor = "";
    lancamentoContaPagar.value.idFornecedor = null;
    lancamentoContaPagar.value.parcela = "001/001";
    lancamentoContaPagar.value.historico = "";
    lancamentoContaPagar.value.valor = "R$ 0,00";
    lancamentoContaPagar.value.desconto = "R$ 0,00";
    lancamentoContaPagar.value.acrescimo = "R$ 0,00";
    lancamentoContaPagar.value.valorAPagar = "R$ 0,00";
    lancamentoContaPagar.value.valorPago = "R$ 0,00";
    lancamentoContaPagar.value.idGrupoContasPagar = null;
    lancamentoContaPagar.value.idSubgrupoContasPagar = null;
    lancamentoContaPagar.value.observacoes = "";
    lancamentoContaPagar.value.auxDataUltimoPagamento = "";
    lancamentoContaPagar.value.situacao = "A";
}

async function gravar(values, actions) {
    let dados = new FormData();

    values.lancamentoContaPagar.dataEmissao = Converte.datePtBrToIso(values.lancamentoContaPagar.dataEmissao); 
    values.lancamentoContaPagar.dataVencimento = Converte.datePtBrToIso(values.lancamentoContaPagar.dataVencimento); 
    
    let dataUltimoPagamento = '';

    if(values.lancamentoContaPagar.dataUltimoPagamento == '') {
        dataUltimoPagamento = null;
    } else{
        dataUltimoPagamento = Converte.datePtBrToIso(values.lancamentoContaPagar.dataUltimoPagamento); 
    }

    values.lancamentoContaPagar.dataUltimoPagamento = dataUltimoPagamento;
    
    dados.append("dados", JSON.stringify(values.lancamentoContaPagar));

    if(route.name == 'ContasPagar.Create') { 
        if((values.lancamentoContaPagar.dataUltimoPagamento == '' || values.lancamentoContaPagar.dataUltimoPagamento == null)
             && Converte.stringToFloat(values.lancamentoContaPagar.valorPago) > 0) {
            snackBarApp.value.open("Preencha a data do valor do último pagamento, ou deixe zerado o campo 'Valor Pago'.", 6000);
            document.getElementById("dataUltimoPagamento").focus();
            return;
        }
        if((values.lancamentoContaPagar.dataUltimoPagamento !== '' && values.lancamentoContaPagar.dataUltimoPagamento !== null) 
            && Converte.stringToFloat(values.lancamentoContaPagar.valorPago) == 0) {
            snackBarApp.value.open("Preencha o valor pago, ou deixe em branco o campo 'Data do Último Pagamento'.", 6000);
            document.getElementById("valorPago").focus();
            return;
        }


        try {
            const response = await ContaPagar.novoLancamento(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O lançamento não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Novo lançamento de conta a pagar cadastrado com sucesso.");
                actions.resetForm();
                cleanForm();
                document.getElementById("lancamento").focus();
            }
        } catch (err) {
           snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ContasPagar.Update') {
        try {
            const response = await ContaPagar.alteraLancamento(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O lançamento de contas a pagar não pode ser alterado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                actions.resetForm();
                cleanForm();
                snackBarApp.value.open("Lançamento de contas a pagar alterado com sucesso.");
                
                router.push({ name: "ContasPagar.List" });

            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ContasPagar.Quita') {
        try {
            const response = await ContaPagar.quitaLancamento(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O lançamento de contas a pagar não pode ser quitado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Lançamento de contas a pagar quitado com sucesso.");
                actions.resetForm();
                cleanForm();
                router.push({'name':'ContasPagar.List'});
            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }
}

async function getGruposContasPagar() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoContasPagar: null, descricao: "" };

    const response = await GrupoContasPagar.get(start, limit, "", sort);
    if (response.status == 200) {
        gruposContasPagar.value = response.data.data;
        gruposContasPagar.value.unshift(primeiroRegistro);

        if (lancamentoContaPagar.value.idGrupoContasPagar == null) {
            lancamentoContaPagar.value.idGrupoContasPagar = response.data.data[0].idGrupoContasPagar;
            return;
        }
        getSubgruposContasPagar();
    }
}

async function getSubgruposContasPagar() {
    let dados = new FormData();
    dados.append("idGrupoContasPagar", lancamentoContaPagar.value.idGrupoContasPagar);
    subgruposContasPagar.value = [];
    if (primeiraCarga == false) {
        lancamentoContaPagar.value.idSubgrupoContasPagar = null;
    }

    const response = await SubgrupoContasPagar.getSubgrupos(dados);
    if (response.status == 200) {
        let primeiroRegistro = {
            idSubgrupoContasPagar: null,
            descricao: "",
            idGrupoContasPagar: null,
        };
        subgruposContasPagar.value = response.data.data;
        subgruposContasPagar.value.unshift(primeiroRegistro);
        if ((route.name !== "ContasPagar.Update" && route.name !== "ContasPagar.Show") || primeiraCarga == false) {
            lancamentoContaPagar.value.idSubgrupoContasPagar = response.data.data[0].idSubgrupoContasPagar;
        }

        primeiraCarga = false;
    }
}

async function getTiposDocumentos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idTipoDocumento: null, descricao: "" };

    const response = await TipoDocumento.get(start, limit, "", sort);
    if (response.status == 200) {
        tiposDocumentos.value = response.data.data;
        tiposDocumentos.value.unshift(primeiroRegistro);

        if (lancamentoContaPagar.value.idTipoDocumento == null) {
            lancamentoContaPagar.value.idTipoDocumento = response.data.data[0].idTipoDocumento;
            return;
        }
    }
}

async function getLancamentoContaPagar(id) {
    let dados = new FormData();
    if (route.name == "ContasPagar.Update") {
        dados.append("codigoModulo", "03.04.03");
    } else if (route.name == "ContasPagar.Show") {
        dados.append("codigoModulo", "03.04.09");
    } else if (route.name == "ContasPagar.Quita") {
        dados.append("codigoModulo", "03.04.04");
    } 

    dados.append("idContaPagar", id);

    try {
        let response = await ContaPagar.getById(dados)
        if (response.status == 200) {

            response.data.data.dataVencimento = Converte.dateIsoToPtBr(response.data.data.dataVencimento);
            response.data.data.dataEmissao = Converte.dateIsoToPtBr(response.data.data.dataEmissao);
            if(response.data.data.dataUltimoPagamento !== null) {
                response.data.data.dataUltimoPagamento = Converte.dateIsoToPtBr(response.data.data.dataUltimoPagamento);
            }
            
            while(response.data.data.numeroParcela.length < 3 ) {

                response.data.data.numeroParcela = '0'+ response.data.data.numeroParcela;
            }

            while(response.data.data.numeroParcelas.length < 3 ) {
                response.data.data.numeroParcelas = '0'+  response.data.data.numeroParcelas;
            }

            response.data.data.parcela = response.data.data.numeroParcela + "/" + response.data.data.numeroParcelas;
            response.data.data.valor = Converte.numberToCurrency(response.data.data.valor);
            response.data.data.valorAPagar = Converte.numberToCurrency(response.data.data.valorAPagar);
            response.data.data.valorJaPago = Converte.numberToCurrency(response.data.data.valorPago);
            response.data.data.valorPago = Converte.numberToCurrency(response.data.data.valorPago);
            response.data.data.desconto = Converte.numberToCurrency(response.data.data.desconto);
            response.data.data.acrescimo = Converte.numberToCurrency(response.data.data.acrescimo);

            if(route.name == 'ContasPagar.Quita') {
                response.data.data.valorPago = response.data.data.valorAPagar;
                response.data.data.dataUltimoPagamento = new Date().toLocaleDateString("pt-BR");
            }


            setValues({ lancamentoContaPagar: response.data.data });

            onBlurParcela();

        }
    }
    catch (err) {
        console.log(err);
    }
}

async function proximoLancamento() {
    try {
        const response = await ContaPagar.proximoLancamento();
        if (response.status == 200) {
            lancamentoContaPagar.value.lancamento = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

async function getFornecedor() {
    lancamentoContaPagar.value.idFornecedor = "";
    lancamentoContaPagar.value.razaoFornecedor = "";

    if (lancamentoContaPagar.value.codigoFornecedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", lancamentoContaPagar.value.codigoFornecedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.04");

    try {
        let response = await Fornecedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    lancamentoContaPagar.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor inativo não pode gerar lançamentos de contas a pagar.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    lancamentoContaPagar.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor bloqueado não pode gerar lançamentos de contas a pagar.", 6000);
                    return;
                }
                lancamentoContaPagar.value.codigoFornecedor = "";
                document.getElementById("codigoFornecedor").focus();
                snackBarApp.value.open("Fornecedor não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            lancamentoContaPagar.value.idFornecedor = response.data.data.idFornecedor;
            lancamentoContaPagar.value.razaoFornecedor = response.data.data.razao;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouFornecedor(fornecedor) {
    if (fornecedor == null) {
        return;
    }

    lancamentoContaPagar.value.codigoFornecedor = fornecedor.codigo;
    lancamentoContaPagar.value.razaoFornecedor = fornecedor.razao;
    lancamentoContaPagar.value.idFornecedor = fornecedor.idFornecedor;

    getFornecedor();
    dialogBuscaFornecedor.value = false;

    setTimeout(() => {
        document.getElementById("historico").focus();
    }, 100);
}

function cadastrouFornecedor(codigoFornecedor) {
    if (codigoFornecedor == null) {
        return;
    }

    lancamentoContaPagar.value.codigoFornecedor = codigoFornecedor;

    getFornecedor();
}


function calculaValorAPagar() {

    let valorJaPago = Converte.stringToFloat(lancamentoContaPagar.value.valorJaPago);
    let valor = Converte.stringToFloat(lancamentoContaPagar.value.valor);
    let acrescimo = Converte.stringToFloat(lancamentoContaPagar.value.acrescimo);
    let desconto = Converte.stringToFloat(lancamentoContaPagar.value.desconto);

    lancamentoContaPagar.value.valorAPagar = Converte.numberToCurrency(valor + acrescimo - desconto -  valorJaPago);
}

function onBlurParcela() {
    let value = lancamentoContaPagar.value.parcela;

    let posBarra = value.indexOf("/");

    if(posBarra < 0) {
        return false;
    }

    let parcela = value.substring(0, posBarra);
    let parcelas = value.substring(posBarra+1);

    if(Number.isInteger(parseInt(parcela)) == false ) {
        return false;
    }

    if(Number.isInteger(parseInt(parcelas)) == false ) {
        return false;
    }

    if(parseInt(parcela) > parseInt(parcelas)) {
        return false;
    }


    while(parcela.length < 3 ) {

        parcela = '0'+parcela;
    }

    while(parcelas.length < 3 ) {
        parcelas = '0'+parcelas;
    }

    lancamentoContaPagar.value.parcela = parcela + "/" + parcelas;
}               


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="route.name == 'ContasPagar.Create'">Novo Lançamento de Contas a Pagar</h2>
            <h2 v-show="route.name == 'ContasPagar.Update'">Alteração de Lançamento de Contas a Pagar</h2>
            <h2 v-show="route.name == 'ContasPagar.Quita'">Quita um Lançamento de Contas a Pagar</h2>
            <h2 v-show="route.name == 'ContasPagar.Show'">Consulta de um Lançamento de Contas a Pagar</h2>
        </v-card-title>
        <form ref="formContaPagar" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Lançamento"
                            id="lancamento"
                            type="text"
                            v-model="lancamentoContaPagar.lancamento"
                            :error-messages="errors['lancamentoContaPagar.lancamento']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    lancamentoContaPagar.lancamento = e.target.value;
                                }
                            "
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data de Emissão" id="dataEmissao" v-model="lancamentoContaPagar.dataEmissao" 
                            :error-messages="errors['lancamentoContaPagar.dataEmissao']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data de Vencimento" id="dataVencimento" v-model="lancamentoContaPagar.dataVencimento" 
                            :error-messages="errors['lancamentoContaPagar.dataVencimento']" :readonly="isReadOnly"
                            @blur="calculaValorAPagar()" />
                    </v-col>
                    <v-col cols="3">
                        <Data label="D.Último Pagamento" id="dataUltimoPagamento" v-model="lancamentoContaPagar.dataUltimoPagamento" 
                            :error-messages="errors['lancamentoContaPagar.dataUltimoPagamento']" 
                            :readonly="isReadOnly || route.name=='ContasPagar.Update'">
                            <template v-slot:append v-if="route.name !== 'ContasPagar.Create'">
                                <v-tooltip text="Consulta os pagamentos desse lançamento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogUltimosPagamentos = true;"
                                            @blur="calculaValorAPagar()"
                                            id="btnConsultaUltimosPagamentos">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </template>
                        </Data>
                        <v-dialog v-model="dialogUltimosPagamentos" transition="dialog-top-transition">
                            <ContasPagarPagamentos @close-dialog="dialogUltimosPagamentos = false" />
                        </v-dialog> 
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select
                            :items="tiposDocumentos"
                            item-title="descricao"
                            item-value="idTipoDocumento"
                            label="Tipo de Documento"
                            v-model="lancamentoContaPagar.idTipoDocumento"
                            :readonly="isReadOnly"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo tipo de documento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogTiposDocumentos = true"
                                            id="btnNovoTipoDocumento"
                                            v-show="temPermissao('01.04.01') && route.name !== 'ContasPagar.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogTiposDocumentos" transition="dialog-top-transition">
                                    <TipoDocumentoCreate @cadastrouTipoDocumento="getTiposDocumentos()" @close-dialog="dialogTiposDocumentos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Documento"
                            id="documento"
                            type="text"
                            v-model="lancamentoContaPagar.documento"
                            maxlength="10"
                            :error-messages="errors['lancamentoContaPagar.documento']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Parcela"
                            id="parcela"
                            type="text"
                            v-model="lancamentoContaPagar.parcela"
                            maxlength="7"
                            :error-messages="errors['lancamentoContaPagar.parcela']"
                            @blur="onBlurParcela()"
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código do Fornecedor"
                            id="codigoFornecedor"
                            type="text"
                            v-model="lancamentoContaPagar.codigoFornecedor"
                            maxlength="10"
                            :error-messages="errors['lancamentoContaPagar.codigoFornecedor']"
                            @blur="getFornecedor()"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field
                            label="Razão Social/Nome do Fornecedor"
                            id="razaoFornecedor"
                            type="text"
                            v-model="lancamentoContaPagar.razaoFornecedor"
                            maxlength="50"
                            :error-messages="errors['lancamentoContaPagar.razaoFornecedor']"
                            readonly 
                            tabindex="-1">
                            <template v-slot:append v-if="route.name !=='ContasPagar.Show'">
                                <v-tooltip text="Faz a busca de um fornecedor por nome fantasia/cnpj.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaFornecedor = true" id="btnBuscaFornecedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaFornecedor" transition="dialog-top-transition">
                                    <FornecedorBusca 
                                        @selecionouFornecedor = "selecionouFornecedor" 
                                        @close-dialog="dialogBuscaFornecedor = false"
                                        :select-inativo-bloqueado=false />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo fornecedor.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogFornecedor = true" id="btnNovoFornecedor">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogFornecedor" transition="dialog-top-transition">
                                    <FornecedorCreate @cadastrouFornecedor="cadastrouFornecedor" @close-dialog="dialogFornecedor = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Histórico"
                            id="historico"
                            type="text"
                            v-model="lancamentoContaPagar.historico"
                            maxlength="80"
                            :error-messages="errors['lancamentoContaPagar.historico']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Moeda
                            label="Valor da Parcela"
                            id="valor"
                            type="text"
                            v-model="lancamentoContaPagar.valor"
                            :error-messages="errors['lancamentoContaPagar.valor']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAPagar()"
                        />
                    </v-col>
                    <v-col v-show="route.name !== 'ContasPagar.Create'">
                        <Moeda
                            label="Valor Já Pago"
                            id="valorJaPago"
                            type="text"
                            v-model="lancamentoContaPagar.valorJaPago"
                            readonly
                            tabIndex="-1"
                        />
                    </v-col>

                    <v-col>
                        <Moeda
                            label="Descontos(R$)"
                            id="desconto"
                            type="text"
                            v-model="lancamentoContaPagar.desconto"
                            :error-messages="errors['lancamentoContaPagar.desconto']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAPagar()"
                        />
                    </v-col>
                    <v-col>
                        <Moeda
                            label="Acréscimos(R$)"
                            id="acrescimo"
                            type="text"
                            v-model="lancamentoContaPagar.acrescimo"
                            :error-messages="errors['lancamentoContaPagar.acrescimo']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAPagar()"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <Moeda
                            label="Valor a Pagar"
                            id="valorAPagar"
                            type="text"
                            v-model="lancamentoContaPagar.valorAPagar"
                            :error-messages="errors['lancamentoContaPagar.valorAPagar']"
                            readonly
                            tabIndex=-1
                        />
                    </v-col>                    
                    <v-col cols="4" v-if="route.name !== 'ContasPagar.Update' && route.name !== 'ContasPagar.Show'">
                        <Moeda
                            label="Valor Pago"
                            id="valorPago"
                            type="text"
                            v-model="lancamentoContaPagar.valorPago"
                            :error-messages="errors['lancamentoContaPagar.valorPago']"
                            :readonly="isReadOnly"
                            @blur="calculaValorAPagar()"
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            :items="gruposContasPagar"
                            item-title="descricao"
                            item-value="idGrupoContasPagar"
                            label="Grupo de Contas a Pagar"
                            v-model="lancamentoContaPagar.idGrupoContasPagar"
                            @update:modelValue="getSubgruposContasPagar()"
                            :readonly="isReadOnly"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de contas a pagar.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogGrupoContasPagar = true"
                                            id="btnNovoGrupoContasPagar"
                                            v-show="temPermissao('01.15.01') && route.name !== 'ContasPagar.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoContasPagar" transition="dialog-top-transition">
                                    <GrupoContasPagarCreate @cadastrouGrupoContasPagar="getGruposContasPagar()" @close-dialog="dialogGrupoContasPagar = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12">
                        <v-select
                            :items="subgruposContasPagar"
                            item-title="descricao"
                            item-value="idSubgrupoContasPagar"
                            label="Subgrupo de Contas a Pagar"
                            v-model="lancamentoContaPagar.idSubgrupoContasPagar"
                            :readonly="isReadOnly"
                            no-data-text="Nenhum subgrupo cadastrado para esse grupo.">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo subgrupo de contas a pagar.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogSubgrupoContasPagar = true"
                                            id="btnNovoSubgrupoContasPagar"
                                            v-show="temPermissao('01.16.01') && route.name !== 'ContasPagar.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogSubgrupoContasPagar" transition="dialog-top-transition">
                                    <SubgrupoContasPagarCreate @cadastrouSubgrupoContasPagar="getGruposContasPagar();" @close-dialog="dialogSubgrupoContasPagar = false" />
                                </v-dialog>
                            </template>

                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            label="Observações"
                            id="observacoes"
                            type="text"
                            v-model="lancamentoContaPagar.observacoes"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" v-show="$route.name !== 'ContasPagar.Show' && showBtnGravar" variant="elevated" type="submit" id="btnContasPagarGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)">
                    Voltar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
    
</template>