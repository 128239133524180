<script setup>
    import { ref, computed, onMounted, defineEmits, defineProps } from 'vue';
    import {Cliente} from '../../resources/clienteResource';
    import config from '../../services/config';
    import { useCliente } from '../../store/cliente';
    
    const clienteStore = useCliente();
    let clientes = ref({});

    const emit = defineEmits(['selecionou-cliente', 'close-dialog']);

    const props = defineProps({
    selectInativoBloqueado: {
        type: Boolean,
        default: true,
    },
});

    const total = ref(0);
    const pageSize = ref(config.maxPerPage);
    const currentPage = ref(clienteStore.currentPage);
    const filtro = ref(clienteStore.filtro);
    const filtrarPor = ref(clienteStore.filtrarPor)
    const itemsFiltrarPor = ref([
        { value: 'codigo', label: 'Código' },
        { value: 'nome', label: 'Nome/Razão Social' },
        { value: 'apelido', label: 'Apelido/Nome Fantasia' },
        { value: 'cnpj', label: 'CPF/CNPJ' },
    ]);

    const direction = computed(() => {
        return clienteStore.sort[0].direction;
    });

    const orderBy = computed(() => {
        return clienteStore.sort[0].property;
    });
    
    onMounted(() => {
        search();
    });

    async function search() {
        let start = clienteStore.start;
        let limit = clienteStore.limit;
        let sort = JSON.stringify(clienteStore.sort);
        
        clienteStore.setFiltro(filtro.value);
        clienteStore.setFiltrarPor(filtrarPor.value);
        try {
            const response = await Cliente.get(start, limit, filtro.value, sort, filtrarPor.value);
            clientes.value = response.data.data;
            total.value = parseInt(response.data.total);
        } catch(err) {
            console.log(err);
        }
    }

    function handleCurrentChange(val) {
        let start = ( val - 1) * pageSize.value;
        clienteStore.setCurrentPage(val);
        clienteStore.setStart(start);
        search();
    }

    function setSort(property) {
        let sort = clienteStore.sort;
        let directionAtual = sort[0].direction;
        let direction = '';

        if (property !== sort[0].property) {
            direction = 'ASC';
        }
        else
        {
            if(directionAtual == 'ASC') {
                direction = 'DESC'
            }
            else
            {
                direction = 'ASC'
            }
        }

        let aSort = [{
            'property':property,
            'direction':direction
        }];
        clienteStore.setSort(aSort);
        search();
    }

    function selecionar(cliente) {
        emit('selecionou-cliente', cliente);
    }

    function closeModalBuscaClientes() {
        emit('close-dialog');
    }


</script>

<template>
    <v-card>
        <v-card-title>
            <h3>Busca de Clientes</h3>
        </v-card-title>
        <v-card-text>
            <v-card  elevation="16" class="mb-5">
                <v-card-title>
                    Filtro
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols=4>
                                <v-select
                                    v-model="filtrarPor"
                                    :items="itemsFiltrarPor"
                                    item-title="label"
                                    item-value="value"
                                    label="Filtrar Por"
                                ></v-select>
                            </v-col>
                            <v-col cols=6>
                                <v-text-field label="Filtro" type="text" v-model="filtro" prepend-inner-icon="mdi-magnify"></v-text-field>
                            </v-col>
                            <v-col cols=2>
                                <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            
            
            <v-card  elevation="16" class="mb-5">
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                        <tr>
                            <th class="text-center">Seleciona</th>

                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                                    Código
                                    <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nome')">
                                    Nome/Razão Social
                                    <v-icon v-if="orderBy == 'nome'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('apelido')">
                                    Apelido/Nome Fantasia
                                    <v-icon v-if="orderBy == 'apelido'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('cnpj')">
                                    CPF/CNPJ
                                    <v-icon v-if="orderBy == 'cnpj'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o) in clientes" :key="o">
                                <td class="text-center"
                                    :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-center' : 'text-center'">
                                    <v-tooltip text="Seleciona esse cliente." v-if="props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A'">
                                        <template v-slot:activator="{ props }">
                                            <v-btn 
                                                v-bind="props"
                                                variant="text" 
                                                icon="mdi-check-bold" 
                                                @click.prevent="selecionar(o)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>

                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.codigo }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.nome }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.apelido }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.cnpj }}</a></td>
                            </tr>

                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>

            <div class="block text-right">
                <v-pagination
                    @update:modelValue="handleCurrentChange"        
                    v-model="currentPage"
                    :length="Math.ceil(total / pageSize)"
                    :total-visible="7"
                    :show-first-last-page="true">
                </v-pagination>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalBuscaClientes'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>
