<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { string, number, object, addMethod } from "yup";
import { useRoute } from "vue-router";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Cartao } from "../../resources/cartaoResource";
import { Converte } from "../../utils/converte";
import { useField, useForm } from "vee-validate";


const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propOrdemServicos: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);


addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if (Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});


const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);
const cartoes = ref([]);

const emit = defineEmits(['close-dialog', 'finalizouOrdemServicos']);

const validationSchema = object({
    ordemServicos: object({
        idOrdemServicos: undefined,
        idCartao: number().required("O cartão/convênio é obrigatório"),
        dataFinalizacao: string().required("A data da finalização é obrigatória"),
        valorTotalPS: undefined,
        valorTotalP: undefined,
        descRPP: undefined,
        descontoP: undefined,
        valorTotalPComDesconto: undefined,
        valorTotalS: undefined,
        descRPS: undefined,
        descontoS: undefined,
        valorTotalSComDesconto: undefined,
        valorTotalComDesconto: undefined,
        parcelas: number().max(999).min(1).typeError("O número de parcelas deve ser maior ou igual a 1.").required("O número de parcelas é obrigatório"),
        juros: undefined,
        entrada: undefined,
        adiantamento: undefined,
        valorParcela: undefined,
        valorTotalComDescontoJuros: undefined,
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const ordemServicos = ref({
    idOrdemServicos: useField("ordemServicos.idOrdemServicos").value,
    idCartao:useField("ordemServicos.idCartao").value,
    dataFinalizacao: useField("ordemServicos.dataFinalizacao").value,
    valorTotalPS: useField("ordemServicos.valorTotalPS").value,
    valorTotalP: useField("ordemServicos.valorTotalP").value,
    descRPP: useField("ordemServicos.descRPP").value,
    descontoP: useField("ordemServicos.descontoP").value,
    valorTotalPComDesconto: useField("ordemServicos.valorTotalPComDesconto").value,
    valorTotalS: useField("ordemServicos.valorTotalS").value,
    descRPS: useField("ordemServicos.descRPS").value,
    descontoS: useField("ordemServicos.descontoS").value,
    valorTotalSComDesconto: useField("ordemServicos.valorTotalSComDesconto").value,
    valorTotalComDesconto: useField("ordemServicos.valorTotalComDesconto").value,
    parcelas: useField("ordemServicos.parcelas").value,
    juros: useField("ordemServicos.juros").value,
    entrada: useField("ordemServicos.entrada").value,
    adiantamento: useField("ordemServicos.adiantamento").value,
    valorParcela: useField("ordemServicos.valorParcela").value,
    valorTotalComDescontoJuros: useField("ordemServicos.valorTotalComDescontoJuros").value,
});

const submit = handleSubmit(() => {
    gravar();
});

onMounted(() => {
    cleanData();
    getCartoes();
    
    if (operacao.value == "C") {
        isReadOnly.value = true;
        getOrdemServicos(props.propOrdemServicos.idOrdemServicos);
    }

    setTimeout(() => {
        if(operacao.value !== "C") {
            document.getElementById("cartao").focus();
        }
    }, 0);

});


function cleanData() {
    ordemServicos.value.idOrdemServicos = props.propOrdemServicos.idOrdemServicos;
    ordemServicos.value.dataFinalizacao = new Date().toLocaleDateString("pt-BR");
    ordemServicos.value.valorTotalPS = 'R$ 0,00';
    ordemServicos.value.valorTotalP = 'R$ 0,00';
    ordemServicos.value.descRPP = 'R';
    ordemServicos.value.descontoP = 'R$ 0,00';
    ordemServicos.value.valorTotalPComDesconto = 'R$ 0,00';
    ordemServicos.value.valorTotalS = 'R$ 0,00';
    ordemServicos.value.descRPS = 'R';
    ordemServicos.value.descontoS = 'R$ 0,00';
    ordemServicos.value.valorTotalSComDesconto = 'R$ 0,00';
    ordemServicos.value.parcelas = 1;
    ordemServicos.value.juros = '0,0000 %';
    ordemServicos.value.entrada = 'R$ 0,00';
    ordemServicos.value.valorParcela = 'R$ 0,00';
    ordemServicos.value.valorTotalAPagar = props.valorTotal;

    getTotais();

}

async function getTotais() {
    try {
        let response = await OrdemServicos.totais(ordemServicos.value.idOrdemServicos);
        if (response.status == 200) {
            ordemServicos.value.valorTotalPS = Converte.numberToCurrency(response.data.data.total);
            ordemServicos.value.valorTotalP = Converte.numberToCurrency(response.data.data.totalProdutos);
            ordemServicos.value.valorTotalS = Converte.numberToCurrency(response.data.data.totalServicos);
            ordemServicos.value.adiantamento = Converte.numberToCurrency(response.data.data.adiantamento);
            if (route.name !== 'OrdensServicos.Show') {
                ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(response.data.data.totalProdutos);
                ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(response.data.data.totalServicos);
                ordemServicos.value.valorTotalComDescontoJuros = Converte.numberToCurrency(response.data.data.totalAdiantamento);
                ordemServicos.value.valorTotalComDesconto = Converte.numberToCurrency(response.data.data.totalAdiantamento);
                ordemServicos.value.valorParcela = Converte.numberToCurrency(response.data.data.totalAdiantamento);
            }

            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais da ordem de serviços.');
        console.log(err);
    }
}

async function gravar() {
    let valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    if (valorTotalPComDesconto < 0) {
        snackBarApp.value.open('O valor total dos produtos com desconto deve ser maior ou igual a 0. Corrija os valor dos descontos dos produtos.', 6000);
        return;
    }

    let valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);
    if (valorTotalSComDesconto < 0) {
        snackBarApp.value.open('O valor total dos serviços com desconto deve ser maior ou igual a 0. Corrija os valor dos descontos dos serviços.', 6000);
        return;
    }

    let valorTotalComDescontoJuros = Converte.stringToFloat(ordemServicos.value.valorTotalComDescontoJuros);
    if (valorTotalComDescontoJuros < 0) {
        snackBarApp.value.open('O valor total a pagar deve ser maior ou igual a 0.', 6000);
        return;
    }


    let obj = {};
    obj.idOrdemServicos = ordemServicos.value.idOrdemServicos;
    obj.idCartao = ordemServicos.value.idCartao;
    obj.dataFinalizacao = Converte.datePtBrToIso(ordemServicos.value.dataFinalizacao);
    obj.descRPP = ordemServicos.value.descRPP;
    obj.descRPS = ordemServicos.value.descRPS;
    obj.descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    obj.descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    obj.valorTotalComDescontoJuros = Converte.stringToFloat(ordemServicos.value.valorTotalComDescontoJuros);
    obj.valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    obj.valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);
    obj.juros = Converte.stringToFloat(ordemServicos.value.juros);
    obj.entrada = Converte.stringToFloat(ordemServicos.value.entrada);
    obj.numeroParcelas = ordemServicos.value.parcelas;

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));
    try {
        let response = await OrdemServicos.finalizaCartao(dados);
        if (response.status == 200) {

            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível finalizar a ordem de serviços com cartão, ' + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open('Ordem de serviços finalizada com sucesso.');
            emit('finalizouOrdemServicos', ordemServicos.value.idOrdemServicos);

            emit('close-dialog');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao finalizar a ordem de serviços com cartão, erro:' + err);
        console.log(err);
    }
}

function changeDescRPP() {
    if (ordemServicos.value.descRPP == 'P') {
        ordemServicos.value.descontoP = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoP), 2);
    }
    if (this.descRPP == 'R') {
        ordemServicos.value.descontoP = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoP));
    }

    calculaValorAPagar();
}

function changeDescRPS() {
    if (ordemServicos.value.descRPS == 'P') {
        ordemServicos.value.descontoS = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoS), 2);
    }
    if (this.descRPP == 'R') {
        ordemServicos.value.descontoS = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoS));
    }

    calculaValorAPagar();
}

function calculaValorAPagar() {
    let descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    let descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    let descRPP = ordemServicos.value.descRPP;
    let descRPS = ordemServicos.value.descRPS;
    let valorTotalP = Converte.stringToFloat(ordemServicos.value.valorTotalP);
    let valorTotalS = Converte.stringToFloat(ordemServicos.value.valorTotalS);
    let adiantamento = Converte.stringToFloat(ordemServicos.value.adiantamento);
    let valorTotalPComDesconto = 0;
    let valorTotalSComDesconto = 0;
    let juros = Converte.stringToFloat(ordemServicos.value.juros);
    let valorParcela = 0;
    let auxValorTotal = 0;
    let entrada = Converte.stringToFloat(ordemServicos.value.entrada);
    let parcelas = parseInt(ordemServicos.value.parcelas);

    if (descRPP == 'R') {
        valorTotalPComDesconto = valorTotalP - descontoP;
    } else {
        valorTotalPComDesconto = valorTotalP - (valorTotalP * descontoP / 100);
    }

    if (descRPS == 'R') {
        valorTotalSComDesconto = valorTotalS - descontoS;
    } else {
        valorTotalSComDesconto = valorTotalS - (valorTotalS * descontoS / 100);
    }

    let valorTotalComDesconto = valorTotalPComDesconto + valorTotalSComDesconto;

    auxValorTotal = valorTotalComDesconto - adiantamento - entrada;

    ordemServicos.value.valorTotalComDesconto = Converte.numberToCurrency(valorTotalComDesconto);
    ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(valorTotalPComDesconto);
    ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(valorTotalSComDesconto);

    if (juros == 0) {
        valorParcela = auxValorTotal / parcelas;
    }
    else {
        juros = juros / 100;
        let cf = juros / (1 - Math.pow((1 + juros), (parcelas * -1)));
        valorParcela = cf * auxValorTotal;
    }

    ordemServicos.value.valorParcela = Converte.numberToCurrency(valorParcela);
    ordemServicos.value.valorTotalComDescontoJuros = Converte.numberToCurrency((valorParcela * parcelas) + adiantamento + entrada);
}

async function getOrdemServicos(idOrdemServicos) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.03.03');
    dados.append('idOrdemServicos', idOrdemServicos);
    try {
        let response = await OrdemServicos.getById(dados);
        if (response.status == 200) {

            if (route.name == 'OrdensServicos.Show') {
                ordemServicos.value.dataFinalizacao = Converte.dateIsoToPtBr(response.data.data.dataFinalizacao);
                ordemServicos.value.idCartao = response.data.data.idCartao;
                ordemServicos.value.descRPP = response.data.data.descRPP;
                ordemServicos.value.descRPS = response.data.data.descRPS;
                if (response.data.data.descRPP == 'R') {
                    ordemServicos.value.descontoP = Converte.numberToCurrency(response.data.data.descontoP);
                }
                else {
                    ordemServicos.value.descontoP = Converte.numberToPorcentagem(response.data.data.descontoP, 2);
                }

                if (response.data.data.descRPS == 'R') {
                    ordemServicos.value.descontoS = Converte.numberToCurrency(response.data.data.descontoS);
                }
                else {
                    ordemServicos.value.descontoS = Converte.numberToPorcentagem(response.data.data.descontoS, 2);
                }
                ordemServicos.value.carencia = response.data.data.carencia;
                ordemServicos.value.entrada = Converte.numberToCurrency(response.data.data.entrada);
                ordemServicos.value.juros = Converte.numberToPorcentagem(response.data.data.juros, 4);
                ordemServicos.value.parcelas = response.data.data.numeroParcelas;
                calculaValorAPagar();
                
            }
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar a ordem de serviços.');
        console.log(err);
    }
}

async function getCartoes() {
    try {
        let response = await Cartao.getCartoes();
        cartoes.value  = response.data.data;
        if(ordemServicos.value.idCartao  == null) {
            ordemServicos.value.idCartao = response.data.data[0].idCartao;
            return;
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os cartões.');
        console.log(err);
    }
}

</script>


<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="operacao !== 'C'">Finalização da Ordem de Serviços com Cartão/Convênio</h2>
            <h2 v-show="operacao == 'C'">Ordem de Serviços Finalizada com Cartão/Convênio</h2>
        </v-card-title>
        <form ref="formFinalizaOrdemServicosCartao" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select :items="cartoes" item-title="nome" item-value="idCartao" label="Cartão/Convênio" id="cartao"
                            v-model="ordemServicos.idCartao" :readonly="isReadOnly"
                            :error-messages="errors['ordemServicos.idCartao']"
                            no-data-text="Nenhum cartão/convênio cadastrado."
                            ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Data label="Data da Finalização" id="dataFinalizacao" v-model="ordemServicos.dataFinalizacao"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos+Serviços" id="valorTotalPS"
                            v-model="ordemServicos.valorTotalPS" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos" id="valorTotalP" v-model="ordemServicos.valorTotalP"
                            readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="realPorcentagem" item-title="text" item-value="value"
                            label="Desc. em R$ ou em % Produtos" v-model="ordemServicos.descRPP" :readonly="isReadOnly"
                            @update:modelValue="changeDescRPP()"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'R'">
                        <Moeda label="Desconto nos Produtos em (R$)" id="descontoP" v-model="ordemServicos.descontoP"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'P'">
                        <Porcentagem label="Desconto nos Produtos em (%)" id="descontoP"
                            v-model="ordemServicos.descontoP" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="V.Total dos Produtos com Descontos" id="valorTotalPComDesconto"
                            v-model="ordemServicos.valorTotalPComDesconto" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Serviços" id="valorTotalS" v-model="ordemServicos.valorTotalS"
                            readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="realPorcentagem" item-title="text" item-value="value"
                            label="Desc.em R$ ou em % Serviços" v-model="ordemServicos.descRPS" :readonly="isReadOnly"
                            @update:modelValue="changeDescRPS()"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'R'">
                        <Moeda label="Desconto nos Serviços em (R$)" id="descontoS" v-model="ordemServicos.descontoS"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'P'">
                        <Porcentagem label="Desconto nos Serviços em (%)" id="descontoS"
                            v-model="ordemServicos.descontoS" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total dos Serviços com Descontos" id="valorTotalSComDesconto"
                            v-model="ordemServicos.valorTotalSComDesconto" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total com Descontos" id="valorTotalComDesconto"
                            v-model="ordemServicos.valorTotalComDesconto" readonly tabindex="-1" />

                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Número de Parcelas" id="parcelas" type="text"
                            v-model="ordemServicos.parcelas" maxlength="9" :readonly="isReadOnly"
                            :error-messages="errors['ordemServicos.parcelas']" @blur="calculaValorAPagar()" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Porcentagem label="Juros nas Parcelas" id="juros" type="text" tipo="juros" v-model="ordemServicos.juros"
                            maxlength="9" :readonly="isReadOnly" :error-messages="errors['ordemServicos.juros']"
                            @blur="calculaValorAPagar()"  />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Entrada(R$)" id="entrada" v-model="ordemServicos.entrada"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Adiantamento(R$)" id="adiantamento" v-model="ordemServicos.adiantamento" readOnly
                            tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor de Cada Parcela" id="valorParcela" v-model="ordemServicos.valorParcela"
                            readonly tabindex="-1" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Valor Total a Pagar" id="valorTotalComDescontoJuros"
                            v-model="ordemServicos.valorTotalComDescontoJuros" readonly tabindex="-1" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C'"
                    color="primary" variant="elevated" type="submit" 
                    id="btnConfirmarCartao">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="emit('close-dialog')">
                    Fechar
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
