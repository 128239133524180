<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { PermissaoService } from "@/services/permissaoService";
import { string, number, object, addMethod } from "yup";
import { useRoute } from "vue-router";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Banco } from "../../resources/bancoResource";
import BancoCreate from "../Bancos/BancoCreate.vue";
import { Converte } from "../../utils/converte";
import { useField, useForm } from "vee-validate";
import { valorFormat } from "../../services/filtros";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propOrdemServicos: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if (Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);
const isReadOnlyCheque = ref(false);
const bancos = ref([]);
const cheque = ref({});
const dialogBanco = ref(false);

const emit = defineEmits(['close-dialog', 'finalizouOrdemServicos']);

const validationSchema = object({
    ordemServicos: object({
        idOrdemServicos: undefined,
        dataFinalizacao: string().required("A data da finalização é obrigatória"),
        valorTotalPS: undefined,
        valorTotalP: undefined,
        descRPP: undefined,
        descontoP: undefined,
        valorTotalPComDesconto: undefined,
        valorTotalS: undefined,
        descRPS: undefined,
        descontoS: undefined,
        valorTotalSComDesconto: undefined,
        valorTotalComDesconto: undefined,
        parcelas: number().max(999).min(1).typeError("O número de parcelas deve ser maior ou igual a 1.").required("O número de parcelas é obrigatório"),
        entrada: undefined,
        adiantamento: undefined,
        valorParcela: undefined,
        cheques: undefined,
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const ordemServicos = ref({
    idOrdemServicos: useField("ordemServicos.idOrdemServicos").value,
    dataFinalizacao: useField("ordemServicos.dataFinalizacao").value,
    valorTotalPS: useField("ordemServicos.valorTotalPS").value,
    valorTotalP: useField("ordemServicos.valorTotalP").value,
    descRPP: useField("ordemServicos.descRPP").value,
    descontoP: useField("ordemServicos.descontoP").value,
    valorTotalPComDesconto: useField("ordemServicos.valorTotalPComDesconto").value,
    valorTotalS: useField("ordemServicos.valorTotalS").value,
    descRPS: useField("ordemServicos.descRPS").value,
    descontoS: useField("ordemServicos.descontoS").value,
    valorTotalSComDesconto: useField("ordemServicos.valorTotalSComDesconto").value,
    valorTotalComDesconto: useField("ordemServicos.valorTotalComDesconto").value,
    parcelas: useField("ordemServicos.parcelas").value,
    entrada: useField("ordemServicos.entrada").value,
    adiantamento: useField("ordemServicos.adiantamento").value,
    valorParcela: useField("ordemServicos.valorParcela").value,
    cheques: useField("ordemServicos.cheques").value,
});

const submit = handleSubmit((values, actions) => {
    incluirCheque(values, actions);
});

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}


function incluirCheque() {
    if (cheque.value.data == null || cheque.value.data == '') {
        snackBarApp.value.open("A data de emissão é obrigatória.", 6000);
        return;
    }

    if (cheque.value.bomPara == null || cheque.value.bomPara == '') {
        snackBarApp.value.open("A data de bom para é obrigatória.", 6000);
        return;
    }

    if (Converte.stringToFloat(cheque.value.valor) <= 0) {
        snackBarApp.value.open("O valor do cheque deve ser maior que R$ 0,00.", 6000);
        return;
    }

    if (cheque.value.idBanco <= 0) {
        snackBarApp.value.open("O banco deve ser selecionado.", 6000);
        return;
    }


    const banco = bancos.value.find(auxBanco => auxBanco.idBanco === cheque.value.idBanco);
  
    ordemServicos.value.cheques.push({
        'id': ordemServicos.value.cheques.length + 1,
        'data': cheque.value.data,
        'bomPara': cheque.value.bomPara,
        'valor': cheque.value.valor,
        'idBanco': cheque.value.idBanco,
        'banco': banco.nome,
        'agencia': cheque.value.agencia,
        'cc': cheque.value.cc,
        'numero': cheque.value.numero,
        'titular': cheque.value.titular,
        'observacoes': cheque.value.observacoes,
    });

    cheque.value.valor = valorRestante();
}

function excluirCheque(auxCheque) {
    let index = ordemServicos.value.cheques.indexOf(auxCheque);
    if (index >= 0) {
        ordemServicos.value.cheques.splice(index, 1);
        cheque.value.valor = valorRestante();
    }
}

function valorRestante() {
    let valorTotalJaGerado = 0;
    let valor = 0;
    let valorTotal = Converte.stringToFloat(ordemServicos.value.valorTotalComDesconto) - Converte.stringToFloat(ordemServicos.value.entrada) -
        Converte.stringToFloat(ordemServicos.value.adiantamento);
    let parcelas = ordemServicos.value.parcelas;

    for (var i = 0; i < ordemServicos.value.cheques.length; i++) {
        valorTotalJaGerado = valorTotalJaGerado + Converte.stringToFloat(ordemServicos.value.cheques[i].valor);
    }

    if ((parcelas - 1) == ordemServicos.value.cheques.length) {
        valor = valorTotal - valorTotalJaGerado;
    } else if (parcelas == ordemServicos.value.cheques.length) {
        valor = 0;
    }
    else {
        valor = (valorTotal - valorTotalJaGerado) / (parcelas - ordemServicos.value.cheques.length)
    }

    if( ordemServicos.value.cheques.length == ordemServicos.value.numeroParcelas || valor <= 0 || operacao.value == 'C') {
        isReadOnlyCheque.value = true;
    }
    else {
        isReadOnlyCheque.value = false;
    }


    return Converte.numberToCurrency(valor);
}

onMounted(() => {
    getBancos();
    cleanData();
    cleanCheque();
    if (operacao.value == "C") {
        isReadOnly.value = true;
        getOrdemServicos(props.propOrdemServicos.idOrdemServicos);
    }

    setTimeout(() => {
        document.getElementById("dataFinalizacao").focus();
    }, 0);

    if (props.propOrdemServicos.idCliente <= 0 || props.propOrdemServicos.idCliente == null) {
        snackBarApp.value.open('Para finalizar uma ordem de serviços com cheque é necessário que o campo do cliente seja preenchido. ' +
            'Preencha o campo do cliente e tente novamente.');
        emit('close-dialog');
    }
});

function cleanData() {
    ordemServicos.value.idOrdemServicos = props.propOrdemServicos.idOrdemServicos;
    ordemServicos.value.dataFinalizacao = new Date().toLocaleDateString("pt-BR");
    ordemServicos.value.valorTotalPS = 'R$ 0,00';
    ordemServicos.value.valorTotalP = 'R$ 0,00';
    ordemServicos.value.descRPP = 'R';
    ordemServicos.value.descontoP = 'R$ 0,00';
    ordemServicos.value.valorTotalPComDesconto = 'R$ 0,00';
    ordemServicos.value.valorTotalS = 'R$ 0,00';
    ordemServicos.value.descRPS = 'R';
    ordemServicos.value.descontoS = 'R$ 0,00';
    ordemServicos.value.valorTotalSComDesconto = 'R$ 0,00';
    ordemServicos.value.parcelas = 1;
    ordemServicos.value.entrada = 'R$ 0,00';
    ordemServicos.value.valorParcela = 'R$ 0,00';
    ordemServicos.value.valorTotalAPagar = props.valorTotal;

    ordemServicos.value.cheques = [];

    getTotais();

}

function cleanCheque() {
    cheque.value.data = new Date().toLocaleDateString("pt-BR");
    cheque.value.bomPara = new Date().toLocaleDateString("pt-BR");
    cheque.value.idBanco = null;
    cheque.value.valor = '';
    cheque.value.agencia = '';
    cheque.value.cc = '';
    cheque.value.numero = '';
    cheque.value.titular = '';
    cheque.value.observacoes = '';
}

async function getBancos() {
    const response = await Banco.getBancos();
    bancos.value = response.data.data;

    if (bancos.value.length > 0 && (cheque.value.idBanco == null || cheque.value.idBanco == 0)) {
        cheque.value.idBanco = response.data.data[0].idBanco;
    }
}


async function getTotais() {
    try {
        let response = await OrdemServicos.totais(ordemServicos.value.idOrdemServicos);
        if (response.status == 200) {
            ordemServicos.value.valorTotalPS = Converte.numberToCurrency(response.data.data.total);
            ordemServicos.value.valorTotalP = Converte.numberToCurrency(response.data.data.totalProdutos);
            ordemServicos.value.valorTotalS = Converte.numberToCurrency(response.data.data.totalServicos);
            ordemServicos.value.adiantamento = Converte.numberToCurrency(response.data.data.adiantamento);
            if (route.name !== 'OrdensServicos.Show') {
                ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(response.data.data.totalProdutos);
                ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(response.data.data.totalServicos);
                ordemServicos.value.valorTotalComDescontoJuros = Converte.numberToCurrency(response.data.data.totalAdiantamento);
                ordemServicos.value.valorTotalComDesconto = Converte.numberToCurrency(response.data.data.totalAdiantamento);
                ordemServicos.value.valorParcela = Converte.numberToCurrency(response.data.data.totalAdiantamento);
            }

            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais da ordem de serviços.');
        console.log(err);
    }
}

async function gravar() {
    let valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    if (valorTotalPComDesconto < 0) {
        snackBarApp.value.open('O valor total dos produtos com desconto deve ser maior ou igual a 0. Corrija os valor dos descontos dos produtos.', 6000);
        return;
    }


    let valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);
    if (valorTotalSComDesconto < 0) {
        snackBarApp.value.open('O valor total dos serviços com desconto deve ser maior ou igual a 0. Corrija os valor dos descontos dos serviços.', 6000);
        return;
    }

    let valorTotalComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalComDesconto);
    if (valorTotalComDesconto < 0) {
        snackBarApp.value.open('O valor total a pagar deve ser maior ou igual a 0.', 6000);
        return;
    }

    let valorTotalJaGerado = 0;

    for (var i = 0; i < ordemServicos.value.cheques.length; i++) {
        valorTotalJaGerado = valorTotalJaGerado + Converte.stringToFloat(ordemServicos.value.cheques[i].valor);
    }

    valorTotalJaGerado = valorTotalJaGerado + Converte.stringToFloat(ordemServicos.value.entrada) +
        Converte.stringToFloat(ordemServicos.value.adiantamento);

    if (valorTotalJaGerado.toFixed(2) !== valorTotalComDesconto.toFixed(2)) {
        snackBarApp.value.open('O valor total dos cheques + entrada + adiantamento deve ser igual ao valor total com descontos ' +
            'Corrija os cheques lançados e tente novamente.', 6000);
        return;
    }

    let obj = {};
    obj.idOrdemServicos = ordemServicos.value.idOrdemServicos;
    obj.dataFinalizacao = Converte.datePtBrToIso(ordemServicos.value.dataFinalizacao);
    obj.descRPP = ordemServicos.value.descRPP;
    obj.descRPS = ordemServicos.value.descRPS;
    obj.descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    obj.descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    obj.entrada = Converte.stringToFloat(ordemServicos.value.entrada);
    obj.valorTotalComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalComDesconto);
    obj.valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    obj.valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);
    obj.numeroParcelas = ordemServicos.value.parcelas;
    obj.cheques = ordemServicos.value.cheques;

    obj.idCliente = props.propOrdemServicos.idCliente;

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));

    try {
        let response = await OrdemServicos.finalizaCheque(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível finalizar a ordem de serviços com cheques, ' +
                    response.data.message, 6000);
                return;
            }

            snackBarApp.value.open('Ordem de serviços finalizada com sucesso.');
            emit('finalizouOrdemServicos', ordemServicos.value.idOrdemServicos);
            emit('close-dialog');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao finalizar a ordem de serviços com cheques, erro:' + err);
        console.log(err);
    }
}

function changeDescRPP() {
    if (ordemServicos.value.descRPP == 'P') {
        ordemServicos.value.descontoP = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoP), 2);
    }
    if (this.descRPP == 'R') {
        ordemServicos.value.descontoP = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoP));
    }

    calculaValorAPagar();
}

function changeDescRPS() {
    if (ordemServicos.value.descRPS == 'P') {
        ordemServicos.value.descontoS = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoS), 2);
    }
    if (this.descRPP == 'R') {
        ordemServicos.value.descontoS = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoS));
    }

    calculaValorAPagar();
}

function calculaValorAPagar() {
    let descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    let descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    let descRPP = ordemServicos.value.descRPP;
    let descRPS = ordemServicos.value.descRPS;
    let valorTotalP = Converte.stringToFloat(ordemServicos.value.valorTotalP);
    let valorTotalS = Converte.stringToFloat(ordemServicos.value.valorTotalS);
    let valorTotalPComDesconto = 0;
    let valorTotalSComDesconto = 0;

    if (descRPP == 'R') {
        valorTotalPComDesconto = valorTotalP - descontoP;
    } else {
        valorTotalPComDesconto = valorTotalP - (valorTotalP * descontoP / 100);
    }

    if (descRPS == 'R') {
        valorTotalSComDesconto = valorTotalS - descontoS;
    } else {
        valorTotalSComDesconto = valorTotalS - (valorTotalS * descontoS / 100);
    }

    let valorTotalComDesconto = valorTotalPComDesconto + valorTotalSComDesconto;

    ordemServicos.value.valorTotalComDesconto = Converte.numberToCurrency(valorTotalComDesconto);
    ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(valorTotalPComDesconto);
    ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(valorTotalSComDesconto);

    cheque.value.valor = valorRestante();
}

async function getOrdemServicos(idOrdemServicos) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.03.03');
    dados.append('idOrdemServicos', idOrdemServicos);
    try {
        let response = await OrdemServicos.getById(dados);
        if (response.status == 200) {

            if (route.name == 'OrdensServicos.Show') {
                ordemServicos.value.dataFinalizacao = Converte.dateIsoToPtBr(response.data.data.dataFinalizacao);
                ordemServicos.value.descRPP = response.data.data.descRPP;
                ordemServicos.value.descRPS = response.data.data.descRPS;
                if (response.data.data.descRPP == 'R') {
                    ordemServicos.value.descontoP = Converte.numberToCurrency(response.data.data.descontoP);
                }
                else {
                    ordemServicos.value.descontoP = Converte.numberToPorcentagem(response.data.data.descontoP, 2);
                }

                if (response.data.data.descRPS == 'R') {
                    ordemServicos.value.descontoS = Converte.numberToCurrency(response.data.data.descontoS);
                }
                else {
                    ordemServicos.value.descontoS = Converte.numberToPorcentagem(response.data.data.descontoS, 2);
                }
                ordemServicos.value.carencia = response.data.data.carencia;
                ordemServicos.value.entrada = Converte.numberToCurrency(response.data.data.entrada);
                ordemServicos.value.juros = Converte.numberToPorcentagem(response.data.data.juros, 4);
                ordemServicos.value.parcelas = response.data.data.numeroParcelas;
                ordemServicos.value.nroDiasTodoDia = response.data.data.nroTodoDia;
                ordemServicos.value.dias = response.data.data.dias;
                calculaValorAPagar();

                getCheques();
            }
            else {
                let idCliente = response.data.data.idCliente;

                if (idCliente <= 0 || idCliente == null) {
                    snackBarApp.value.open('Cliente não preenchido', 'Para finalizar uma ordem de serviços com cheque é necessário que o campo do cliente seja preenchido. Preencha o campo do cliente e tente novamente.', 'warning');
                    emit('close-dialog');
                }
            }
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar a ordem de serviços.');
        console.log(err);
    }
}


async function getCheques() {
    try {
        let response = await OrdemServicos.getCheques(ordemServicos.value.idOrdemServicos);
        ordemServicos.value.cheques = response.data.data;
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os cheques da ordem de serviços.');
        console.log(err);
    }
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="operacao !== 'C'">Finalização da Ordem de Serviços com Cheques</h2>
            <h2 v-show="operacao == 'C'">Ordem de Serviços Finalizada com Cheques</h2>
        </v-card-title>
        <form ref="formFinalizaOrdemServicosCheque" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <Data label="Data da Finalização" id="dataFinalizacao" v-model="ordemServicos.dataFinalizacao"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos+Serviços" id="valorTotalPS"
                            v-model="ordemServicos.valorTotalPS" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos" id="valorTotalP" v-model="ordemServicos.valorTotalP"
                            readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="realPorcentagem" item-title="text" item-value="value"
                            label="Desc. em R$ ou em % Produtos" v-model="ordemServicos.descRPP" :readonly="isReadOnly"
                            @update:modelValue="changeDescRPP()"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'R'">
                        <Moeda label="Desconto nos Produtos em (R$)" id="descontoP" v-model="ordemServicos.descontoP"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'P'">
                        <Porcentagem label="Desconto nos Produtos em (%)" id="descontoP"
                            v-model="ordemServicos.descontoP" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="V.Total dos Produtos com Descontos" id="valorTotalPComDesconto"
                            v-model="ordemServicos.valorTotalPComDesconto" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Serviços" id="valorTotalS" v-model="ordemServicos.valorTotalS"
                            readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="realPorcentagem" item-title="text" item-value="value"
                            label="Desc.em R$ ou em % Serviços" v-model="ordemServicos.descRPS" :readonly="isReadOnly"
                            @update:modelValue="changeDescRPS()"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'R'">
                        <Moeda label="Desconto nos Serviços em (R$)" id="descontoS" v-model="ordemServicos.descontoS"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'P'">
                        <Porcentagem label="Desconto nos Serviços em (%)" id="descontoS"
                            v-model="ordemServicos.descontoS" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total dos Serviços com Descontos" id="valorTotalSComDesconto"
                            v-model="ordemServicos.valorTotalSComDesconto" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total com Descontos" id="valorTotalComDesconto"
                            v-model="ordemServicos.valorTotalComDesconto" readonly tabindex="-1" />

                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Entrada(R$)" id="entrada" v-model="ordemServicos.entrada"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Adiantamento(R$)" id="adiantamento" v-model="ordemServicos.adiantamento" readOnly
                            tabindex="-1" />
                    </v-col>

                    <v-col cols="3">
                        <v-text-field label="Número de Cheques" id="numeroCheques" type="text"
                            v-model="ordemServicos.parcelas" maxlength="9" :readonly="isReadOnly"
                            :error-messages="errors['ordemServicos.parcelas']" @blur="calculaValorAPagar()" />
                    </v-col>
                </v-row>


                <v-card v-show="operacao !== 'C'">
                    <v-card-title>
                        <h2>Dados dos Cheques</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="3">
                                <Data label="Data de Emissão" id="data" v-model="cheque.data" :readonly="isReadOnlyCheque"/>
                            </v-col>
                            <v-col cols="3">
                                <Data label="Bom Para" id="bomPara" v-model="cheque.bomPara" :readonly="isReadOnlyCheque" />
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="cheque.idBanco" id="banco" :items="bancos" 
                                    item-title="nome" 
                                    item-value="idBanco" 
                                    label="Banco"
                                    :readonly="isReadOnlyCheque"
                                    no-data-text="Nenhum banco cadastrado.">
                                    <template v-slot:append>
                                        <v-tooltip text="Cadastra um novo banco.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn
                                                    v-bind="props"
                                                    color="primary"
                                                    @click="dialogBanco = true"
                                                    id="btnNovoBanco"
                                                    v-show="temPermissao('01.07.01')">
                                                    <v-icon>mdi-invoice-text-plus</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogBanco" transition="dialog-top-transition">
                                            <BancoCreate @cadastrouBanco="getBancos()" @close-dialog="dialogBanco = false" />
                                        </v-dialog>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <Moeda label="Valor do Cheque" id="valor" v-model="cheque.valor" :readonly="isReadOnlyCheque"/>
                            </v-col>

                            <v-col cols="3">
                                <v-text-field label="Agência" id="agencia" type="text" v-model="cheque.agencia" maxlength="15" :readonly="isReadOnlyCheque"/>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Conta" id="cc" type="text" v-model="cheque.cc" maxlength="15" :readonly="isReadOnlyCheque"/>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Nro.Cheque"
                                    id="numero"
                                    type="text"
                                    v-model="cheque.numero"
                                    maxlength="10"
                                    :readonly="isReadOnlyCheque"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Titular do Cheque" id="titular" type="text" v-model="cheque.titular" maxlength="50" :readonly="isReadOnlyCheque"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Observações"
                                    id="observacoes"
                                    type="text"
                                    v-model="cheque.observacoes"
                                    :readonly="isReadOnlyCheque"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-btn v-show="!isReadOnlyCheque" color="primary" @click.prevent="incluirCheque()">Incluir Cheque</v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>


                <v-card class="mt-8" v-show="ordemServicos.cheques && ordemServicos.cheques.length">
                    <v-card-title>
                        <h2>Cheques</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-center" v-show="!isReadOnly">Ações</th>
                                    <th>D.Emissão</th>    
                                    <th>Bom Para</th>
                                    <th>Valor</th>
                                    <th>Banco</th>
                                    <th>Agência</th>
                                    <th>Conta</th>
                                    <th>Nro.Cheque</th>
                                    <th>Titular do Cheque</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="o in ordemServicos.cheques" :key="o" >
                                    <td v-show="!isReadOnly">
                                        <v-tooltip text="Excluir o cheque.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn
                                                    size="small"
                                                    v-bind="props"
                                                    variant="text"
                                                    icon="mdi-trash-can"
                                                    @click.prevent="excluirCheque(o)">
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                    </td>
                                    <td>{{ o.data }}</td>
                                    <td>{{ o.bomPara }}</td>
                                    <td>{{ valorFormat(o.valor) }}</td>
                                    <td>{{ o.banco }}</td>
                                    <td>{{ o.agencia }}</td>
                                    <td>{{ o.cc }}</td>
                                    <td>{{ o.numero }}</td>
                                    <td>{{ o.titular }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C' && ordemServicos.cheques && ordemServicos.cheques.length == ordemServicos.parcelas"
                    color="primary" variant="elevated" @click.prevent="gravar()"
                    id="btnConfirmarAPrazo">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="emit('close-dialog')">
                    Fechar
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
